
export default class mqttWorkerMsg {
    static INIT_MSG = 'INIT_MSG';
    static END_MSG = 'END_MSG';
    static MQTT_MSG = 'MQTT_MSG';
    static MQTT_PUB_MSG = 'MQTT_PUB_MSG';
    static MQTT_SUB_MSG = 'MQTT_SUB_MSG';
    static MQTT_UNSUB_MSG = 'MQTT_UNSUB_MSG';
    static UPDATE_IOT_TOKEN = 'UPDATE_IOT_TOKEN';

    constructor(data, event_type) {
        this.data = data;
        this.event_type = event_type;
    }
}