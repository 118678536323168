import brokerSelector from './brokerSelector';
import CommManagerWorker from './CommManagerWorker';
import commManagerMsgTypes from './commManagerMsgTypes';

export default class mqttWorkerFactory {

    static getCommanagerWorkerClient = (worker_self, broker_names, ip, accountId, iotToken, heartbeatTimeout, non_json_topic_transform_dict) => {
        var connectionName = '[CommWorker]';

       const getNewIotToken = () => {
            worker_self.postMessage({ type: commManagerMsgTypes.GET_NEW_IOT_TOKEN });
        }
        var mqtts = brokerSelector.get_mqtts(broker_names, ip, iotToken, getNewIotToken);

        console.log('start creating client')
        var client = new CommManagerWorker(
            connectionName,
            mqtts,
            accountId,
            heartbeatTimeout,
            non_json_topic_transform_dict,
            (message) => {
                worker_self.postMessage(message);
            },
            // on_connect
            (broker_name) => {
                console.log(`${broker_name} connected`);
            },
            // on_disconnect
            (broker_name) => {
                console.log(`${broker_name} disconnected`);
            }
        );
        console.log('create client')
        console.log(client)
        return client;
    }
}