import React from 'react';
import '../styles/machine-list.scss';
import { ReactComponent as CombineBordered } from '../assets/CombineBordered.svg';
import { ReactComponent as TractorBordered } from '../assets/TractorBordered.svg';
import { MachineType } from '../constants/machineType';
import { connect } from 'react-redux';

class MachineList extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.machineList) return true;

    return false;
  }

  renderIcon(machine) {
    if (machine.machine_type === MachineType.Combine) {
      return <CombineBordered className='combine' />;
    }
    return <TractorBordered className='tractor' />;
  }

  renderTractorModel(machine) {
    if (machine.machine_type === MachineType.Combine) return null;
    return <div className='model'>{machine.machine_model}</div>
  }

  renderRow(machine) {
    return (
      <li
        className='machine'
        key={machine.dbId}
        onClick={() => this.props.onMachineClick(machine)}
      >
        <div className='icon'>
          {this.renderIcon(machine)}
        </div>
        <div className='details'>
          <div className='name'>{machine.displayName}</div>
          {this.renderTractorModel(machine)}
        </div>
      </li>
    );
  }

  render() {
    const machines = this.props.machineList.map(machine =>
      this.renderRow(machine)
    );

    return (
      <ul className='machine-list'>
        {machines}
      </ul>
    );
  }
}

const mapStateToProps = state => {
  return {
    machineList: state.machine.machineList
  }
}

export default connect(mapStateToProps)(MachineList);