// modules
import olStyleStroke from 'ol/style/stroke';
import olStyleStyle from 'ol/style/style';
import olStyleFill from 'ol/style/fill';
import olStyleCircle from 'ol/style/circle';
import olStyleIcon from 'ol/style/icon';
import olGeomMultiPoint from 'ol/geom/multipoint';
import olGeomPoint from 'ol/geom/point';

// state model
import combineBubbleStateModel from '../models/combineBubbleStateModel';

// utilities
import {
  pinTypes,
  newpinTypes,
  notificationTypes
} from '../config';

var styleVars = {
  bgRed: 'rgba(185, 44, 40, 0.7)'
}

var fieldStyleDefault = new olStyleStyle({
  stroke: new olStyleStroke({
    color: 'rgb(153, 204, 255)',
    width: 2
    //color: 'rgba(51 ,122 ,183, .8)',
  }),
  fill: new olStyleFill({
    color: 'rgba(192, 192, 192, .2)'
  })
});

var fieldStyleNew = new olStyleStyle({
  stroke: new olStyleStroke({
    color: 'rgb(153, 204, 255)',
    width: 2
    //color: 'rgba(51 ,122 ,183, .8)',
  }),
  fill: new olStyleFill({
    color: 'rgba(192, 192, 192, .2)'
  })
});

var fieldStyleSelected = new olStyleStyle({
  stroke: new olStyleStroke({
    color: 'rgb(153, 255, 51)',
    width: 2
    //color: 'rgba(51 ,122 ,183, .8)',
  }),
  fill: new olStyleFill({
    color: 'rgba(192, 192, 192, .2)'
  })
});

// styling for a field boundary that is being modified
var fieldStyleModify = [ new olStyleStyle({
  stroke: new olStyleStroke({
    color: 'white',
    width: 2
    //color: 'rgba(51 ,122 ,183, .8)',
  }),
  fill: new olStyleFill({
    color: 'rgba(192, 192, 192, .2)'
  })
}),
      new olStyleStyle({
        image: new olStyleCircle({
          radius: 5,
          fill: new olStyleFill({
            color: 'orange'
          })
        }),
        geometry: function(feature) {
          // return the coordinates of the first ring of the polygon
          var coordinates = [];
          for(var i=0;i<feature.getGeometry().getCoordinates().length;i++) {
            coordinates=coordinates.concat(feature.getGeometry().getCoordinates()[i]);
          }
          return new olGeomMultiPoint(coordinates);
        }
      })];

// styling for an inner boundary
var innerBoundaryDefaultStyle = new olStyleStyle({
  stroke: new olStyleStroke({
    color: 'rgba(80 ,20 ,20, .6)',
    width: 2
  }),
  fill: new olStyleFill({
    color: 'rgba(50, 20, 20, .1)'
  })
});
// styling for a field boundary that is being modified
var innerBoundaryDeleteStyle = [ new olStyleStyle({
  stroke: new olStyleStroke({
    color: 'white',
    width: 2
    //color: 'rgba(51 ,122 ,183, .8)',
  }),
  fill: new olStyleFill({
    color: 'rgba(192, 192, 192, .2)'
  })
}),
      new olStyleStyle({
        image: new olStyleCircle({
          radius: 5,
          fill: new olStyleFill({
            color: 'orange'
          })
        }),
        geometry: function(feature) {
          // return the coordinates of the first ring of the polygon
          var coordinates = [];
          for(var i=0;i<feature.getGeometry().getCoordinates().length;i++) {
            coordinates=coordinates.concat(feature.getGeometry().getCoordinates()[i]);
          }
          return new olGeomMultiPoint(coordinates);
        }
      })];

var deleteInnerBoundary = new olStyleStyle({
  fill: new olStyleFill({
    color: styleVars.bgRed
  })
});

// styling for an invalid inner boundary that is being modified
var invalidInnerBoundaryStyle = [ new olStyleStyle({
  stroke: new olStyleStroke({
    color: 'rgba(185, 44, 40, 1)',
    width: 2
    //color: 'rgba(51 ,122 ,183, .8)',
  }),
  fill: new olStyleFill({
    color: 'rgba(185, 44, 40, 0.1)'
  })
}),
      new olStyleStyle({
        image: new olStyleCircle({
          radius: 5,
          fill: new olStyleFill({
            color: 'rgba(185,44,40, 0.5)',
          })
        }),
        geometry: function(feature) {
          // return the coordinates of the first ring of the polygon
         var coordinates = [];
          for(var i=0;i<feature.getGeometry().getCoordinates().length;i++) {
            coordinates=coordinates.concat(feature.getGeometry().getCoordinates()[i]);
          }
          return new olGeomMultiPoint(coordinates);
        }
      })];

var selectVerticesStyle = new olStyleStyle({
  stroke: new olStyleStroke({
    color: styleVars.bgRed,
    width: 2
    //color: 'rgba(51 ,122 ,183, .8)',
  }),
  fill: new olStyleFill({
    color: styleVars.bgRed
  })
});
      

var coverageMap = new olStyleStyle({
  stroke: new olStyleStroke({
    color: 'rgb(38, 168, 222)',
    width: 2
  }),
  fill: new olStyleFill({
    color: 'rgba(38, 168, 222, .2)'
  })
});


var combineBubblePathPlanner = new olStyleStyle({
  stroke: new olStyleStroke({
    color: 'rgb(255, 255, 0)',
    width: 2
  }),
  fill: new olStyleFill({
    color: 'rgba(255, 255, 0, .4)'
  })
});

var routeStyleMachinePath = new olStyleStyle({
    stroke: new olStyleStroke({
        color: 'yellow',
        width: 2
    }),
    fill: new olStyleFill({
        color: null
    })
});


var middlePinStyleActive = new olStyleStyle({
  image: new olStyleIcon( ({
    anchor: [0.5, 1],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    opacity: 1.00,
    scale: 1,
    src: 'assets/images/middlePin.png'
  })),
  geometry: function(feature) {
     var coordinates = feature.getGeometry().getCoordinates();
      return new olGeomPoint(coordinates);
   }
});

var middlePinStyleInactive = new olStyleStyle({
  image: new olStyleIcon( ({
    anchor: [0.5, 1],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    opacity: 0.35,
    scale: 1,
    src: 'assets/images/middlePin.png'
  })),
   geometry: function(feature) {
     var coordinates = feature.getGeometry().getCoordinates();
      return new olGeomPoint(coordinates);
   }
});



var unloadPinStyle = new olStyleStyle({
  image: new olStyleIcon( ({
    anchor: [0.5, 1],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    opacity: 1.00,
    scale: 1,
    src: 'assets/images/unloadingPin.png'
  }))
});

var stagePinStyle = new olStyleStyle({
  image: new olStyleIcon( ({
    anchor: [0.5, 1],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    opacity: 1.00,
    scale: 1,
    src: 'assets/images/stagingPin.png'
  })),
  stroke: new olStyleStroke({
            color: 'blue',
            width: 5
        }),
  fill: {}
});

var interceptPointPinStyle = new olStyleStyle({
  image: new olStyleIcon(({
    anchor: [0.5, 0.5],
    anchorXUnits: 'fraction',
    anchorYUnits: 'fraction',
    opacity: 1.00,
    scale: 1,
    src: 'assets/images/sync-intercept-point.png'
  })),
  geometry: function(feature) {
    var coordinates = feature.getGeometry().getCoordinates();
    return new olGeomPoint(coordinates);
  }
});

var combineBubbles = {
    [combineBubbleStateModel.PATH_PLANNER()]: combineBubblePathPlanner,
}
export {
  fieldStyleDefault,
  fieldStyleNew,
  fieldStyleSelected,
  fieldStyleModify,
  innerBoundaryDefaultStyle,
  invalidInnerBoundaryStyle,
  coverageMap,
  routeStyleMachinePath,
  middlePinStyleActive,
  middlePinStyleInactive,
  stagePinStyle,
  unloadPinStyle,
  deleteInnerBoundary,
  selectVerticesStyle,
  interceptPointPinStyle,
  combineBubbles,
  innerBoundaryDeleteStyle
}