import workerEventHandler from './workerEventHandler';

export default function worker(self) {
  const NAME = '[CommWorker]:';
  var client = null;
  self.addEventListener('message', (e) => {
    var result = workerEventHandler.processMessage(self, client, NAME, e.data);
    if (result) {
      client = result;
    }
  });
};