export default class timeCompare {
    static isGreaterThanSeconds(startTimeStamp, endTimeStamp, numSeconds) {
        var difference = (endTimeStamp - startTimeStamp) / 1000;

        return difference > numSeconds;
    }

    static isTimestampNew(currentSec, futureSec, new_than_secs = 0) {
        var differenceSec = currentSec - futureSec;
        return differenceSec < new_than_secs;
    }
}