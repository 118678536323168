import { ErrorMessages } from '../constants/errorMessages';
import booleanContains from '@turf/boolean-contains';
import kinks from '@turf/kinks'
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point, polygon } from '@turf/helpers';
import GeoJsonHelper from '../utilities/GeoJsonHelper';
import intersect from '@turf/intersect';
import {
  showMapErrorToastAutoClosing, showMapErrorToastNonClosing
} from './toast-helper';
import { allBoundariesWithinField, doGeometriesOverlap } from '../SmartAgUI-Common/map/mapHelper';
import { VertexStyleProperties, VertexStyleIDs, SelectedVertexStyle } from '../constants/mapConstants';
import { MachineTypeIcons } from '../constants/machineType';
import SimpleSelectMode from './simple_select_mode';
import DirectSelectMode from './direct_select_mode';

// Modify the default styles for vertices.
export const customizeDrawStyles = styles => {
  // Reverse the fill/outline colors and make the point a little bit bigger than others.
  const selectedVertexOutline = styles.find(style => style.id === VertexStyleIDs.SelectedOutline);
  const selectedVertexFill = styles.find(style => style.id === VertexStyleIDs.SelectedFill);

  selectedVertexOutline.paint[VertexStyleProperties.Color] = SelectedVertexStyle.SelectedOutline;
  selectedVertexFill.paint[VertexStyleProperties.Color] = SelectedVertexStyle.SelectedFill;

  return styles;
}

export const isFeatureEmpty = feature => {
  return !feature.geometry.coordinates[0][0];
}

export const getDrawnFeatures = drawData => {
  // Draw adds a feature with a null coordinate any time you enter draw polygon mode, even if nothing has been drawn.
  // Filter these out - they're empty.
  return drawData.features.filter(feature => !isFeatureEmpty(feature));
}

export const formatFieldFeature = (field, feature) => {
  let cleanedFeature = { ...feature };

  cleanedFeature.geometry.coordinates = GeoJsonHelper.cleanCoordinatePairs(cleanedFeature.geometry.coordinates);

  return {
    ...cleanedFeature,
    id: field.id
  }
}

export const createFieldsGeoJsonSource = fields => {
  const fieldFeatures = fields.map(field => {
    // We know the first feature is always the field and there shouldn't be any others
    const fieldFeature = field.data.features[0];

    return formatFieldFeature(field, fieldFeature);
  });

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: fieldFeatures
    },
    tolerance: 2
  };
};

// For now, initialize the machines on the Test Field we typically use so that we can see them right away.
// This is just for initialization. The coordinates of each machine will be updated constantly.
export const createMachinesGeoJsonSource = () => {
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            iconImage: MachineTypeIcons.Combine,
            bearing: 0,
            iconSize: .45
          },
          geometry: {
            type: 'Point',
            coordinates: [-93.627, 41.974]
          }
        },
        {
          type: 'Feature',
          properties: {
            iconImage: MachineTypeIcons.Tractor,
            bearing: 0,
            iconSize: .5
          },
          geometry: {
            type: 'Point',
            coordinates: [-93.628, 41.973]
          }
        }
      ]
    }
  };
};

export const createSecondaryCombineFeature = (id, coordinate, bearing, label) => {
  return {
    type: 'Feature',
    properties: {
      iconImage: MachineTypeIcons.CombineSecondary,
      bearing: bearing,
      iconSize: 1.2,
      machineType: 'secondaryCombine',
      id: id,
      label: label,
    },
    geometry: {
      type: 'Point',
      coordinates: coordinate
    }
  };
}

export const isMapDirty = (drawData, selectedField, mapRef) => {
  const drawnFeatures = getDrawnFeatures(drawData);

  if (!selectedField) {
    // In create mode, need to see if anything has been drawn...
    return drawnFeatures.length > 0;
  } else {
    // In modify mode, need to see if they've modified the field in any way..this is a flag we set in MapView.
    // Will return true if the draw.update event was ever hit.
    return mapRef.state.hasModifiedField;
  }
}

// Checks only outer field boundaries.
export const validateCreatedField = drawData => {
  const drawnFeatures = getDrawnFeatures(drawData);

  if (drawnFeatures.length === 0) {
    showMapErrorToastAutoClosing(ErrorMessages.NoFeatures);
    return false;
  }

  const fieldPolygon = drawnFeatures[0];

  // Check that they have actually created a polygon (must close the polygon)
  if (!fieldPolygon.properties.outerBoundary) {
    showMapErrorToastAutoClosing(ErrorMessages.FieldNotFinished);
    return false;
  }

  if (hasKinks(fieldPolygon)) {
    showMapErrorToastNonClosing(ErrorMessages.InvalidField);
    return false;
  }

  return true;
};

// Checks both outer and inner field boundaries.
export const validateModifiedField = drawData => {
  if (!isValidDrawData(drawData)) {
    return false;
  }

  const fieldPolygon = drawData.features[0];

  // Validate fieldPolygon
  const errors = validateField(fieldPolygon);

  if (errors.length > 0) {
    errors.forEach(error => {
      showMapErrorToastAutoClosing(error);
    });
    return false;
  }

  return true;
}

// Basic validation that's needed regardless of drawing mode:
// - Field was created or modified (AKA something has been drawn)
// - If more than one feature was drawn, show errors as necessary
// - Whatever was drawn was finished (added to map source)
export const isValidDrawData = drawData => {
  const drawnFeatures = getDrawnFeatures(drawData);

  if (drawnFeatures.length === 0) {
    showMapErrorToastAutoClosing(ErrorMessages.NoFeatures);
    return false;
  }

  const fieldPolygon = drawnFeatures[0];

  // Check that they have actually created a polygon (must close the polygon)
  if (!fieldPolygon.properties.outerBoundary) {
    showMapErrorToastAutoClosing(ErrorMessages.FieldNotFinished);
    return false;
  }

  // They have drawn at least one invalid inner boundary
  if (drawnFeatures.length > 1) {
    const invalidInnerBoundaries = drawnFeatures.slice(1);

    showMapErrorToastAutoClosing(ErrorMessages.InvalidInnerBoundary);
    // Also check if any invalid boundary has kinks
    if (invalidInnerBoundaries.some(previouslyInvalidInnerBoundary => hasKinks(previouslyInvalidInnerBoundary))) {
      showMapErrorToastAutoClosing(ErrorMessages.InvalidField);
    }

    // Finally check outer bounds and we can be done.
    if (hasKinks(fieldPolygon)) {
      showMapErrorToastAutoClosing(ErrorMessages.InvalidField);
    }

    return false;
  }

  return true;
}

// Returns true if all field inner boundaries are within the outer boundary. False otherwise.
export const areAllInnerBoundariesWithinFieldBounds = fieldPolygon => {
  // There are no inner boundaries
  if (fieldPolygon.geometry.coordinates.length === 1) return true;
  const innerBoundaries = fieldPolygon.geometry.coordinates.slice(1);
  const innerBoundariesPolygons = innerBoundaries.map(coords => {
    return polygon([coords]);
  });

  return allBoundariesWithinField(fieldPolygon, innerBoundariesPolygons);
}

// Important: kinks() will have a feature if any polygon inner boundary is out of bounds.
export const hasKinks = polygon => {
  // Note: hasKinks will be true if a field has an inner boundary out of bounds (this is a false positive).
  const polygonHasKinks = kinks(polygon).features.length > 0;

  // If no inner boundaries, there will be no false positives.
  if (polygon.geometry.coordinates.length === 1) {
    return polygonHasKinks;
  }

  // Didn't find any kinks.
  if (!polygonHasKinks) return false;

  // Polygon has kinks, we need to eliminate false positives by checking that all inner boundaries are valid.
  return areAllInnerBoundariesWithinFieldBounds(polygon);
}

// Checks that secondPolygon is completely within firstPolygon.
// If firstPolygon has inner boundaries, checks that secondPolygon does not overlap with any of them.
export const isValidInnerBoundary = (firstPolygon, secondPolygon) => {
  const innerBoundaries = firstPolygon.geometry.coordinates.slice(1);

  for (let i = 0; i < innerBoundaries.length; i++) {
    const innerBoundary = innerBoundaries[i];
    const innerPolygon = polygon([innerBoundary]);

    if (intersect(innerPolygon, secondPolygon)) {
      return false;
    }
  }

  return booleanContains(firstPolygon, secondPolygon);
}

// Disable dragging functionality for both select modes.
export const initDrawModes = selectedFieldID => {
  // Pass the selectedField's ID to simple select mode.
  SimpleSelectMode.selectedFieldFeatureId = selectedFieldID;

  return {
    ...MapboxDraw.modes,
    simple_select: SimpleSelectMode,
    direct_select: DirectSelectMode
  };
}

// All we care about is if the field has kinks or not.
// Ignoring inner boundaries.
// NOTE: If polygon has inner boundaries and any inner boundary is out of bounds, this will return false!
export const validatePolygonHasNoKinks = polygon => {
  if (hasKinks(polygon)) {
    showMapErrorToastNonClosing(ErrorMessages.InvalidField);
    return false;
  } else {
    return true;
  }
}

// Finds the selectedField in fieldsSource and initializes the innerBoundariesIDs property if it does not exist.
export const getSelectedFieldFeature = (fieldsSource, selectedFieldID) => {
  const selectedFieldFeature = fieldsSource.data.features.find(source => source.id === selectedFieldID);

  // Uploaded fields don't have the outerBoundary property, so we need to initialize it.
  selectedFieldFeature.properties.outerBoundary = true;

  return selectedFieldFeature;
}

export const coordinatesEqual = (coordinate1, coordinate2) => {
  return coordinate1[0] === coordinate2[0] && coordinate1[1] === coordinate2[1];
}

export const isCoordinateInPolygon = (coordinate, polygon) => {
  const pointToCheck = point(coordinate);

  return booleanPointInPolygon(pointToCheck, polygon);
}

// Check if first and last points in a boundary (polygon coordinates) are equal.
export const areFirstAndLastCoordinatesEqual = boundary => {
  const lastIndex = boundary.length - 1;

  return coordinatesEqual(boundary[0], boundary[lastIndex]);
}

// Deleting an inner boundary results in an empty boundary array.
// Have to filter these out to prevent errors.
export const getActualFieldBoundaries = fieldBoundaries => {
  return fieldBoundaries.filter(boundary => boundary.length);
}

// In order for a polygon to be valid, we have to ensure the first and last coordinates match.
// This can happen if user tries to delete the first/last point in a polygon - we need to fix the coordinates.
export const cleanPolygonCoordinates = polygonCoordinates => {
  if (!areFirstAndLastCoordinatesEqual(polygonCoordinates)) {
    return polygonCoordinates.concat([polygonCoordinates[0]]);
  }
  return polygonCoordinates
}

export const removeDeletedCoordinates = (coordinates, deletePolygon) => {
  return coordinates.filter(coordinate => !isCoordinateInPolygon(coordinate, deletePolygon));
}

export const getIDsOfInnerBoundariesOutOfBounds = fieldPolygon => {
  let invalidInnerBoundaryIDs = [];

  for (let innerBoundaryIndex = 1; innerBoundaryIndex < fieldPolygon.geometry.coordinates.length; innerBoundaryIndex++) {
    const innerBoundaryCoordinates = fieldPolygon.geometry.coordinates[innerBoundaryIndex];
    const innerPolygon = polygon([innerBoundaryCoordinates]);

    if (!booleanContains(fieldPolygon, innerPolygon)) {
      invalidInnerBoundaryIDs.push(innerBoundaryIndex);
    }
  }

  return invalidInnerBoundaryIDs;
}

export const validateField = fieldPolygon => {
  const innerBoundaries = fieldPolygon.geometry.coordinates.slice(1);
  const innerBoundariesPolygons = innerBoundaries.map(coords => {
    return polygon([coords]);
  });

  let errors = [];

  const kinked = hasKinks(fieldPolygon);

  if (kinked) {
    errors.push(ErrorMessages.InvalidField);
  }

  // Stop here if there's no inner boundaries
  if (innerBoundariesPolygons.length === 0) return errors;

  // !kinked would be the case that there is not a kink OR the inner boundaries could be out of bounds.
  if (!kinked) {
    const areWithinField = allBoundariesWithinField(fieldPolygon, innerBoundariesPolygons);

    // Check all inner boundaries of fieldPolygon are within outer boundary
    if (!areWithinField) {
      errors.push(ErrorMessages.InvalidInnerBoundary);
    }

    // Check that no inner boundaries of fieldPolygon overlap
    // doGeometriesOverlap errors if an inner boundary is kinked, therefore cannot do this if kinked
    if (innerBoundariesPolygons.length > 1) {
      const boundariesOverlap = doGeometriesOverlap(innerBoundariesPolygons);

      if (boundariesOverlap) {
        errors.push(ErrorMessages.InvalidInnerBoundary);
      }
    }
  }

  return errors;
}