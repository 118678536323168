import { toast } from 'react-toastify';
import { ToastSettings, SuccessToastId } from '../constants/toastSettings';
import { MapHints, MapToastContainerId, MapHintToastSettings } from '../constants/mapConstants';
import { ErrorMessages } from '../constants/errorMessages';

// Creates/updates an error toast for the specified message.
// Making the toast id the message ensures there is only one instance of that toast.
export const showDefaultErrorToast = message => {
  toast.error(message, {
    ...ToastSettings,
    toastId: message
  });
}

export const showDefaultSuccessToast = message => {
  toast.success(message, {
    ...ToastSettings,
    toastId: SuccessToastId
  });
}

export const dismissSuccessToast = () => {
  toast.dismiss(SuccessToastId);
}

export const showDefaultErrorToastNonClosing = message => {
  if (toast.isActive(message)) return;

  toast.error(message, {
    autoClose: false,
    ...ToastSettings,
    toastId: message,
  });
}

export const dismissAllToasts = () => {
  toast.dismiss();
}

export const dismissToast = message => {
  toast.dismiss(message);
}

export const dismissMapHintToasts = () => {
  toast.dismiss(MapHints.StartDrawing);
  toast.dismiss(MapHints.StartDeleting);
  toast.dismiss(MapHints.StartEditingOuterBoundaries);
  toast.dismiss(MapHints.OuterBoundaryOnly);
}

// Dismisses toasts that are no longer needed.
// User has just completed an action in a scenario that proves they either no longer need a hint or they have fixed an error.
export const dismissIrrelevantMapToasts = (isDrawCreateEvent, isCreating, isDeleting) => {
  // Polygon has been created (DrawEvents.Create)
  if (isDrawCreateEvent) {
    if (isDeleting) {
      // They created a deletePolygon, can dismiss the StartDeleting hint.
      toast.dismiss(MapHints.StartDeleting);
      return;
    }

    if (isCreating) {
      // Dismiss the StartDrawing OuterBoundaryOnly hints, CreateBeforeDelete, FieldNotFinished error.
      toast.dismiss(MapHints.StartDrawing);
      toast.dismiss(MapHints.OuterBoundaryOnly);
      toast.dismiss(ErrorMessages.CreateBeforeDelete);
      toast.dismiss(ErrorMessages.FieldNotFinished);
    }
  } else {
    // Polygon has been updated in some way (DrawEvents.Update). isDeleting doesn't matter here.
    if (isCreating) {
      // They have updated outer boundaries, so can dismiss the StartEditingOuterBoundaries hint.
      toast.dismiss(MapHints.StartEditingOuterBoundaries);
    }
  }
}

// Shows an error toast, does not auto close.
// Used to show errors during field creation/modifying so that users can always see any validation errors.
// Creates the toast's ID by combining the message displayed with the polygon ID for which the message applies.
// This makes it easy to dismiss toasts without having to store another ID.
export const showMapErrorToastNonClosing = message => {
  const toastId = message;

  if (toast.isActive(toastId)) {
    toast.update(toastId, {
      render: message,
      containerId: MapToastContainerId
    });
  } else {
    toast.error(message, {
      autoClose: false,
      toastId: toastId,
      containerId: MapToastContainerId
    });
  }
}

// Shows an error toast for 5 seconds.
// Used to show any validation errors when trying to save a field.
// Uses ValidatorToastID to avoid duplicates.
export const showMapErrorToastAutoClosing = message => {
  if (toast.isActive(message)) {
    toast.update(message, {
      render: message,
      containerId: MapToastContainerId
    });
  } else {
    toast.error(message, {
      autoClose: 5000,
      toastId: message,
      containerId: MapToastContainerId
    });
  }
}

export const showMapHintToastAutoClosing = message => {
  if (toast.isActive(message)) {
    toast.update(message, {
      render: message,
      containerId: MapToastContainerId
    });
  } else {
    toast.info(message, {
      ...MapHintToastSettings,
      toastId: message
    });
  }
}