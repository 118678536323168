import { guid } from './guid';
import mqttConstants from './mqttConstants';

const radioMqttFactory = () => {
  const id = guid();
    var mqtt = {
      name: mqttConstants.RADIO_BROKER,
      host: mqttConstants.RADIO_BROKER_URL,
      priority_broker: false,
      options: {
        queueQoSZero: false,
        qos: 0,
        clientId: id,
        resubscribe: true
      }
    };

    return mqtt;
};

export default radioMqttFactory;