import React from 'react';
import '../../styles/settings-view.scss';
import Switch from '../Switch';
import { toggleDarkMode } from '../../dux/settings-dux';
import { clearUserTokens, setActiveAccount } from '../../dux/account-management-dux';
import { openDialog } from '../../dux/dialog-dux';
import { DialogPrompts } from '../../constants/dialogPrompts';

class SettingsView extends React.Component {
  toggleDarkMode = () => {
    this.props.dispatch(toggleDarkMode());
  }

  logout = () => {
    this.props.dispatch(openDialog({
      content: DialogPrompts.Logout,
      yesButton: () => {
        // Log them out and send them to login
        this.props.dispatch(clearUserTokens());
        window.location.assign(`${process.env.REACT_APP_ACCOUNTS_UI_SLINGSHOT}${window.location.href}`);
      }
    }));
  }

  render() {
    return (
      <div className='settings-view'>
        <h1>{'OMNiDRIVE SETTINGS'}</h1>
        <ul className='settings-list'>

          <li className='setting'>
            {'Dark Mode'}
            <Switch checked={this.props.settings.darkMode} onChange={this.toggleDarkMode} />
          </li>

          <li className='setting'>
            {'Log Out'}
            <button className='logout-button'
              onClick={this.logout}>
              {'Log Out'}
            </button>
          </li>
        </ul>

      </div>
    );
  }
}

export default SettingsView;