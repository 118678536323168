
// utilities
import objectHelper from './objectHelper';

export default class socketServerHelper {
    static AAVI_SOCKET_CLIENT_NAME = 'aaviClientSocket';
    static MC_SOCKET_CLIENT_NAME = 'mcClientSocket';

    static getAaviSocketName() {
        return '/'+socketServerHelper.AAVI_SOCKET_CLIENT_NAME;
    }

    static getMcSocketName() {
        return '/'+socketServerHelper.MC_SOCKET_CLIENT_NAME;
    }

    static disconnectAllPreviousClients(io, socketClientName) {
        io.of(socketClientName).clients((error, clients) => {
            if (error) throw error;

            if (clients.length > 1) {
                for (var i = clients.length - 2; i >= 0; i--) {
                    // disconnect other clients
                    console.log('---------Manually disconnecting client: ' + clients[i]);
                    io.of(socketClientName).clients().connected[clients[i]].disconnect();
                }
            }
        });
    }

    static sendDisarmIfAllClientsDisconnected(commManager, socketServerConnections, onDisconnectCallback) {
        if (objectHelper.checkSize(socketServerConnections) < 1) {
            onDisconnectCallback();
        }
    }
}