import React from 'react';
import Loader from '../src/components/Loader';
import { connect } from 'react-redux';
import { commInit, destroyWorkersAction } from './SmartAgUI-Common/actions/commActions';
import wifiMqttFactory from './SmartAgUI-Common/wifiMqttFactory';
import commEventListeners from './SmartAgUI-Common/commEventListeners';
import { getNewIotToken } from './dux/iot-token-dux';

class ContentContainer extends React.Component {
  componentDidUpdate(prevProps) {
    // We need to initialize connection with machines only once. Only do this when we know the user was just verified.
    if (prevProps.iotToken.secretKey !== this.props.iotToken.secretKey) {
      // if we have an existing comm client, end it.
      console.log(this.props.comm.client);
      if (this.props.comm.client) {
        console.log('destroy comm workers');
        this.props.dispatch(destroyWorkersAction());
      }
      this.initMachineCommunication();
    }
  }

  initMachineCommunication = () => {
    console.log('initMachineCommunication')
    const { accountManagement, dispatch, combineInfo, tractorInfo, iotToken } = this.props;
    const wifiMqtt = wifiMqttFactory(process.env.REACT_APP_MQTT_URL);

    const commInitAction = commInit('[AAVI]', [wifiMqtt], process.env.REACT_APP_MQTT_URL, null, iotToken, getNewIotToken);
    const getCombineInfoCallback = () => {
      return combineInfo;
    };
    const getTractorInfoCallback = () => {
      return tractorInfo;
    };

    dispatch(commInitAction);
    commEventListeners.combineStateListenerSlimCore(dispatch, getCombineInfoCallback, accountManagement.activeAccount.company_id);
    commEventListeners.combineSecondaryStateListenerSlimCore(dispatch, accountManagement.activeAccount.company_id);
    commEventListeners.tractorStateListenerSlimCore(dispatch, getTractorInfoCallback, accountManagement.activeAccount.company_id);
  }

  render() {
    if (this.props.accountManagement.error || !this.props.accountManagement.activeAccount.account_id) {
      return <Loader />;
    }

    return (
      <div className='content-container'>
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ContentContainer);