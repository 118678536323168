// modules
import turf from 'turf';
import booleanContains from '@turf/boolean-contains';
import booleanWithin from '@turf/boolean-contains';
import unkinkPolygon from '@turf/unkink-polygon';
import olProj from 'ol/proj';
import olEasing from 'ol/easing';
import olExtent from 'ol/extent';
import { easing } from 'ol';
import olFeature from 'ol/feature';
import olFormatGeoJson from 'ol/format/geojson';
import olGeomLineString from 'ol/geom/linestring';
import olGeomLinearRing from 'ol/geom/linearring';
import olGeomPolygon from 'ol/geom/polygon';
import olGeomPoint from 'ol/geom/point';

//import ol from 'ol';
// utilities
import openLayerTempIdConsts from '../../SmartAgUI-Common/map/openLayerTempIdConsts';
import * as mapLogic from './mapLogic';
import * as styles from './styles';
import { selectedField } from './mapInteractions';
import {
  pinTypes,
  newpinTypes,
  olLayerTypes,
  olInteractionTypes
} from '../config';

// utility variable
var geoJSONFormat = new olFormatGeoJson();


var findInteraction = function(interactionName) {
  var desiredInteraction;
  mapLogic.getMap().getInteractions().forEach(function (interaction) {
    if(interaction.get("name") == interactionName) {
      desiredInteraction = interaction;
    }
  });
  return desiredInteraction;
};


export var findLayer = function(layerName) {
  var desiredLayer;
  mapLogic.getMap().getLayers().forEach(function (layer) {
    if(layer.get("name") == layerName) {
      desiredLayer = layer;
    }
  });
  return desiredLayer;
};

var findFeature = function(layerName, featureName) {
  var features = findLayer(layerName).getSource().getFeatures();
  for(var i=0; i<features.length; i++) {
    if(features[i].getProperties().name == featureName) {
      return features[i];
    }
  }
  return null;
};

var findFeatureByID = function(layerName, featureID) {
  var fieldLayer = findLayer(layerName);
  var fieldLayerSource = fieldLayer.getSource();
  var fieldFeature = fieldLayerSource.getFeatureById(featureID);
  return fieldFeature;
};

function removeFeature(layer, nameToRemove) {
  //if(findFeature(layer, nameToRemove) == null) return;
  var layer = findLayer(layer);
  var features = layer.getSource().getFeatures();
  if (features != null && features.length > 0) {
    var x;
    for (x in features) {
      if (features[x].getProperties().name == nameToRemove) {
        layer.getSource().removeFeature(features[x]);
        break;
      }
    }
  }
}

/**
 * Removes a specified field from the fields layer data source
 *
 * fieldID - the ID of the field to be removed
 */
export function removeFieldFromMap(fieldID) {
  // deselect the field so it can be removed
  selectedField.clear();
  // get the data source of the fields layer
  var fieldsSource = findLayer(olLayerTypes.FIELD).getSource();
  // remove the specified field from thge layer
  var fieldFeature = fieldsSource.getFeatureById(fieldID);
  if(fieldFeature != null){
    fieldsSource.removeFeature(fieldFeature);
  }
}


function clearLayer(layerName) {

  clearSelections();
  
  var layer = findLayer(layerName);
  layer.getSource().clear();
}

function addSelectedFeature(feature)
{
  findInteraction("select").dispatchEvent({
    feature,
    type: 'addFeature'
  });
}

// get and return the coverage map data from the layer
function getCoverageMapData() {
  var coverageMapLayer = findLayer(olLayerTypes.COVERAGE_MAP);
  return geoJSONFormat.writeFeatures(coverageMapLayer.getSource().getFeatures());
}


export function addFieldToMap(fieldID, fieldData) {
  var features = geoJSONFormat.readFeatures(fieldData);
  features[0].setStyle(styles.fieldStyleDefault);
  features[0].setId(fieldID);
  features[0].setProperties({ "type": "field" })

  var vector = findLayer("vector");
  // Add active field to the map
  vector.getSource().addFeatures(features);
}


/**
 * Add the inner boundary data to the field data. The inner boundary data is
 * appended to the array of boundaries since the first boundary is always the
 * field.
 *
 * fieldFeature - the feature fo the field data
 * innerBoundaryCoordinates - the set of coordinates for the inner boundary
 */
export function addInnerBoundary(fieldGeometry, innerBoundaryCoordinates) {
  // create inner boundary geometry
  var innerBoundary = new olGeomLinearRing(innerBoundaryCoordinates);
  // add inner boundary to the field
  fieldGeometry.appendLinearRing(innerBoundary);
}



// return field data in string format
export function getFieldData() {
  return geoJSONFormat.writeFeatures(findLayer(olLayerTypes.FIELD).getSource().getFeatures());
}

function centerMapOnCoordinate(coordinate) {
  mapLogic.getMap().getView().setCenter(coordinate);
}

/**
 * Centers the map view on the extent given
 *
 * extent - array of coordinates of the extent
 */
export function centerMap(extentCoordinates) {
  // extentCoordinates - array<number>[minx, miny, maxx, maxy]
 
  var centerCoordinate = olExtent.getCenter(extentCoordinates);
  var map = mapLogic.getMap();
  var mapView = map.getView();
  mapView.fit(extentCoordinates, {
      size: map.getSize(),
      duration: 500
    }
  );
  // Center the map
  mapLogic.getMap().getView().animate({ center: centerCoordinate, duration: 500 });
}

export function removePin(pinID) {
  // get the pin layer source data
  var pinLayerSource = findLayer(olLayerTypes.FIELD_PINS).getSource();
  // get the specific pin feature by id
  var pinFeature = pinLayerSource.getFeatureById(pinID);
  // remove the pin feature from the pin layer
  pinLayerSource.removeFeature(pinFeature);
}

function stopDrawRoute() {
  clearLayer(olLayerTypes.INVISIBLE);
  findInteraction(olInteractionTypes.DRAW_ROUTE).setActive(false);
}
function drawRoute(drawEndCallback = null)
{
  // clear any old custom routes
  clearLayer(olLayerTypes.INVISIBLE);
  // get the drawRoute interaction
  var drawRouteInteraction = findInteraction(olInteractionTypes.DRAW_ROUTE);
  // enable route drawing in open layers
  drawRouteInteraction.setActive(true);
  // if a callback function is sent, sets up the event for it
  if (drawEndCallback !== null) {
    drawRouteInteraction.on('drawend', drawEndCallback);
  }
}

function finishDrawRoute() {
  // get the drawRoute interaction
  var drawRouteInteraction = findInteraction(olInteractionTypes.DRAW_ROUTE);

  // stops the drawing in open layers and adds the feature to the layer
  drawRouteInteraction.finishDrawing();

  var customRouteLayer = findLayer(olLayerTypes.INVISIBLE);
  var customRouteFeature = customRouteLayer.getSource().getFeatures()[0];

  // disable route drawing in open layers
  drawRouteInteraction.setActive(false);


  // remove all feature selections so they can be removed
  clearSelections();

  // update the map
  mapLogic.getMap().updateSize();

  return customRouteFeature.getGeometry().getCoordinates();
}


function isAnyPinSet(pins) {
  for (var key in pins) {
    if (pins[key]) {
      return true;
    }
  }
  return false;
}

function clearSelections(){
  findInteraction(olInteractionTypes.SELECT).dispatchEvent({
    type: 'clearSelections'
  });
}

function removePinsFromMap() {
  // remove all feature selections so they can be removed
  clearSelections();

  var vector = findLayer("fieldPins");
  // get all the features in the geoJSON field file
  vector.getSource().clear();
}

function isCoordinateInField(fieldData, coordinate) {
  // parse the field data
  var fieldLayer = findLayer(olLayerTypes.FIELD);
  var featuresFound = fieldLayer.getSource().getFeaturesAtCoordinate(coordinate);
  if (featuresFound.length > 0) {
    return true;
  } else {
    return false;
  }
}

/**
 * Centers the field on a specific coordinate
 *
 * coordinate - the coordinate to center the map view on
 */
export function centerOnCoordinate(coordinate) {
  mapLogic.getMap().getView().animate({ center: coordinate, duration: 75 });
  //mapLogic.getMap().getView().setCenter(coordinate);
}

// parse out the geometry coordinates from a feature
export function getFeatureCoordinates(feature) {
  return feature.getGeometry().getCoordinates();
}

function getFieldByID(fieldID) {
  return findLayer(olLayerTypes.FIELD).getSource().getFeatureById(fieldID);
}

/**
 * Retrieves the extent of a specific field
 *
 * fieldID - ID of the field
 */
export function getFieldExtent(fieldID) {
  return findLayer(olLayerTypes.FIELD)
          .getSource()
          .getFeatureById(fieldID)
          .getGeometry()
          .getExtent();
}

/**
 * Retrieves the extent of the field layer source
 */
export function getFieldsExtentLonLat() {
  var fieldExtent = findLayer(olLayerTypes.FIELD).getSource().getExtent();
  return fieldExtent;
}

function setFieldCoordinates(fieldFeature, fieldCoordinates) {
  fieldFeature.getGeometry().setCoordinates(fieldCoordinates);
}

function getCoordinatesFromFieldData(fieldData) {
  // convert to JSON if data is in string format
  return geoJSONFormat.readFeatures(fieldData)[0].getGeometry().getCoordinates();
}

function getFieldDataString() {
  var fieldData = findLayer(olLayerTypes.FIELD).getSource().getFeatures();
  return geoJSONFormat.writeFeatures(fieldData);
}

// get the field data in string format
export function getFieldDataStringById(fieldID) {
  // get the field feature
  var fieldFeature = getFieldByID(fieldID);
  // write out as a feature collection (must be an Array<Feature>)
  return geoJSONFormat.writeFeatures([fieldFeature]);
}

// get the field data in object format
export function getFieldDataObjectById(fieldID) {
    // get the field feature
    var fieldFeature = getFieldByID(fieldID);
    // write out as a feature collection (must be an Array<Feature>)
    return geoJSONFormat.writeFeaturesObject([fieldFeature]);
}

export function cancelUpdatingField(fieldID, fieldData) {
  var fieldFeature = findLayer(olLayerTypes.FIELD).getSource().getFeatureById(fieldID);
  // clear the inner boundary layer
  clearLayer(olLayerTypes.INNER_BOUNDARY);
  // revert OpenLayers field data
  fieldFeature.getGeometry().setCoordinates(getFieldFeatureCoordinates(fieldData));
}

// checks geometry of specified feature against an array of features
// returns an array of the indices of the features that are invalid
export function isUnkinkedFeatureOverlappingOtherFeatures(feature, otherFeatures, ignoreIndex) {
  // array of invalid indices to be returned
  var invalidFeaturesByIndex = [];
  // loop through all the features - if a feature had a kink in it, it will have multiple polygons (reason for nested loops)
  for (var i = 0; i < otherFeatures.length; i++) {
    if (i !== ignoreIndex) {
      for (var j = 0; j < feature.length; j++) {
        for (var k = 0; k < otherFeatures[i].length; k++) {
          // if the two geometries intersect - add it to the array of invalid indices
          if (turf.intersect(feature[j].geometry, otherFeatures[i][k].geometry)) {
            invalidFeaturesByIndex.push(i);
          }
        }
      }
    }
  }
  return invalidFeaturesByIndex;
}


/**
 * Checks if a feature object overlaps any features from a given array
 *
 * feature - feature object to focus on
 * otherFeatures - array of features to loop through and compare against 'feature'
 * ignoreIndex (optional) - index to ignore comparing against in the 'otherFeatures' array
 */
export function isFeatureOverlappingOtherFeatures(feature, otherFeatures, ignoreIndex = null) {
  // array of invalid indices to be returned
  var invalidFeaturesByIndex = [];
  // loop through all the features - if a feature had a kink in it, it will have multiple polygons (reason for nested loops)
  for (var i = 0; i < otherFeatures.length; i++) {
    if (i !== ignoreIndex || ignoreIndex === null) {
      // if the two geometries intersect - add it to the array of invalid indices
      if (turf.intersect(feature.geometry, otherFeatures[i].geometry)) {
        invalidFeaturesByIndex.push(i);
      }
    }
  }
  return invalidFeaturesByIndex;
}

/**
 * Runs validation on the field
 *
 * fieldFeature - The OpenLayers feature of the field to validate
 */
export function validField(fieldFeature) {
  var { fieldBoundary, innerBoundaries } = getFieldGeometries(fieldFeature.getGeometry());

  // a kink means that the field is overlapping itself
  if (unkinkPolygon(fieldBoundary).features.length > 1) {
    return false;
  }
  // if inner boundaries are not all contained by the field - field is invalid
  var withinField = allBoundariesWithinField(fieldBoundary, innerBoundaries);
  if (!withinField) {
    return false;
  }
  // last check is to see if any inner boundaries overlap eachother
  if (innerBoundaries.length > 1) {
    return !doGeometriesOverlap(innerBoundaries);
  }

  // field is valid
  return true;
}

/**
 * Checks if the geometry of an inner boundary is contained in the field
 *

 * field - geometry of the field
 * innerBoundaries - geometries of all the inner boundaries
 */
export function allBoundariesWithinField(fieldGeometry, innerBoundaries) {
  // loop through all the inner boundaries
  for (var i = 0; i < innerBoundaries.length; i++) {
    // if the geometry of the inner boundary is completely contained inside the field boundary - return true
    if (!booleanContains(fieldGeometry, innerBoundaries[i])) {
      return false;
    }
  }
  // if the for loop was finished, we know all boundaries are contained by the field
  return true;
}

/**
 * Loops through all geometries and checks if any of them overlap each other
 *
 * geometries - The geometries to check for overlapping
 */
export function doGeometriesOverlap(geometries) {

  for (var i = 0; i < geometries.length; i++) {
    for (var j = i+1; j < geometries.length; j++) {
      if (turf.intersect(geometries[i], geometries[j])) {
        return true;
      }
    }
  }
  return false;
}


/**
 * Parses the field data from a string and returns the field geometry object
 */
export function fieldGeometryObjectFromString(fieldData) {
  var fieldGeometry = geoJSONFormat
                        .readFeatures(fieldData)[0]
                        .getGeometry();

  return geoJSONFormat.writeGeometryObject(fieldGeometry);
}

/**
 * Finds and returns the geometry object of a pin
 */
export function getPinGeometryObject(pinName) {
  var pinLayer = findLayer(olLayerTypes.FIELD_PINS).getSource();
  var pinGeometry = pinLayer
                      .getFeatureById(pinName) // retrieve the pin feature from the layer
                      .getGeometry(); // geometry of pin
  return geoJSONFormat.writeGeometryObject(pinGeometry);

}

/**
 * Checks if the geometry of a pin is contained in the field
 *
 * fieldBoundary - geometry object of the field boundary
 * pinGeometry - geometry object of the pin
 */
export function isPinWithinField(pinGeometry, fieldBoundaryGeometry) {

  // if the field s a multi polygon, loop through all outer boundaries
  if (fieldBoundaryGeometry.type.toLowerCase() === 'polygon') {
    return isPointWithinPolygon(pinGeometry, fieldBoundaryGeometry);
  } else {
    var validPoint = false;
    for (var i = 0; i < fieldBoundaryGeometry.coordinates.length; i++) {
      var outerBoundaryGeometry = geoJSONFormat.writeGeometryObject(new olGeomPolygon(fieldBoundaryGeometry.coordinates[i]));
      if (isPointWithinPolygon(pinGeometry, outerBoundaryGeometry)) {
        validPoint = true;
      }
    }
    return validPoint;
  }
}

/**
 * Checks if the geometry of a point is contained in a polygon geometry
 *
 * polygonGeometry - geometry object of the polygon
 * pointGeometry - geometry object of the point
 */
export function isPointWithinPolygon(pointGeometry, polygonGeometry) {
  // if the geometry of the inner boundary is completely contained inside the field boundary - return true
  if (booleanContains(polygonGeometry, pointGeometry)) {
    return true;
  } else {
    // the geometry of the inner boundary intersects the field boundary - return false
    return false;
  }
}


/**
 * Checks if the geometry of an inner boundary is contained in the field
 *
 * fieldBoundaryCoordinates - coordinates of field outer boundary
 * geometry - geometry of the inner boundary
 */
export function isBoundaryWithinField(boundaryFeatureObject, fieldBoundaryFeatureObject) {
    var polyDifference = turf.difference(boundaryFeatureObject, fieldBoundaryFeatureObject);
    // if the difference returned is undefined, we know that the geometry is completely contained by the other
    return polyDifference == undefined;
}

export function anyCoordinatesInPolygon(coordinates, polygon) {
  for (var i = 0; i < coordinates.length; i++) {
    var point = {
      type: 'Point',
      coordinates: coordinates[i]
    }
    if (booleanWithin(polygon, point)) {
      return true;
    }
  }
  return false;
}
/**
 * Removes any existing kinks from an array of features and returns the unkinked features array
 *
 * features - the list of features to be unkinked (if any)
 */
export function removeKinksFromFeatures(features) {
  var unkinkedFeatures = [];
  for (var i = 0; i < features.length; i++) {
    var geometryObject = geoJSONFormat.writeGeometryObject(features[i].getGeometry());
    if (!doesBoundaryContainKink(geometryObject)) {
      unkinkedFeatures.push([geoJSONFormat.writeFeatureObject(features[i])]);
    } else {
      unkinkedFeatures.push(unkinkPolygon(geometryObject).features);
    }
  }
  return unkinkedFeatures;
}

/**
 * Removes any existing kinks from an array of features and returns the unkinked features array
 *
 * feature - the feature to be unkinked
 */
export function removeKinksFromFeature(feature) {

  var geometryObject = geoJSONFormat.writeGeometryObject(feature.getGeometry());
  geometryObject.coordinates = removeDuplicateVertices(geometryObject);
  feature.getGeometry().setCoordinates([geometryObject.coordinates]);
  if (!doesBoundaryContainKink(geometryObject)) {
    return [geoJSONFormat.writeFeatureObject(feature)];
  } else {
    return unkinkPolygon(geometryObject).features;
  }
}

function removeDuplicateVertices(geometry) {
  var coordinates = geometry.coordinates[0];
  for (var i = 0; i < coordinates.length; i++) {
    if (i > 0 && matchingCoordinates(coordinates[i-1], coordinates[i])) {
      coordinates.splice(i, 1);
      i--;
    }
  }
  return coordinates;
}

export function getFeatureObjects(features) {
  var featureObjects = [];
  for (var i = 0; i < features.length; i++) {
    var featureObject = geoJSONFormat.writeFeatureObject(features[i]);
    featureObjects.push(featureObject);
  }
  return featureObjects;
}
export function getInnerBoundaryFeatureObjectsFromFieldData(fieldData) {
  var fieldDataGeometry = fieldData.features[0].geometry;
  // construct the geometry object to in comparison
  var innerBoundaryFeatureObjects = [];
  for (var i = 1; i < fieldDataGeometry.coordinates.length; i++) {
    var featureObject = formatPolygonFeatureObjectFromCoordinates([fieldDataGeometry.coordinates[i]]);
    innerBoundaryFeatureObjects.push(featureObject);
  }
  return innerBoundaryFeatureObjects;
}

/**
 * Checks if the specified geometry is overlapping any geometry from a collection
 *
 * featureObject - coordinates of boundary to check against other boundaries
 * featureObjectCollection - collection of the inner boundary features
 */
export function isBoundaryOverlappingOtherBoundaries(featureObject, featureObjectCollection, ignoreIndex=null) {
  try {
    for (var i = 0; i < featureObjectCollection.length; i++) {
      if (ignoreIndex !== i && turf.intersect(featureObject, featureObjectCollection[i])) {
        return true;
      }
    }
    // if we've gotten to this point, we know that no geometries intersect - return false
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

/**
 * Checks if the specified geometry is overlapping itself
 *
 * geometry - Geometry of the inner boundary being checked
 */
export function doesBoundaryContainKink(geometry) {
  try {
    // check if the features array returned contains intersecting points
    if (turf.kinks(geometry).features.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
}


/**
 * Takes an array of coordinate sets and returns an array of geojson polygon features
 *
 * coordinates - the array of coordinate sets
 */
export function formatPolygonsFromCoordinates(coordinates) {
  // array of polygons to be returned
  var polygons = [];
  // loop through all the coordinates and create a polygon
  for (var i = 0; i < coordinates.length; i++) {
    // add the geojson polygon feature to the array
    polygons.push(new olFeature({
      geometry: new olGeomPolygon([coordinates[i]])
    }));
  }
  return polygons;
}

/**
 * Takes the field feature and places it into the field data format to be saved.
 * The field feature needs to be in a feature collection before placed into the
 * database.
 *
 * fieldData - the field data that needs to be written into a feature collection
 */
export function writeFieldDataToString(fieldFeature) {
  return geoJSONFormat.writeFeatures([fieldFeature]);
}

/**
 * Creates a new feature collection from the field geometry sent in
 *
 * fieldGeometry - the geometry to use for the feature of the feature collection
 */
export function writeFieldGeometryToString(fieldGeometry) {
  return geoJSONFormat.writeFeatures([new olFeature({
    geometry: geoJSONFormat.readGeometry(fieldGeometry)
  })]);
}

export function getFieldFeatureObject(fieldData) {
  var fieldBoundaryCoordinates = fieldData.features[0].geometry.coordinates;
  var fieldBoundaryObject = formatPolygonFeatureObjectFromCoordinates(fieldBoundaryCoordinates);
  return fieldBoundaryObject;
}

export function getFieldBoundaryFeatureObject(fieldData) {
  var fieldBoundaryCoordinates = fieldData.features[0].geometry.coordinates[0];
  var fieldBoundaryObject = formatPolygonFeatureObjectFromCoordinates([fieldBoundaryCoordinates]);
  return fieldBoundaryObject;
}

/**
 * Returns an array of polygons from the field geometry
 *
 * fieldGeometry - field geometry to retrieve polygons from
 */
export function getFieldPolygons(fieldGeometry) {
  // the field is always the first index of the feature collection
  return getFieldPolygons(fieldGeometry).getLinearRings();
}
/**
 * Returns an array of polygons from the given set of coordinates
 *
 * fieldCoordinates - the set of coordinates from the field
 */
export function getFieldPolygonsFromCoordinates(fieldCoordinates) {
  // construct a field geometry object
  var fieldGeometryObject = {
    type: 'Polygon',
    coordinates: [fieldCoordinates]
  }
  // return all the linear rings (polygons) feom the field geometry
  return geoJSONFormat.readGeometry(fieldGeometryObject).getLinearRings();
}


/**
 * Parses out all the geometry objects from the field geometry
 *
 * fieldGeometry - The geometry of the field to parse out
 *
 * return - Object with two properties (innerBoundaries and fieldBoundary)
 */
export function getFieldGeometries(fieldGeometry) {
  var linearRings = fieldGeometry.getLinearRings();
  var innerBoundaries = [];
  var fieldBoundary;
  for (var i = 0; i < linearRings.length; i++) {
    if (i !== 0) {
      innerBoundaries.push({
        type: 'Polygon',
        coordinates: [linearRings[i].getCoordinates()]
      });
    } else { // first index is always the field boundary
      fieldBoundary = {
        type: 'Polygon',
        coordinates: [linearRings[i].getCoordinates()]
      };
    }
  }

  return {
    fieldBoundary,
    innerBoundaries
  };
}

/**
 * Returns a polygon feature from a set of coordinates
 *
 * fieldCoordinates - the set of coordinates from the field
 */
export function getFieldFeatureFromCoordinates(fieldCoordinates) {
  // construct a polygon feature object
  return new olFeature({
    geometry: new olGeomPolygon(fieldCoordinates)
  });
}

// parse out coordinates from field
export function getFieldFeatureCoordinates(fieldData) {
  return fieldData.features[0].geometry.coordinates;
}

/**
 * Create a polygon feature from a set of coordinates
 *
 * coordinates - the set of coordinates to use to create the feature
 */
export function formatPolygonFeatureObjectFromCoordinates(coordinates) {
  return geoJSONFormat.writeFeatureObject(new olFeature({
    geometry: new olGeomPolygon(coordinates)
  }));
}

/**
 * Set the styling of the feature to the INVALID inner boundary style
 *
 * innerBoundaryFeature - the feature that needs the styling updated
 */
export function invalidInnerBoundary(innerBoundaryFeature) {
  innerBoundaryFeature.setStyle(styles.invalidInnerBoundaryStyle);
}
/**
 * Set the styling of the feature to the VALID inner boundary style
 *
 * innerBoundaryFeature - the feature that needs the styling updated
 */
export function validInnerBoundary(innerBoundaryFeature) {
  innerBoundaryFeature.setStyle(styles.fieldStyleNew);
}

/**
 * Update the field feature style to the normal state
 *
 * fieldFeature - the feature of the field data
 */
export function updateFieldStyleNormal(fieldFeature) {
  fieldFeature.setStyle(styles.fieldStyleDefault);
}

/**
 * Update the field feature style to the selected state
 *
 * fieldFeature - the feature of the field data
 */
export function updateFieldStyleSelected(fieldFeature) {
  fieldFeature.setStyle(styles.fieldStyleSelected);
}

/**
 * Update the field feature style to the modifying state
 *
 * fieldFeature - the feature of the field data
 */
export function updateFieldStyleModify(fieldFeature) {
  fieldFeature.setStyle(styles.fieldStyleModify);
}

export function matchingCoordinates(coordinateOne, coordinateTwo) {
  return coordinateOne[0] === coordinateTwo[0] && coordinateOne[1] ===  coordinateTwo[1];
}


// module.exports.getFieldDataString = getFieldDataString;
// module.exports.getFieldByID = getFieldByID;
// module.exports.getCoordinatesFromFieldData = getCoordinatesFromFieldData;
// module.exports.setFieldCoordinates = setFieldCoordinates;
// module.exports.isCoordinateInField = isCoordinateInField;
// module.exports.findInteraction = findInteraction;
// module.exports.findLayer = findLayer;
// module.exports.getCoverageMapData = getCoverageMapData;
// module.exports.findFeature = findFeature;
// module.exports.findFeatureByID = findFeatureByID;
// module.exports.removeFeature = removeFeature;
// module.exports.isAnyPinSet = isAnyPinSet;
// module.exports.drawRoute = drawRoute;
// module.exports.stopDrawRoute = stopDrawRoute;
// module.exports.finishDrawRoute = finishDrawRoute;
// module.exports.clearLayer = clearLayer;
// module.exports.addSelectedFeature = addSelectedFeature;
// module.exports.removePinsFromMap = removePinsFromMap;
// module.exports.centerMapOnCoordinate = centerMapOnCoordinate;
