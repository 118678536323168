
export default class wildcardDict{
    constructor(){
        this.dict = {};
    }

    get(topic){
        var allMatches = [];
        var keys = Object.keys(this.dict);
        var key1 = topic.split('/');

        for(var i = 0; i < keys.length; i++){
            var topicFromDict = keys[i];
            var key2 = topicFromDict.split('/');

            var maxArrayLength = Math.max(key1.length, key2.length);

            for(var j = 0; j < maxArrayLength; j++){
                var a = key1[j];
                var b = key2[j];

                if(b === '#'){
                    allMatches.push(this.dict[topicFromDict]);
                    break;
                }
                else if(b === '+'){
                    //noop
                }
                else if(a !== b){
                    break;
                }
                else if(a === b && j+1 === maxArrayLength){
                    allMatches.push(this.dict[topicFromDict]);
                }
            }
        }

        //turns 2d array to 1d
        return [].concat.apply([], allMatches);;
    }

    push(topic, callback){
        var items = this.dict[topic];
        if(items == null){
            this.dict[topic] = [callback];
        }
        else{
            this.dict[topic].push(callback);
        }
    }

    remove(topic, callback){
        var items = this.dict[topic];
        if(items == null)
            throw new Error(`Topic: ${topic} not found in dict.`);

        var callbackIndex = items.indexOf(callback);
        if(callbackIndex < 0)
            throw new Error(`Topic: ${topic} was found in dict, but callback was not (Callback: ${JSON.stringify(callback)}`);

        items.splice(callbackIndex, 1);

        if(this.dict[topic].length < 1)
            delete this.dict[topic];
    }

    length(){
        return Object.keys(this.dict).length;
    }
}