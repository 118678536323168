import dateUtils from '../dateUtils';
import { v4 as uuidv4 } from 'uuid';

export default class secondaryCombineModel{
  constructor(combineName, lon, lat, heading)
  {
    this.id = uuidv4();
    this.update(combineName, lon, lat, heading);
  }

  update(combineName, lon, lat, heading) {
    this.combineName = combineName;
    this.lon = lon;
    this.lat = lat;
    this.lastUpdated = new Date();
    this.heading = heading;
  }

  isValid(){
    //The combine is valid if it has been "updated" in the last 60 seconds
    const sixtySecondsAgo = dateUtils.dateAdd(new Date(), 'second', -60).getTime() / 1000;
    const lastUpdated = this.lastUpdated.getTime() / 1000;
    const isRecentlyUpdateCombine = lastUpdated >= sixtySecondsAgo;

    return isRecentlyUpdateCombine;
  }
}