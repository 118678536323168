export default class timeUtil{
    static getTimestampSeconds() {
        return Date.now() / 1000;
    }

    static getCurrentUtcTimestamp() {
        return Date.now();
    }
    
    // wrapper function here just in case someday we decide to use something like moment.js 
    static parseUtcStringToTimestamp(utcStr) {
        return Date.parse(utcStr);
    }
}