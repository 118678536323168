const actionTypes = {

    // Camera feed
    CAMERA_FEEDS_START: "CAMERA_FEEDS_START",
    CAMERA_FEEDS_STOP: "CAMERA_FEEDS_STOP",
    CAMERA_FEEDS_RECIEVED_NEW_IMG: "CAMERA_FEEDS_RECIEVED_NEW_IMG",

    //Tracked Objects
    UPDATE_TRACKED_OBJECTS_STATE: "UPDATE_TRACKED_OBJECTS_STATE",

    //Secondary Combine
    UPDATE_SECONDARY_COMBINE_STATE: "UPDATE_SECONDARY_COMBINE_STATE",

    // Notifications
    NOTIFICATIONS_ADD: "NOTIFICATIONS_ADD",
    NOTIFICATIONS_REMOVE: "NOTIFICATIONS_REMOVE",
    NOTIFICATIONS_ADD_NO_DUPLICATES: "NOTIFICATIONS_ADD_NO_DUPLICATES",

    // MQTT
    MQTT_INIT: "MQTT_INIT",
    MQTT_CONNECTED: "MQTT_CONNECTED",
    MQTT_DISCONNECTED: "MQTT_DISCONNECTED",

    // Tractor Info
    UPDATE_TRACTOR_GPS: "UPDATE_TRACTOR_GPS",
    UPDATE_TRACTOR_CAN: "UPDATE_TRACTOR_CAN",
    UPDATE_TRACTOR_MACHINE_STATUS: "UPDATE_TRACTOR_MACHINE_STATUS",
    UPDATE_TRACTOR_MACHINE_STATUS_EXTRA: "UPDATE_TRACTOR_MACHINE_STATUS_EXTRA",
    UPDATE_TRACTOR_SENSOR_STATUS: "UPDATE_TRACTOR_SENSOR_STATUS",
    UPDATE_TRACTOR_NAME: "UPDATE_TRACTOR_NAME",
    UPDATE_TRACTOR_ID: "UPDATE_TRACTOR_ID",
    UPDATE_TRACTOR_BROKER_STATUS: "UPDATE_TRACTOR_BROKER_STATUS",
    UPDATE_TRACTOR_RUNNING_COMMAND: "UPDATE_TRACTOR_RUNNING_COMMAND",
    UPDATE_TRACTOR_CONFIG: "UPDATE_TRACTOR_CONFIG",
    UPDATE_TRACTOR_LAST_RECEIVED_PING_MILLISECONDS: "UPDATE_TRACTOR_LAST_RECEIVED_PING_MILLISECONDS",
    UPDATE_TRACTOR_CONFIG_OPTIONS: "UPDATE_TRACTOR_CONFIG_OPTIONS",
    INVALIDATE_TRACTOR_DATA: "INVALIDATE_TRACTOR_DATA",

    // Combine Info
    UPDATE_COMBINE_GPS: "UPDATE_COMBINE_GPS",
    UPDATE_COMBINE_SYNC_POINT: "UPDATE_COMBINE_SYNC_POINT",
    UPDATE_COMBINE_CAN: "UPDATE_COMBINE_CAN",
    UPDATE_COMBINE_MACHINE_STATUS: "UPDATE_COMBINE_MACHINE_STATUS",
    UPDATE_COMBINE_MACHINE_STATUS_EXTRA: "UPDATE_COMBINE_MACHINE_STATUS_EXTRA",
    UPDATE_COMBINE_SENSOR_STATUS: "UPDATE_COMBINE_SENSOR_STATUS",
    UPDATE_COMBINE_NAME: "UPDATE_COMBINE_NAME",
    UPDATE_COMBINE_HEAD: "UPDATE_COMBINE_HEAD",
    UPDATE_COMBINE_ID: "UPDATE_COMBINE_ID",
    UPDATE_COMBINE_BROKER_STATUS: "UPDATE_COMBINE_BROKER_STATUS",
    UPDATE_COMBINE_RUNNING_COMMAND: "UPDATE_COMBINE_RUNNING_COMMAND",
    UPDATE_COMBINE_CONFIG: "UPDATE_COMBINE_CONFIG",
    INVALIDATE_COMBINE_DATA: "INVALIDATE_COMBINE_DATA",

    // Coverage
    INIT_COVERAGE_MAP: "INIT_COVERAGE_MAP",
    SHOULD_UPDATE_COVERAGE_MAP: "SHOULD_UPDATE_COVERAGE_MAP",
    UPDATE_COVERAGE_MAP: "UPDATE_COVERAGE_MAP",

    // Combine Bubbles
    UPDATE_COMBINE_BUBBLES_PATH_PLANNER: "UPDATE_COMBINE_BUBBLES_PATH_PLANNER",
    UPDATE_COMBINE_BUBBLES_DISPLAY: "UPDATE_COMBINE_BUBBLES_DISPLAY",
}
export default actionTypes;