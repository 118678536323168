import React from 'react';
import '../../styles/form-view.scss';
import FormInput from '../FormInput';
import { checkName, checkDecimal } from '../../helpers/form-validator';

class CreateEditCombineForm extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.machine) {
      const machine = this.props.machine;

      this.state = {
        name: { value: machine.displayName, error: null, validator: checkName },
        forward: { value: machine.forward, error: null, validator: checkDecimal },
        headWidth: { value: machine.headWidth, error: null, validator: checkDecimal },
        syncX: { value: machine.sync_x || '', disabled: true },
        syncY: { value: machine.sync_y || '', disabled: true }
      }
    }
    else {
      // Creating combine - just basic settings
      this.state = {
        name: { value: '', error: null, validator: checkName },
        forward: { value: '', error: null, validator: checkDecimal },
        headWidth: { value: '', error: null, validator: checkDecimal }
      }
    }
  }

  handleInputChange = event => {
    const { value, name } = event.target;

    if (!event.target.validity.valid) return;

    const updatedField = this.validateInput(name, value);

    this.setState({
      [name]: updatedField
    });
  }

  validate = () => {
    const newState = Object.keys(this.state).reduce((acc, key) => {
      acc[key] = this.validateInput(key, this.state[key].value);
      return acc;
    }, {});

    this.setState(newState);
    return !Object.keys(newState).some(x => newState[x].error && !newState[x].optional);
  }

  validateInput = (key, value) => {
    const inputObject = this.state[key];
    const validatedField = {
      ...inputObject,
      value: value,
      // Only validate if the input is not disabled.
      error: !inputObject.disabled && inputObject.validator(value)
    };

    return validatedField;
  }

  renderFormInput(label, key, type) {
    const formItem = this.state[key];

    return (
      <FormInput
        error={formItem.error}
        onChange={this.handleInputChange}
        label={label}
        type={type}
        name={key}
        value={formItem.value}
        step={'0.01'}
        disabled={formItem.disabled}
      />
    );
  }

  renderBasicSettings() {
    return (
      <div className='form-view'>
        {this.renderFormInput('Name', 'name', 'text')}
        {this.renderFormInput('Forward (in.)', 'forward', 'number')}
        {this.renderFormInput('Head Width (in.)', 'headWidth', 'number')}
      </div>
    );
  }

  renderAdvancedSettings() {
    return (
      <div className='form-view'>
        {this.renderFormInput('Sync Point X Offset (meters)', 'syncX', 'number')}
        {this.renderFormInput('Sync Point Y Offset (meters)', 'syncY', 'number')}
      </div>
    );
  }

  render() {
    if (this.props.showAdvanced) {
      return this.renderAdvancedSettings();
    }
    return this.renderBasicSettings();
  }
}

export default CreateEditCombineForm;