import React from 'react';
import '../../styles/form-view.scss';
import FormInput from '../FormInput';
import { TractorType, MachineType, PSTConfigKeys, IVTConfigKeys } from '../../constants/machineType';
import { checkName, checkDecimal, checkInt } from '../../helpers/form-validator';
import { FormError } from '../../constants/formErrors';

const noop = () => { return null };
const initIVT = {
  value: '',
  error: null,
  type: TractorType.IVT,
  validator: checkDecimal,
  parser: parseFloat
};
const initPST = {
  value: '',
  error: null,
  type: TractorType.PST,
  validator: checkInt,
  parser: parseInt
};
const tractorTypeOptions = [
  { name: TractorType.IVT, value: TractorType.IVT },
  { name: TractorType.PST, value: TractorType.PST }
];

class CreateEditTractorForm extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.machine) {
      const machine = this.props.machine;
      const basicInputs = {
        name: { value: machine.displayName, error: null, validator: checkName },
        type: { value: machine.machine_model, error: null, validator: noop },
      };

      if (this.props.machine.machine_model === TractorType.IVT) {
        // Only have speed dials
        this.state = {
          ...basicInputs,
          speedDialF1: { ...initIVT, value: machine.speedDialF1 },
          speedDialF2: { ...initIVT, value: machine.speedDialF2 },
          minRPM: initPST,
          maxRPM: initPST,
        }
      } else {
        // Only have RPMs
        this.state = {
          ...basicInputs,
          speedDialF1: initIVT,
          speedDialF2: initIVT,
          minRPM: { ...initPST, value: machine.minRPM },
          maxRPM: { ...initPST, value: machine.maxRPM },
        }
      }
    } else {
      // Creating tractor: Everything is empty
      this.state = {
        name: { value: '', error: null, validator: checkName },
        type: { value: TractorType.IVT, error: null, validator: noop },
        speedDialF1: initIVT,
        speedDialF2: initIVT,
        minRPM: initPST,
        maxRPM: initPST,
      }
    }
  }

  handleInputChange = event => {
    const { value, name } = event.target;

    if (!event.target.validity.valid) return;

    const updatedField = this.validateInput(name, value);

    if (name === 'type' && !this.props.machine) this.resetConfigFields(value);

    this.setState({
      [name]: updatedField
    });
  }

  validate = () => {
    const newState = Object.keys(this.state).reduce((acc, key) => {
      acc[key] = this.validateInput(key, this.state[key].value);
      return acc;
    }, {});

    this.setState(newState);

    return !Object.keys(newState).some(x => newState[x].error);
  }

  // While creating a machine, switched the type of the tractor - clear the old type's corresponding config values
  resetConfigFields = value => {
    if (value === TractorType.PST) {
      this.setState({
        speedDialF1: initIVT,
        speedDialF2: initIVT
      });
    } else {
      this.setState({
        minRPM: initPST,
        maxRPM: initPST
      });
    }
  }

  validateInput = (key, value) => {
    // Tractor config values are only relevant when corresponding type is selected.
    let needsValidation = true;

    const isConfigValue = this.isConfigValue(key);

    if (isConfigValue) {
      needsValidation = this.state.type.value === this.state[key].type;
    }

    let validationError = needsValidation ? this.state[key].validator(value, key, this.state) : null;

    // Only need to validate a config value further (with it's compliment) if we know it has already passed the basic validation.
    if (isConfigValue && needsValidation && !validationError) {
      validationError = this.validateConfigValueWithItsCompliment(key, value);
    }

    return {
      ...this.state[key],
      value: value,
      error: validationError
    };
  }

  // Config values are minRPM/maxRPM and speedDialF1/speedDialF2.
  // Need to validate that minRPM < maxRPM or that speedDialF1 < speedDialF2.
  validateConfigValueWithItsCompliment = (configKey, configValue) => {
    let error = null;
    // If a config value's compliment is not empty, we need to compare them to make sure this value is valid.
    // Because we are checking this value, we clear the error for it's compliment.

    // Check IVT config values
    if (this.state.type.value === TractorType.IVT) {
      // Check that SpeedDialF1 < SpeedDialF2
      if (configKey === IVTConfigKeys.SpeedDialF1 && this.state.speedDialF2.value.length !== 0) {
        error = parseFloat(configValue) < parseFloat(this.state.speedDialF2.value) ? null : FormError.SpeedDialF1GreaterThanSpeedDialF2;
        this.clearInputFieldError(IVTConfigKeys.SpeedDialF2);
      } else if (configKey === IVTConfigKeys.SpeedDialF2 && this.state.speedDialF1.value.length !== 0) {
        error = parseFloat(this.state.speedDialF1.value) < parseFloat(configValue) ? null : FormError.SpeedDialF2LessThanSpeedDialF1;
        this.clearInputFieldError(IVTConfigKeys.SpeedDialF1);
      }
    } else {
      // Check PST config values
      // Check that MinRPM < MaxRPM
      if (configKey === PSTConfigKeys.MinRPM && this.state.maxRPM.value.length !== 0) {
        error = parseInt(configValue) < parseInt(this.state.maxRPM.value) ? null : FormError.MinRPMGreaterThanMaxRPM;
        this.clearInputFieldError(PSTConfigKeys.MaxRPM);
      } else if (configKey === PSTConfigKeys.MaxRPM && this.state.minRPM.value.length !== 0) {
        error = parseInt(this.state.minRPM.value) < parseInt(configValue) ? null : FormError.MaxRPMLessThanMinRPM;
        this.clearInputFieldError(PSTConfigKeys.MinRPM);
      }
    }

    return error;
  }

  clearInputFieldError = key => {
    this.setState({
      [key]: {
        ...this.state[key],
        error: null
      }
    });
  }

  isConfigValue = key => {
    return this.state[key].type;
  }

  renderNumericInput(input) {
    return (
      <FormInput
        error={this.state[input.name].error}
        key={input.name}
        onChange={this.handleInputChange}
        label={input.label}
        type='number'
        name={input.name}
        value={this.state[input.name].value}
        step={input.step}
        isInt={input.step === 1}
      />
    )
  }

  renderConfigInputs() {
    if (this.state.type.value === TractorType.IVT) {
      return [
        this.renderNumericInput({
          label: 'Speed Dial F1', name: IVTConfigKeys.SpeedDialF1, step: 0.01
        }),
        this.renderNumericInput({
          label: 'Speed Dial F2', name: IVTConfigKeys.SpeedDialF2, step: 0.01
        })
      ];

    } else {
      return [
        this.renderNumericInput({
          label: 'Minimum RPM', name: PSTConfigKeys.MinRPM, step: 1
        }),
        this.renderNumericInput({
          label: 'Maximum RPM', name: PSTConfigKeys.MaxRPM, step: 1
        })
      ];
    }
  }

  renderAllSettings() {
    return (
      <div className='form-view'>
        <FormInput
          error={this.state.name.error}
          onChange={this.handleInputChange}
          label={'Name'}
          type={'text'}
          name={'name'}
          value={this.state.name.value}
        />
        <FormInput
          onChange={this.handleInputChange}
          label='Type'
          type='select'
          name='type'
          selectOptions={tractorTypeOptions}
          value={this.state.type.value}
          dropdownType={MachineType.Tractor}
        />
        {this.renderConfigInputs()}
      </div>
    );
  }

  renderBasicSettings() {
    return (
      <div className='form-view'>
        <FormInput
          error={this.state.name.error}
          onChange={this.handleInputChange}
          label={'Name'}
          type={'text'}
          name={'name'}
          value={this.state.name.value}
        />
      </div>
    );
  }

  renderAdvancedSettings() {
    return (
      <div className='form-view'>
        <FormInput
          onChange={this.handleInputChange}
          label='Type'
          type='select'
          name='type'
          selectOptions={tractorTypeOptions}
          value={this.state.type.value}
          dropdownType={MachineType.Tractor}
        />
        {this.renderConfigInputs()}
      </div>
    );
  }

  render() {
    if (this.props.showAdvanced === undefined) {
      return this.renderAllSettings();
    } else if (this.props.showAdvanced) {
      return this.renderAdvancedSettings();
    }
    return this.renderBasicSettings();
  }
}

export default CreateEditTractorForm;