import FieldService from '../services/v1/FieldService';
import { showDefaultErrorToast } from '../helpers/toast-helper';
import { ErrorMessages } from '../constants/errorMessages';
import { logError } from './account-management-dux';

export const mountPoint = 'field';
export const actions = {
  SET_FIELD_LIST: 'field/SET_FIELD_LIST',
  SET_FIELD_LIST_ERROR: 'field/SET_FIELD_LIST_ERROR',
  LOADING_FIELD_LIST: 'field/LOADING_FIELD_LIST',
  SET_SELECTED_FIELD: 'field/SET_SELECTED_FIELD',
  CREATE_FIELD: 'field/CREATE_FIELD',
  UPDATE_FIELD: 'field/UPDATE_FIELD',
  DELETE_FIELD: 'field/DELETE_FIELD',
};
// Properties that may be updated when using updateSelectedFieldProperty
export const FieldProperties = {
  Data: 'data',
  Name: 'displayName'
};

export const setFieldList = (accountId, useLoadingAction) => {
  return async dispatch => {

    if (useLoadingAction) {
      dispatch({
        type: actions.LOADING_FIELD_LIST
      });
    }

    try {
      const result = await FieldService.getFieldList(accountId);

      dispatch({
        fieldList: result.data,
        type: actions.SET_FIELD_LIST
      });
    } catch (error) {
      console.error(error.response);
      showDefaultErrorToast(ErrorMessages.FailedGetFields);
      dispatch({
        error: error.response.data,
        type: actions.SET_FIELD_LIST_ERROR,
      });
      dispatch(logError(error, JSON.stringify(error.response)));
    }
  }
}

export const setSelectedField = field => {
  return dispatch => {
    dispatch({
      type: actions.SET_SELECTED_FIELD,
      field: field
    });
  }
}

// Adds createdField to fieldList, sets selectedField to createdField.
export const createField = createdField => {
  return (dispatch, getState) => {
    let currentFieldList = getState().field.fieldList;

    const newFieldList = [...currentFieldList, createdField];

    dispatch({
      fieldList: newFieldList,
      createdField,
      type: actions.CREATE_FIELD
    });
  }
}

export const updateSelectedFieldProperty = (key, value) => {
  return (dispatch, getState) => {
    const currentFieldState = getState().field;
    const currentFieldList = currentFieldState.fieldList;
    const currentSelectedField = currentFieldState.selectedField;
    const selectedFieldIndex = currentFieldList.findIndex(field => field.id === currentSelectedField.id);

    let updatedSelectedField = currentSelectedField;

    // Update the value for the specified key (e.g. will be either displayName or data)
    updatedSelectedField[key] = value;

    let updatedFieldList = currentFieldList;

    updatedFieldList[selectedFieldIndex] = updatedSelectedField;

    dispatch({
      updatedFieldList: updatedFieldList,
      updatedSelectedField,
      type: actions.UPDATE_FIELD
    });
  }
}

export const deleteField = deletedFieldID => {
  return (dispatch, getState) => {
    const currentFieldList = getState().field.fieldList;
    const newFieldList = currentFieldList.filter(field => field.id !== deletedFieldID);

    dispatch({
      fieldList: newFieldList,
      type: actions.DELETE_FIELD
    });
  }
}

const initialState = {
  fieldList: undefined,
  selectedField: undefined,
  error: ''
};

export function reducer(state = initialState, action) {
  switch (action.type) {
  case actions.LOADING_FIELD_LIST:
    return {
      ...state,
      fieldList: undefined
    };
  case actions.SET_FIELD_LIST:
    return {
      ...state,
      fieldList: action.fieldList
    };
  case actions.SET_FIELD_LIST_ERROR:
    return {
      ...state,
      error: action.error,
      fieldList: state.fieldList || []
    }
  case actions.SET_SELECTED_FIELD:
    return {
      ...state,
      selectedField: action.field
    };
  case actions.CREATE_FIELD:
    return {
      ...state,
      selectedField: action.createdField,
      fieldList: action.fieldList
    };
  case actions.UPDATE_FIELD:
    return {
      ...state,
      selectedField: action.updatedSelectedField,
      fieldList: action.updatedFieldList
    };
  case actions.DELETE_FIELD:
    return {
      ...state,
      selectedField: undefined,
      fieldList: action.fieldList
    };
  default:
    return state;
  }
}