import React from 'react';
import '../../styles/sidebar-view.scss';
import { Route, Switch } from 'react-router';
import Loader from '../Loader';
import { setFieldList, createField, updateSelectedFieldProperty, FieldProperties } from '../../dux/field-dux';
import FieldService from '../../services/v1/FieldService';
import {
  showDefaultErrorToast, dismissMapHintToasts, dismissToast,
  dismissAllToasts
} from '../../helpers/toast-helper';
import { ErrorMessages } from '../../constants/errorMessages';
import { validateCreatedField, validateModifiedField, isMapDirty } from '../../helpers/map-helper';
import { openModal, closeModal, updateModalBody } from '../../dux/modal-dux';
import { Routes } from '../../constants/routes';
import { openDialog } from '../../dux/dialog-dux';
import { DialogPrompts } from '../../constants/dialogPrompts';
// Sidebars
import FieldsListSidebar from './FieldsListSidebar';
import FieldSettingsSidebar from './FieldSettingsSidebar';
import DrawToolsSidebar from './DrawToolsSidebar';

class SidebarView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldName: ''
    };
  }

  componentDidMount() {
    this.props.dispatch(setFieldList(this.props.accountManagement.activeAccount.account_id, true));
    this.setState({ fieldName: '' });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accountManagement.activeAccount.account_id !== this.props.accountManagement.activeAccount.account_id) {
      this.props.dispatch(setFieldList(this.props.accountManagement.activeAccount.account_id, true));
      this.setState({ fieldName: '' });
    }
  }

  shouldComponentUpdate(nextProps) {
    // Route changed (different sidebar)
    if (this.props.match.path !== nextProps.match.path) return true;
    // Field list is undefined - need to wait for API call to finish
    if (!this.props.field.fieldList || !nextProps.field.fieldList) return true;
    // Field(s) were added or removed
    if (this.props.field.fieldList.length !== nextProps.field.fieldList.length) return true;
    // Account has changed
    if (this.props.accountManagement.activeAccount.account_id !== nextProps.accountManagement.activeAccount.account_id) return true;
    return false;
  }

  saveAndExit = () => {
    dismissMapHintToasts();
    if (!this.props.mapRef.state.draw) return;

    if (this.props.field.selectedField) {
      this.updateField();
    } else {
      this.validateField();
    }
  }

  validateField = () => {
    const drawData = this.props.mapRef.state.draw.getAll();

    if (!validateCreatedField(drawData)) return;

    // Need them to set field name
    this.showFieldNameModal();
  }

  showFieldNameModal = () => {
    const fieldNameInput = <textarea
      key='field-name'
      className='create-field-name'
      placeholder='Field Name'
      maxLength='100'
      value={this.state.fieldName}
      onChange={this.handleFieldNameChange}
    />;
    const createFieldModalOptions = {
      showIcon: true,
      title: 'Create Field',
      body: [fieldNameInput],
      leftButton: 'Create',
      onLeftButtonClick: this.handleCreateField,
      onExit: this.exitModal
    };

    this.props.dispatch(openModal(createFieldModalOptions));
  }

  handleCreateField = () => {
    if (this.state.fieldName.length === 0) {
      showDefaultErrorToast(ErrorMessages.NoFieldName);
      return;
    }

    this.createField();
  }

  handleFieldNameChange = event => {
    this.setState({
      fieldName: event.target.value
    });
    const fieldNameInput = <textarea
      key='field-name'
      className='create-field-name'
      placeholder='Field Name'
      maxLength='100'
      value={event.target.value}
      onChange={this.handleFieldNameChange}
    />;

    this.props.dispatch(updateModalBody([fieldNameInput]));
  }

  exitModal = () => {
    this.props.dispatch(closeModal());
    dismissToast(ErrorMessages.NoFieldName);
    this.setState({ fieldName: '' });
  }

  createField = async () => {
    const drawData = this.props.mapRef.state.draw.getAll();
    const createFieldModel = {
      accountID: this.props.accountManagement.activeAccount.account_id,
      displayName: this.state.fieldName,
      fieldData: drawData
    };

    try {
      const drawId = drawData.features[0].id;
      const newField = await FieldService.createField(createFieldModel);

      this.exitModal();
      this.props.dispatch(createField(newField));
      this.props.mapRef.addDrawnFieldToFieldsSource(drawId, newField.id);
      this.props.history.push(Routes.FieldSettings(newField.id));
    } catch (e) {
      showDefaultErrorToast(ErrorMessages.CreateField);
    }
  }

  updateField = async () => {
    const drawData = this.props.mapRef.state.draw.getAll();

    if (!validateModifiedField(drawData, this.props.mapRef.state.fieldsSource)) return;

    const fieldId = drawData.features[0].id;
    const updateFieldModel = {
      fieldId: fieldId,
      fieldData: drawData
    };

    try {
      await FieldService.updateFieldData(updateFieldModel);
      this.props.dispatch(updateSelectedFieldProperty(FieldProperties.Data, drawData));
      this.props.mapRef.updateFieldInSource(drawData.features[0]);
      this.props.history.push(Routes.FieldSettings(fieldId));
    } catch (e) {
      showDefaultErrorToast(ErrorMessages.UpdateField);
    }
  }

  enableDrawMode = () => {
    this.props.mapRef.enableDrawMode();
  }

  enterDeleteMode = () => {
    this.props.mapRef.enterDeleteMode();
  }

  tryToLeaveWhenDrawing = () => {
    const drawData = this.props.mapRef.state.draw.getAll();
    const isModifyingField = this.props.field.selectedField;

    // Need to see if they've changed anything so we can warn them changes will be lost when they leave.
    if (!isMapDirty(drawData, this.props.field.selectedField, this.props.mapRef)) {
      // They haven't drawn anything, so just let them go back without confirmation. Map source doesn't change.
      if (isModifyingField) {
        // Make sure to show the field again since we hid it.
        this.props.mapRef.showFieldSource();
      }
      this.props.history.goBack();
    } else {
      // Dismissing toasts here because they can get in the way of the dialog.
      dismissAllToasts();

      // Make them confirm they want to leave and lose changes.
      this.props.dispatch(openDialog({
        content: DialogPrompts.LeaveDrawing,
        yesButton: () => {
          if (isModifyingField) {
            // Reset the selected field in source and remove all invalid inner boundaries they may have added
            this.props.mapRef.resetFieldInSource(this.props.field.selectedField.id, this.props.field.selectedField.data.features[0]);
          }

          this.props.history.goBack();
        }
      }));
    }
  }

  refreshMap = () => {
    // We added fields, so map needs to fetch new fieldList from API and redraw.
    this.props.mapRef.refreshMapSource();
  }

  removeFieldFromSource = id => {
    this.props.mapRef.removeFieldFromMapSource(id);
  }

  render() {
    if (!this.props.field.fieldList) {
      return <Loader />;
    }

    return (
      <div className='sidebar-view'>
        <Switch>
          <Route
            path={Routes.Home}
            render={props => <FieldsListSidebar {...props}
              refreshMap={this.refreshMap} />}
            exact
          />
          <Route
            path={Routes.FieldSettings()}
            render={props => <FieldSettingsSidebar {...props}
              removeFieldFromSource={this.removeFieldFromSource} />}
            exact
          />
          <Route
            path={Routes.FieldCreateModify}
            render={props => <DrawToolsSidebar {...props}
              onSave={this.saveAndExit}
              onBack={this.tryToLeaveWhenDrawing}
              enableDrawMode={this.enableDrawMode}
              onDelete={this.enterDeleteMode} />}
            exact
          />
        </Switch>
      </div>
    );
  }
}

export default SidebarView;