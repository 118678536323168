import * as Cookies from 'js-cookie';
import { LocalStorageKeys } from '../constants/localStorageKeys';
import { setLocalItemAsObject, getTokenOrDefault, getLocalItem } from './local-storage';


export const setupAccountInfo = url => {
  let tokens = null;

  if (url.searchParams.get('refreshToken') != null) {
    tokens = {
      accessToken: url.searchParams.get('accessToken'),
      idToken: url.searchParams.get('idToken'),
      refreshToken: url.searchParams.get('refreshToken')
    };
  }
  else if (Cookies.get('jwt') != null) {
    tokens = {
      jwt: Cookies.get('jwt'),
      scid: Cookies.get('scid'),
    };
  }

  if (tokens != null) {
    const accountInfo = {
      ...tokens
    };

    setLocalItemAsObject(LocalStorageKeys.AccountInfo, accountInfo);
  }
}

export const getTokens = () => {
  // jwt comes from the browser cookie, but is set into local storage in the 'setupAccountInfo' function
  const jwt_token = {
    jwt: getTokenOrDefault('jwt') ? getTokenOrDefault('jwt') : '',
    scid: getTokenOrDefault('scid') ? getTokenOrDefault('scid') : ''
  };

  console.log('jwt_token:', jwt_token);
  if (jwt_token.jwt.length > 0) {
    return jwt_token;
  }

  const tokens = {
    idToken: getTokenOrDefault('idToken'),
    accessToken: getTokenOrDefault('accessToken'),
    refreshToken: getTokenOrDefault('refreshToken'),
  };

  console.log('smart ag tokens:', tokens);
  return tokens
}


export const removeTokensFromStorage = () => {
  const local_info = getLocalItem(LocalStorageKeys.AccountInfo)

  let accountInfo = {
    ...local_info,
    accessToken: '',
    idToken: '',
    refreshToken: '',
  };

  if (local_info != undefined && local_info.jwt != null) {
    accountInfo = {
      ...local_info,
      jwt: '',
      scid: '',
    };
  }

  setLocalItemAsObject(LocalStorageKeys.AccountInfo, accountInfo);
}


export const removeTokensFromCookies = () => {
  Cookies.remove('scid');
  Cookies.remove('jwt');
}