export default class conversionUtil {
    static MPH_MS = 2.237;
    
    // converts a value from MPH to MS
    static mphToMs(mph, decimalPoint) {
        var ms = mph / conversionUtil.MPH_MS;
        if (decimalPoint) {
            ms = conversionUtil.roundToDecimalPoint(ms, decimalPoint);
        }
        return ms
    }
    // converts a value from MS to MPH
    static msToMph(ms, decimalPoint) {
        var mph = ms * conversionUtil.MPH_MS
        if (decimalPoint) {
            mph = conversionUtil.roundToDecimalPoint(mph, decimalPoint);
        }
        return mph;
    }

    static roundToDecimalPoint(value, decimalPoint) {
        var temp = 1;
        for (var i=0; i<decimalPoint; i++) {
            temp = temp*10
        }
        value = Math.round(value * temp) / temp;
        return value;
    }

    static radiansToDegrees(radians) {
        const degrees = radians * (180 / Math.PI);
        return degrees;
    }
}