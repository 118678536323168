import { modes } from '@mapbox/mapbox-gl-draw';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import Constants from '@mapbox/mapbox-gl-draw/src/constants';

const SimpleSelectMode = modes.simple_select;

SimpleSelectMode.onSetup = function (opts) {
  const state = {
    dragMoveLocation: null,
    boxSelectStartLocation: null,
    boxSelectElement: undefined,
    boxSelecting: false,
    canBoxSelect: false,
    dragMoving: false,
    canDragMove: false,
    initiallySelectedFeatureIds: opts.featureIds || []
  };

  if (state.initiallySelectedFeatureIds.length) {
    const id = state.initiallySelectedFeatureIds[0];

    // If the feature exists, select it.
    if (this.getFeature(id)) {
      this.setSelected([id]);
    } else {
      // Feature doesn't exist.
      // Could be that they deleted it.
      // Could also be the case where they added an inner boundary and that polygon gets deleted and is merged in with selected field.
      // Need to select the field feature instead, IF it exists.
      if (this.getFeature(this.selectedFieldFeatureId)) {
        this.setSelected([this.selectedFieldFeatureId]);
      }
    }
  }

  this.fireActionable();
  this.setActionableState({
    combineFeatures: true,
    uncombineFeatures: true,
    trash: true
  });

  return state;
};

// Do not let them drag around the feature. NOOP
SimpleSelectMode.dragMove = () => { };

// Edit: Do not disable dragPan here so that user can move drag around within the field.
// Edit: Remove call to doRender to reduce flicker.
SimpleSelectMode.startOnActiveFeature = function (state, e) {
  // Stop any already-underway extended interactions
  this.stopExtendedInteractions(state);

  // Set up the state for drag moving
  state.canDragMove = true;
  state.dragMoveLocation = e.lngLat;
};

// Edit: Make sure it doesn't deselect the selected feature. Ensures field is always selected.
SimpleSelectMode.clickAnywhere = function (state) {
  doubleClickZoom.enable(this);
  this.stopExtendedInteractions(state);
};

// Edit: Do not call doRender for each of the currently selected features.
// Edit: Take out shift + click functionality since we do not need it.
SimpleSelectMode.clickOnFeature = function (state, e) {
  // Stop everything
  doubleClickZoom.disable(this);
  this.stopExtendedInteractions(state);

  const featureId = e.featureTarget.properties.id;
  const isFeatureSelected = this.isSelected(featureId);

  // Click on any selected feature but a point
  if (isFeatureSelected && this.getFeature(featureId).type !== Constants.geojsonTypes.POINT) {
    // Enter direct select mode
    return this.changeMode(Constants.modes.DIRECT_SELECT, {
      featureId: featureId
    });
  }

  if (!isFeatureSelected) {
    // Make it the only selected feature
    this.setSelected(featureId);
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  }

  // No matter what, re-render the clicked feature
  this.doRender(featureId);
};

export default SimpleSelectMode;