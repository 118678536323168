
import mqttWorkerMsg from './mqttWorkerMsg';
import mqttWorkerFactory from './mqttWorkerFactory';

export default class commWorkerMsgHelper {
    static processWorkerCommand(worker_self, client, data) {
        if (data.event_type === mqttWorkerMsg.MQTT_SUB_MSG) {
            var topic = data.data.topic;
            client.subscribe(topic, data);
        }
        else if (data.event_type === mqttWorkerMsg.MQTT_PUB_MSG) {
            var topic = data.data.topic;
            var payload = data.data.payload;
            var options = data.data.options;

            client.publish(topic, payload, options);
        }
        else if (data.event_type === mqttWorkerMsg.MQTT_UNSUB_MSG) {
            var topic = data.data.topic;
            client.__unsubscribe(topic, null);
        }
        else if (data.event_type === mqttWorkerMsg.END_MSG) {
            client.end();
        }
        else if (data.event_type === mqttWorkerMsg.UPDATE_IOT_TOKEN) {
            var iotToken = data.data;
            client.updateIotToken(iotToken);
        }
        else {
            console.error('[Worker] recieved message without a purpose.');
        }
    }
}