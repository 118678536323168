// utilities
import CommManagerReciever from '../CommManagerReciever';
import actionTypes from './actionTypes';
import machineConfigConstants from '../machineConfigConstants';
import WorkerTypes from '../../SmartAgUI-Common/WorkerTypes';

// actions
import * as tractorInfoActions from './tractorInfoActions';
import * as combineInfoActions from './combineInfoActions';

function onConnect(dispatch, broker_name) {
  console.log('mqtt broker ', broker_name, ' has connected.');
  dispatch({
    type: actionTypes.MQTT_CONNECTED,
    payload: {
      broker_name
    }
  });
}

function onDisconnect(dispatch, broker_name) {
  console.log('mqtt broker ', broker_name, ' has disconnected.');
  dispatch({
    type: actionTypes.MQTT_DISCONNECTED,
    payload: {
      broker_name
    }
  });
}

export function commInit(connectionName, mqtts, local_broker_ip, heartbeatTimeoutAction=null, firstIotToken, getNewIotTokenAction=null) {
  return (dispatch, getState) => {

    var { accountManagement } = getState();
    var heartbeatTimeoutCb = null;
    if (heartbeatTimeoutAction) {
        heartbeatTimeoutCb = () => {
            dispatch(heartbeatTimeoutAction);
        }
    }


    var getNewIotTokenCb = null;
    if (getNewIotTokenAction) {
        const curretIotToken = () => getState().iotToken;
        getNewIotTokenCb = () => {
            // dispatch action to fetch new tokens from the cloud
            dispatch(getNewIotTokenAction());

            var iotToken = curretIotToken();
            return iotToken;
        }
    }

    var client = new CommManagerReciever(
      connectionName, 
      mqtts,
      local_broker_ip,
      accountManagement.id,
      firstIotToken,
      // on_connect
      (broker_name) => {
        onConnect(dispatch, broker_name);
      }
      ,
      // on_disconnect
      (broker_name) => {
        onDisconnect(dispatch, broker_name);
      }
      ,
      (broker_name, service_name, isConnected) => {
        if (service_name == machineConfigConstants.COMBINE_NAME) {
          combineInfoActions.updateCombineBrokerStatus(dispatch, broker_name, isConnected);
        } else if (service_name === machineConfigConstants.TRACTOR_NAME) {
          tractorInfoActions.updateTractorBrokerStatus(dispatch, broker_name, isConnected);
        } else {
          //TODO: make this dynamic once multi vehicle is full implemented
        }
      }
      ,
      heartbeatTimeoutCb,
      getNewIotTokenCb
    );

    // initialize connection
    dispatch({
  		type: actionTypes.MQTT_INIT,
      payload: {
        client
      }
  	});
  }
}

export function subscribe(topic, callback, serviceName, worker_type, max_rate_hertz=null, priority_broker=true) {
  return (dispatch, getState) => {
    var comm = getState().comm.client;
    comm.subscribe(topic, callback, serviceName, worker_type, max_rate_hertz, priority_broker);
  }
}


export function unsubscribe(topic) {
  return (dispatch, getState) => {
    var comm = getState().comm.client;
    comm.unsubscribeFromTopic(topic);
  }
}


export function destroyWorkersAction() {
  return (dispatch, getState) => {
    var comm = getState().comm.client;
    comm.end();
    console.log("Destroyed the web workers for comms");
  }
}


export function restartWorkersAction() {
  return (dispatch, getState) => {
    var comm = getState().comm.client;
    comm.setupWorkers();
    comm.reInitSubs();
    console.log("Restarted the web workers for comms");
  }
}

export function publish(topic, payload, options={ qos: 0 }) {
  return (dispatch, getState) => {
    var comm = getState().comm.client;
    comm.publish(topic, JSON.stringify(payload), WorkerTypes.COMMANDS_WORKER, options);
  }
}