import React from 'react';
import '../../styles/setup-operation-view.scss';
import { ReactComponent as BackButton } from '../../assets/BackButton.svg';
import { setMachineList } from '../../dux/machine-dux';
import { openDialog } from '../../dux/dialog-dux';
import sha1 from 'sha1';
import FormInput from '../FormInput';
import { MachineType } from '../../constants/machineType';
import { showDefaultErrorToastNonClosing, dismissAllToasts } from '../../helpers/toast-helper';
import { ErrorMessages } from '../../constants/errorMessages';
import MachineControlService from '../../services/v1/MachineControlService';
import { startOperation } from '../../dux/operation-dux';
import { Routes } from '../../constants/routes';

class SetupOperationView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tractor: null,
      combine: null,
      availableTractors: [],
      availableCombines: [],
      isLoading: false,
      machineControlConnected: false
    };
    this.checkMCReadyInterval = null;
  }

  componentDidMount() {
    this.populateMachineDropdowns();
    this.isMachineControlReady();
  }

  componentWillUnmount() {
    dismissAllToasts();
    clearInterval(this.checkMCReadyInterval);
  }

  generateConnectionID() {
    const date = new Date();

    return sha1(date);
  }

  showConfirmLiabilityNotice = () => {
    this.props.dispatch(openDialog({
      content: `Improper use of this equipment or faulty 
      path planning may cause property damage, personal injury or death. 
      It is your responsibility to verify that there are no obstacles in 
      the path of the equipment prior to movement and that the planned route is safe. 
      By selecting Accept, you are accepting all responsibility 
      for the operation of this equipment and the path that was planned.`,
      yesButtonTxt: 'Accept',
      noButtonTxt: 'Cancel',
      headerTxt: 'WARNING',
      yesButton: async () => {
        this.startOperation();
      }
    }));
  }

  startOperation = () => {
    const operationData = {
      connectionID: this.generateConnectionID(),
      combine: this.state.combine,
      tractor: this.state.tractor
    };

    this.props.dispatch(startOperation(operationData));
    this.props.history.push(Routes.Operation);
  }

  isMachineControlReady = async () => {
    try {
      const responseStatusOk = await MachineControlService.checkConnection();

      if (responseStatusOk) {
        this.setState({
          machineControlConnected: true
        });

        if (this.checkMCReadyInterval) {
          clearInterval(this.checkMCReadyInterval);
          this.checkMCReadyInterval = null;
          dismissAllToasts();
        }

        return true;
      }

      showDefaultErrorToastNonClosing(ErrorMessages.MachineControlConnection);
    } catch (error) {
      showDefaultErrorToastNonClosing(ErrorMessages.MachineControlOffline);
    }

    // If we get here, set an interval to keep trying every 2 seconds.
    if (!this.checkMCReadyInterval) {
      this.checkMCReadyInterval = setInterval(this.isMachineControlReady, 2000);
    }

    return false;
  }

  populateMachineDropdowns = async () => {
    if (!this.props.machine.machineList) {
      await this.props.dispatch(setMachineList(this.props.accountManagement.activeAccount.account_id));
    }

    const availableTractors = this.props.machine.machineList.filter(machine => machine.machine_type === MachineType.Tractor);
    const availableCombines = this.props.machine.machineList.filter(machine => machine.machine_type === MachineType.Combine);

    this.setState({
      availableTractors,
      availableCombines,
      tractor: availableTractors[0],
      combine: availableCombines[0]
    });
  }

  handleInputChange = event => {
    const value = parseInt(event.target.value);

    let machine;

    if (event.target.name === 'tractor') {
      machine = this.state.availableTractors.find(tractor => tractor.dbId === value);
    } else {
      machine = this.state.availableCombines.find(combine => combine.dbId === value);
    }

    this.setState({
      [event.target.name]: machine
    });
  }

  renderTractorDropdown = () => {
    if (!this.state.tractor) {
      return (
        <FormInput
          disabled={true}
          label='Tractor'
          value='No tractors found.'
        />
      );
    }

    const options = this.state.availableTractors.map(tractor => {
      return { name: tractor.displayName, value: tractor.dbId };
    });

    return (
      <FormInput
        onChange={this.handleInputChange}
        label='Tractor'
        type='select'
        name='tractor'
        selectOptions={options}
        value={this.state.tractor.dbId}
        dropdownType={MachineType.Tractor}
      />
    );
  }

  renderCombineDropdown = () => {
    if (!this.state.combine) {
      return (
        <FormInput
          disabled={true}
          label='Combine'
          value='No combines found.'
        />
      );
    }

    const options = this.state.availableCombines.map(combine => {
      return { name: combine.displayName, value: combine.dbId };
    });

    return (
      <FormInput
        onChange={this.handleInputChange}
        label='Combine'
        type='select'
        name='combine'
        selectOptions={options}
        value={this.state.combine.dbId}
        dropdownType={MachineType.Combine}
      />
    );
  }

  render() {
    // Disable start operation button if we are not connected to MC OR
    // no combine is selected OR no tractor is selected
    const disableStartOperation = !this.state.machineControlConnected || !this.state.combine || !this.state.tractor;

    return (
      <div className='setup-operation-view'>
        <BackButton className='back' onClick={() => this.props.history.goBack()} />
        <h2>{'Setup Operation'}</h2>

        <div className='form'>
          {this.renderCombineDropdown()}
          {this.renderTractorDropdown()}
        </div>

        <button
          className='start'
          disabled={disableStartOperation}
          onClick={this.showConfirmLiabilityNotice}
        >{'Start Operation'}</button>

      </div>
    );
  }
}

export default SetupOperationView;