import axios from 'axios';

export default {
  createLog: async (account_id, message) => {
    const response = await axios.post('/v1/log', {
      account_id,
      message
    });

    return response.data;
  },
}