
import actionTypes from '../actions/actionTypes';
import trackedObj from '../models/trackedObj';

export function updateTrackedObjectsState(dispatch, message) {
    const mqttPayload = message.tracks;

    var new_models = []
    for (var i = 0; i < mqttPayload.length; i++) {
        var model = new trackedObj(mqttPayload[i]);
        new_models.push(model);
    }

    const action = {
        type: actionTypes.UPDATE_TRACKED_OBJECTS_STATE,
        payload: {
            models: new_models
        }
    };

    dispatch(action);
}
