// modules
import olCollection from 'ol/collection';
import olGeomPolygon from 'ol/geom/polygon';
import olInteractionDraw from 'ol/interaction/draw';
import olInteractionSnap from 'ol/interaction/snap';
import olInteractionModify from 'ol/interaction/modify';
import olInteractionSelect from 'ol/interaction/select';

// utilities
import boundaryValidationHelper from './boundaryValidationHelper';
import * as mapLayers from './mapLayers';
import * as styles from './styles';
import { olInteractionTypes } from '../config';

/*snap interaction*/
var snap = new olInteractionSnap({
  source: mapLayers.vector.getSource()
});
snap.set("name", "snap");
snap.setActive(true);

/*placePin Interaction*/
var placePin = new olInteractionDraw({
    source: mapLayers.fieldPins.getSource(),
    type: ('Point'),
    clickTolerance: 9999
});
placePin.set("name", "placePin");
placePin.setActive(false);


/*drawPoly Interaction*/
var drawPoly = new olInteractionDraw({
  source: mapLayers.vector.getSource(),
  type: ('Polygon'),
  geometryFunction: drawPolygonUpdate
});
drawPoly.set("name","drawPoly");
drawPoly.setActive(false);

function drawPolygonUpdate(coordinates, geometry) {
    // if no geometry exists, nothing had been drawn previously
    if (!geometry) {
      // finish the polygon with by adding the first coordinate to the end
      coordinates = coordinates[0].concat([coordinates[0][0]]);
      return new olGeomPolygon(coordinates);
    } else {
      // update the geometry
      var geometryCoordinates = [coordinates[0].concat([coordinates[0][0]])];
      geometry.setCoordinates(geometryCoordinates);

      if (coordinates[0].length > 4) {
        // remove the last coordinate because that is where the cursor
        // rather than an actual vertice in the polygon
        var coordinatesToCheck = [coordinates[0].concat([])];
        coordinatesToCheck[0].splice(coordinates[0].length - 1, 1);
        // validate coordinates
        if (boundaryValidationHelper.doCoordinatesCross(coordinatesToCheck[0])) {
            drawPoly.removeLastPoint();
        }
      }
      return geometry;
    }
}

/* drawInnerBoundary Interaction*/
var drawInnerBoundary = new olInteractionDraw({
  source: mapLayers.innerBoundary.getSource(),
  type: ('Polygon')
});
drawInnerBoundary.set("name", "drawInnerBoundary");
drawInnerBoundary.setActive(false);

/* modify inner boundary interaction*/
var modifyInnerBoundary = new olInteractionModify({
  source: mapLayers.innerBoundary.getSource(),
  style: styles.fieldStyleNew,
  pixelTolerance: 20
});
modifyInnerBoundary.set("name", olInteractionTypes.MODIFY_INNER_BOUNDARY);

/*Select inner boundary Interaction*/
var selectedInnerBoundary = new olCollection();
var selectInnerBoundary = new olInteractionSelect({
  features: selectedInnerBoundary,
  layers: [mapLayers.selectInnerBoundary]
});
selectInnerBoundary.set("name", olInteractionTypes.SELECT_INNER_BOUNDARY);
selectInnerBoundary.setActive(false);
// remove all the selections from the interaction
selectInnerBoundary.on('clearSelections', function(event) {
  selectedInnerBoundary.clear();
});

/* drawRoute Interaction*/
var drawRoute = new olInteractionDraw({
  source: mapLayers.invisible.getSource(),
  type: ('LineString'),
});

drawRoute.set("name","drawRoute");
drawRoute.setActive(false);

/* SELECT VERTICES */
var selectVertices = new olInteractionDraw({
  source: mapLayers.selectVertices.getSource(),
  style: styles.selectVerticesStyle,
  type: ('Polygon'),
  freehand: true
});
selectVertices.set("name", olInteractionTypes.SELECT_VERTICES);
selectVertices.setActive(false);

/* Select Interaction */
export var selectedField = new olCollection();
var selectField = new olInteractionSelect({
  style: styles.fieldStyleSelected,
  features: selectedField,
  layers: [mapLayers.vector]
});
selectField.set("name", "select");

selectField.setActive(true);
selectField.on('selectedField', function(event) {
  selectedField.clear();
  selectedField.push(event.payload.feature);
  console.log(selectedField[0]);
});

/*select Interaction*/
var selectFeatures = new olCollection();
var select = new olInteractionSelect({
  features: selectFeatures,
  layers: [mapLayers.fieldPins]
});
select.set("name", "select");

select.setActive(true);
//makes it so when something is no longer selected, the map understands that
var selectedFeatures = select.getFeatures();
select.on('change:active', function() {
  selectedFeatures.forEach(selectedFeatures.remove, selectedFeatures);
});

// select.on('select', function(evt) {
//   selectFeatures.clear();
//   console.log('select new feature');
//   // console.log(evt.selected[0]);
//   // console.log(evt.selected[0].getProperties().group);
//   // we want pin selections
//   if (evt.selected[0] !== undefined && evt.selected[0].getProperties().group !== 'routes' &&
//     evt.selected[0].getProperties().group !== olLayerTypes.COVERAGE_MAP &&
//     evt.selected[0].getProperties().group !== olLayerTypes.FIELD) {
//     selectFeatures.push(evt.selected[0]);
//   }
// });

// remove all selected features
select.on('clearSelections', function() {
  console.log('clear select features');
  selectFeatures.clear();
});


// add selected feature
select.on('addFeature', function(data) {
  selectFeatures.clear();
  selectFeatures.push(data.feature);
});


/*modify Interaction*/
var modify = new olInteractionModify({
  features: selectField.getFeatures()
});
modify.set("name", "modify");
modify.setActive(false);

modify.on('change:active', function() {

});

function modifyInteractionWithSource(source) {
  var modifyInteraction = new olInteractionModify({
    source: source
  });
  modifyInteraction.set("name", olInteractionTypes.MODIFY);
  modifyInteraction.setActive(false);
  return modifyInteraction;
}

// module.exports.modifyInteractionWithSource = modifyInteractionWithSource;
// module.exports.select = select;
// module.exports.selectVertices = selectVertices;
// module.exports.selectField = selectField;
// module.exports.selectedField = selectedField;
// module.exports.modifyInnerBoundary = modifyInnerBoundary;
// module.exports.snap = snap;
// module.exports.placePin = placePin;
// module.exports.drawPoly = drawPoly;
// module.exports.drawInnerBoundary = drawInnerBoundary;
// module.exports.modify = modify;
// module.exports.drawRoute = drawRoute;
// module.exports.selectInnerBoundary = selectInnerBoundary;