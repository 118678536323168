// modules
import olLayerTile from 'ol/layer/tile';
import olLayerVector from 'ol/layer/vector';
import olSourceXyz from 'ol/source/xyz';
import olSourceVector from 'ol/source/vector';
// utilities
import { olLayerTypes } from '../config';
import {
  coverageMap as coverageMapStyle,
  fieldStyleDefault,
  fieldStyleModify,
  innerBoundaryDefaultStyle,
  innerBoundaryDeleteStyle,
  selectVerticesStyle
} from './styles';

export var invisible = new olLayerVector({
  source: new olSourceVector(),
});
invisible.set("name", olLayerTypes.INVISIBLE);
invisible.setVisible(false);

export var mapTiles = new olLayerTile({
  source: new olSourceXyz({
    url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic21hcnRhZyIsImEiOiJjamY5dHdzeGEybjFiMndvMWxiMmprcW1hIn0.L6N5UAru5sI4Pwaf_NhDKA',
    tileSize: 512
  })
})

export var innerBoundary = new olLayerVector({
  source: new olSourceVector(),
  style: fieldStyleModify
});
innerBoundary.set("name", olLayerTypes.INNER_BOUNDARY);

// for deleting inner boundaries
export var selectInnerBoundary = new olLayerVector({
  source: new olSourceVector(),
  style: innerBoundaryDeleteStyle
});
selectInnerBoundary.set("name", olLayerTypes.SELECT_INNER_BOUNDARY);

// draw around vertices
export var selectVertices = new olLayerVector({
  source: new olSourceVector(),
  style: selectVerticesStyle
});
selectVertices.set("name", olLayerTypes.SELECT_VERTICES);


export var vector = new olLayerVector({
  source: new olSourceVector(),
  style: fieldStyleDefault
});
vector.set("name", olLayerTypes.FIELD);

export var coverageMap = new olLayerVector({
  source: new olSourceVector(),
  style: coverageMapStyle,
});
coverageMap.set("name", olLayerTypes.COVERAGE_MAP);


export var machineTravelPath = new olLayerVector({
  source: new olSourceVector(),
});
machineTravelPath.set("name", olLayerTypes.MACHINE_TRAVEL_PATH);

export var machinery = new olLayerVector({
  source: new olSourceVector(),
});
machinery.set("name", olLayerTypes.MACHINERY);


export var fieldPins = new olLayerVector({
  source: new olSourceVector(),
});
fieldPins.set("name", olLayerTypes.FIELD_PINS)

export var syncIntercept = new olLayerVector({
  source: new olSourceVector(),
});
syncIntercept.set("name", olLayerTypes.SYNC_INTERCEPT)

// mqtt topic -> account/tractor/combinepoly/small
export var combineBubbles = new olLayerVector({
  source: new olSourceVector(),
});
combineBubbles.set("name", olLayerTypes.COMBINE_BUBBLES);