import React from 'react';
import '../../styles/fields-list-sidebar.scss';
import FieldList from '../FieldList';
import { connect } from 'react-redux';
import { setSelectedField, setFieldList } from '../../dux/field-dux';
import { openModal, closeModal } from '../../dux/modal-dux';
import ZipFileParser from '../../utilities/ZipFileParser';
import { dismissAllToasts } from '../../helpers/toast-helper';
import FieldBulkUploader from '../../utilities/FieldBulkUploader';
import { showDefaultErrorToast } from '../../helpers/toast-helper';
import { Routes } from '../../constants/routes';

export class FieldsListSidebar extends React.Component {
  shouldComponentUpdate(nextProps) {
    // if fieldList is undefined, fields are still being loaded.
    if (nextProps.fieldList) return true;

    return false;
  }

  componentDidMount() {
    this.props.dispatch(setSelectedField(undefined));
  }

  handleUploadField = () => {
    this.fileUpload.click();
  }

  handleFilePicker = async ref => {
    const { files } = ref.target;

    if (!files) return;

    try {
      const parsedFieldsGeoJson = await ZipFileParser.parseFieldsFromZipFiles(files);

      await FieldBulkUploader.bulkCreateNewFields(parsedFieldsGeoJson, this.props.accountManagement.activeAccount.account_id);
      this.props.dispatch(closeModal());
      this.props.dispatch(setFieldList(this.props.accountManagement.activeAccount.account_id, false));
      this.props.refreshMap();
    } catch (error) {
      showDefaultErrorToast(error.message);
    }
  }

  handleCreateField = () => {
    this.props.dispatch(closeModal());
    this.props.dispatch(setSelectedField(undefined));
    this.props.history.push(Routes.FieldCreateModify);
  }

  handleAddField = () => {
    const addFieldModalOptions = {
      showIcon: true,
      title: 'Add New Field',
      leftButton: 'Create Field',
      onLeftButtonClick: this.handleCreateField,
      rightButton: 'Upload Field(s)',
      onRightButtonClick: this.handleUploadField,
      onExit: this.exitModal
    };

    this.props.dispatch(openModal(addFieldModalOptions));
  }

  exitModal = () => {
    dismissAllToasts();
    this.props.dispatch(closeModal());
  }

  handleFieldClick = field => {
    this.props.dispatch(setSelectedField(field));
    this.props.history.push(Routes.FieldSettings(field.id));
  }

  render() {
    return (
      <div className='fields-list-sidebar'>
        <h1>{'Fields'}</h1>

        <FieldList fieldList={this.props.fieldList} onFieldClick={this.handleFieldClick} />

        <button onClick={this.handleAddField}>
          {'+ Add Field'}
        </button>

        <input
          ref={ref => { this.fileUpload = ref }}
          className='file-upload'
          type='file'
          accept='.zip'
          onChange={this.handleFilePicker} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    fieldList: state.field.fieldList,
    modal: state.modal,
    accountManagement: state.accountManagement
  }
}

export default connect(mapStateToProps)(FieldsListSidebar);
