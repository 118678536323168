export default class machineConfigConstants {
    static COMBINE_NAME = 'combine';
    static TRACTOR_NAME = 'tractor';
		static SECONDARY_COMBINE_NAME = 'combine-secondary';
		static TRACTOR_TYPE_IVT = 'IVT';
		static TRACTOR_TYPE_PST = 'PST';
		static TRACTOR_GEAR_KEYS = {
			[machineConfigConstants.TRACTOR_TYPE_IVT]: 'return_reqgear',
			[machineConfigConstants.TRACTOR_TYPE_PST]: 'cur_gear'
		}
}