export const mountPoint = 'websocket';
export const actions = {
  WEBSOCKET_CONNECTED: 'websocket/WEBSOCKET_CONNECTED',
  WEBSOCKET_INITIATED: 'websocket/WEBSOCKET_INITIATED',
  WEBSOCKET_DISCONNECTED: 'websocket/WEBSOCKET_DISCONNECTED'
};

export const websocketConnected = client => {
  return dispatch => {
    dispatch({
      type: actions.WEBSOCKET_CONNECTED,
      client: client
    })
  }
}

export const websocketInitiated = () => {
  return dispatch => {
    dispatch({
      type: actions.WEBSOCKET_INITIATED
    })
  }
}

export const websocketDisconnect = () => {
  return (dispatch, getState) => {
    const websocket = getState().websocket;

    websocket.client.close();
    dispatch({
      type: actions.WEBSOCKET_DISCONNECTED
    })
  }
}

const initialState = {
  client: undefined,
  connected: false,
  establishingConnection: false
};

export function reducer(state = initialState, action) {
  switch (action.type) {
  case actions.WEBSOCKET_CONNECTED:
    return {
      ...state,
      client: action.client,
      establishingConnection: false,
      connected: true
    };
  case actions.WEBSOCKET_INITIATED:
    return {
      ...state,
      establishingConnection: true
    };
  case actions.WEBSOCKET_DISCONNECTED:
    return {
      ...state,
      client: undefined,
      connected: false,
      establishingConnection: false
    };
  default:
    return state;
  }
}