export default class combineBubbleStateModel {
    constructor(id) {
        this.id = id;
        this.geoJson = null;
    }

    static PATH_PLANNER(){
        return "PATH_PLANNER";
    }

    setGeoJson(geoJson) {
        this.geoJson = geoJson;
    }
}