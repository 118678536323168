import React from 'react';
import '../styles/form-input.scss';

class FormInput extends React.Component {
  renderSelectOption(option) {
    return (
      <option
        key={option.value}
        value={option.value}>
        {option.name}
      </option>
    );
  }

  renderError = () => {
    if (!this.props.error) return null;
    return (
      <span className='error'>
        {this.props.error}
      </span>
    );
  }

  validateNumeric = event => {
    // Don't allow . when empty
    if (event.target.value === '' && event.keyCode === 190) return event.preventDefault();
    // Ignore  = - , . if the input is supposed to be an integer
    const badCodes = [187, 188, 189, 190];

    if (this.props.isInt) {
      return badCodes.includes(event.keyCode) && event.preventDefault();
    }

    if (this.props.type === 'number') {
      // Do not allow 'e'
      return event.keyCode === 69 && event.preventDefault();
    }
    return;
  }

  renderLabel() {
    if (this.props.label) {
      return <label>{this.props.label}</label>
    } else {
      return null;
    }
  }

  renderInput() {
    const selectDropdownClass = `select-container ${this.props.dropdownType}`;

    if (this.props.type === 'select') {
      return (
        <div className={selectDropdownClass}>
          <select
            name={this.props.name}
            value={this.props.value}
            disabled={this.props.disabled}
            onChange={this.props.onChange}>
            {this.props.selectOptions.map(this.renderSelectOption)}
          </select>
        </div>
      );
    } else {
      return (
        <input
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder}
          type={this.props.type}
          name={this.props.name}
          value={this.props.value}
          onKeyDown={this.validateNumeric}
          step={this.props.step}
        />
      );
    }
  }

  render() {
    return (
      <div className='form-input'>
        <div className='input'>
          {this.renderLabel()}
          {this.renderInput()}
        </div>

        {this.renderError()}
      </div>
    );
  }
}

export default FormInput;