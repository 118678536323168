import axios from 'axios';
export const BASE_URL_v1 = '/v1/field';

export default {
  getFieldList: async accountId => {
    const response = await axios.get(`${BASE_URL_v1}/list/${accountId}`);

    // TODO: Change API to do this?
    if (response.data.length === 0) {
      return {
        data: []
      };
    }

    const { info, fieldIDs } = response.data.FieldsList;
    const fieldData = {
      data: fieldIDs.map(id => info[id])
    };

    return fieldData;
  },
  createField: async fieldData => {
    const response = await axios.post(`${BASE_URL_v1}/new`, fieldData);
    const { field } = response.data;

    return field;
  },
  updateFieldName: async fieldDataModel => {
    const response = await axios.put(`${BASE_URL_v1}/displayName`, fieldDataModel);
    const { fieldData } = response.data;

    return fieldData;
  },
  updateFieldData: async fieldDataModel => {
    const response = await axios.put(`${BASE_URL_v1}/update/fieldData`, fieldDataModel);
    const { success } = response.data;

    return success;
  },
  deleteField: async fieldId => {
    const response = await axios.delete(`${BASE_URL_v1}/${fieldId}`);
    const { success } = response.data;

    return success;
  }
}