
import actionTypes from './actionTypes';
import secondaryCombineModel from '../models/secondaryCombineModel';

export function updateSecondaryCombineStateSlimCore(dispatch, combineName, mqttPayload) {
    const lat = parseFloat(mqttPayload.lat);
    const lon = parseFloat(mqttPayload.lon);

    const heading_deg = parseFloat(mqttPayload.heading);
    const heading = heading_deg * (Math.PI / 180);

    var model = new secondaryCombineModel(combineName, lon, lat, heading);

    const action = {
        type: actionTypes.UPDATE_SECONDARY_COMBINE_STATE,
        payload: { 
            model: model
        }
    };

    dispatch(action);
}
