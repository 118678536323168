export default class coverageMapModel{
    constructor(
        id,
        data,
        geometryType,
        lastUpdated,
        shouldUpdate
    ){
        this.id = id;
        this.data = data;
        this.geometryType = geometryType;
        this.lastUpdated = lastUpdated;
        this.shouldUpdate = shouldUpdate;
    }
}