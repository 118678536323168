export default class commBrokerInactivityTracker {
    constructor(onTimeoutCallback, timeoutThreshold) {
        this.timeoutThreshold = timeoutThreshold * 1000; // convert to seconds
        this.onTimeoutCallback = onTimeoutCallback;
        this.serviceTimestamps = {};

        if (onTimeoutCallback != null) {
            this.interval = this.createServiceTimeoutInterval();
        }
    }

    addBroker(brokerName) {
        this.serviceTimestamps[brokerName] = {};
    }

    updateServiceTimestamp(brokerName, serviceName) {
        this.serviceTimestamps[brokerName][serviceName] = Date.now();
    }

    clearInterval() {
        clearInterval(this.interval);
    }

    createServiceTimeoutInterval() {
        var intervalCallback = () => {
            for (const brokerName in this.serviceTimestamps) {
                for (const serviceName in this.serviceTimestamps[brokerName]) {
                    this.inactivateBrokerIfStale(brokerName, serviceName);
                }
            }  
        };
        
        return setInterval(intervalCallback, this.timeoutThreshold);
    }

    // time comparison
    inactivateBrokerIfStale(brokerName, serviceName) {
        if (this.serviceTimestamps[brokerName][serviceName] != null) {
            if (this.shouldDisableBrokerService(brokerName, serviceName)) {
                this.serviceTimestamps[brokerName][serviceName] = null;
                this.onTimeoutCallback(brokerName, serviceName, false);
            }
        }
    }
    
    shouldDisableBrokerService(brokerName, serviceName) {
        var timestamp = this.serviceTimestamps[brokerName][serviceName];
        var currentTimestamp = Date.now();
        var timestampDifference = currentTimestamp - timestamp;
        return timestampDifference > this.timeoutThreshold;
    }
}