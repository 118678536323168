import { modes } from '@mapbox/mapbox-gl-draw';
import Constants from '@mapbox/mapbox-gl-draw/src/constants';
import { isOfMetaType } from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';

const DirectSelectMode = modes.direct_select;
const isVertex = isOfMetaType(Constants.meta.VERTEX);

// Do not let them drag around the feature. NOOP
DirectSelectMode.dragFeature = () => { };

DirectSelectMode.onDrag = function (state, e) {
  if (state.canDragMove !== true) return;

  state.dragMoving = true;

  // This allows zooming to still work when the entire feature is selected.
  if (state.selectedCoordPaths.length > 0) {
    e.originalEvent.stopPropagation();
  }

  const delta = {
    lng: e.lngLat.lng - state.dragMoveLocation.lng,
    lat: e.lngLat.lat - state.dragMoveLocation.lat
  };

  if (state.selectedCoordPaths.length > 0) this.dragVertex(state, e, delta);
  else this.dragFeature(state, e, delta);

  state.dragMoveLocation = e.lngLat;
};

DirectSelectMode.startDragging = function (state, e) {
  const draggingAPoint = isVertex(e);

  // If they have any points selected OR they are trying to drag a point, disable drag pan
  if (state.selectedCoordPaths.length > 0 || draggingAPoint) {
    this.map.dragPan.disable();
    state.canDragMove = true;
    state.dragMoveLocation = e.lngLat;
  } else {
    // This is the case where they are drying to drag within the field to move the map around.
    // Clear canDragMove so that onDrag is not called - prevents an unnecessary fireUpdate call.
    this.map.dragPan.enable();
    state.canDragMove = false;
  }
};

// Clicking on nothing should force simple select mode (will not get ignored because of silent option)
DirectSelectMode.clickNoTarget = function () {
  this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
};

// Clicking on an inactive feature should force simple select mode (will not get ignored because of silent option)
DirectSelectMode.clickInactive = function () {
  this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
};

export default DirectSelectMode;