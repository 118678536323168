// import mqtt from 'mqtt';
import wildcardDict from './wildcardDict';
import commBrokerInactivityTracker from './commBrokerInactivityTracker';
import commManagerMsgTypes from './commManagerMsgTypes';
import work from 'webworkify-webpack';

// models
import callbackModel from './models/callbackModel';
import mqttWorkerMsg from './mqttWorkerMsg';
import WorkerTypes from './WorkerTypes';

const TIMEOUT_THRESHOLD = 5; // this value is referring to seconds

class CommManagerReciever {

    constructor(
        product_name,
        broker_configs,
        local_broker_ip,
        accountId,
        iotToken,
        on_connect = null,
        on_disconnect = null,
        connectivityChangedCb = null,
        on_heartbeat_timeout = null,
        onGetNewMqttTokensCb = null
    ) {
        this.callbacks = new wildcardDict();
        this.unsubscribeCallbacks = {}
        this.connectCount = 0;
        this.productName = product_name;
        this.connectivityChangedCb = connectivityChangedCb;
        this.commBrokerInactivityTracker = new commBrokerInactivityTracker(this.connectivityChangedCb, TIMEOUT_THRESHOLD);
        this.local_broker_ip = local_broker_ip;
        this.iotToken = iotToken;
        this.accountId = accountId;

        this.broker_names = []
        for (const broker of broker_configs) {
            this.commBrokerInactivityTracker.addBroker(broker.name);
            this.broker_names.push(broker.name);
        }

        this.heartbeat_timeout_cb = on_heartbeat_timeout;
        this.getNewMqttTokensCb = onGetNewMqttTokensCb;
        this.connect_cb = on_connect;
        this.disconnect_cb = on_disconnect;

        this.setupWorkers(this.accountId);
    }

    createAllWorkers(){
        for (var key in WorkerTypes) {
            this[key] = work(require.resolve('../SmartAgUI-Common/commWorker'), {worker_name: key});
        }
    }

    getInitMsg(enableHeartbeat, accountId, broker_names){

        var data = {
            ip: this.local_broker_ip, 
            heartbeatTimeout: enableHeartbeat, 
            accountId: accountId, 
            broker_names: broker_names,
            worker_name: 'n/a',
            iotToken: this.iotToken
        };

        return data;
    }

    setupWorkers() {

        this.end();
        this.createAllWorkers();

        var heartbeatInitData = this.getInitMsg(
            this.heartbeat_timeout_cb != null, 
            this.accountId, 
            this.broker_names);
        var initData = this.getInitMsg(false, this.accountId, this.broker_names);

        var self = this;
        var callback = (m) => {
            if (m.data.type === commManagerMsgTypes.HEARTBEAT_TIMEOUT_MSG) {
                self.heartbeat_timeout_cb();
            } else if (m.data.type === commManagerMsgTypes.MACHINE_MSG) {
                self.handle_message(m.data, true);
            } else if (m.data.type === commManagerMsgTypes.MACHINE_NON_JSON_MSG) {
                self.handle_message(m.data, false);
            } else if (m.data.type === commManagerMsgTypes.COVERAGE_JSON_MSG) {
                self.handle_message(m.data, false);
            } else if (m.data.type === commManagerMsgTypes.GET_NEW_IOT_TOKEN) {
                const tokens = this.getNewMqttTokensCb();
                this.updateIotTokenForAllCommWorkers(tokens);
            }
            else {
                throw new Error(`[CommManagerReceiver] received a message without a purpose`);
            }
        };
        
        for (var key in WorkerTypes){
            if (key === WorkerTypes.STREAMS_WORKER){
                heartbeatInitData.worker_name = key;
                var msg = new mqttWorkerMsg(heartbeatInitData, mqttWorkerMsg.INIT_MSG);
                this[key].postMessage(msg);
            }
            else{
                var msg = new mqttWorkerMsg(initData, mqttWorkerMsg.INIT_MSG);
                initData.worker_name = key;
                this[key].postMessage(msg);
            }

            this[key].onmessage = callback;
        }
    }

    updateIotTokenForAllCommWorkers(mqttTokens) {
        for (var key in WorkerTypes){
            var msg = new mqttWorkerMsg(mqttTokens, mqttWorkerMsg.UPDATE_IOT_TOKEN);
            this[key].postMessage(msg);
        }
    }

    handle_message(message, should_stringfy_msg) {
        var cb_list = this.callbacks.get(message.topic);
        if (cb_list == null) {
            return;
        }

        for (const cb of cb_list) {
            // update timestamp on service
            this.commBrokerInactivityTracker.updateServiceTimestamp(message.broker_name, cb.serviceName);
            // Update the brokers connectivity status
            if (this.connectivityChangedCb != null) {
                this.connectivityChangedCb(message.broker_name, cb.serviceName, true);
            }
        }

        // if (should_stringfy_msg === true) {
        //     message.message = JSON.stringify(message.message)
        // }

        for (const cb of cb_list) {
            
            cb.callback(message.message, message.broker_name, message.topic);
        }
    }

    publish(topic, payload, workerType, options={ qos: 0 }) {
        var msg = new mqttWorkerMsg({ payload, topic, options }, mqttWorkerMsg.MQTT_PUB_MSG);
        this[workerType].postMessage(msg);
    }

    unsubscribe(topic, model, workerType) {
        var msg = new mqttWorkerMsg({ topic }, mqttWorkerMsg.MQTT_UNSUB_MSG);
        this[workerType].postMessage(msg);

        // remove callback
        var index = this.callbacks.get(topic).indexOf(model);
        if (index > -1) {
            this.callbacks.remove(topic, model);
        }
        else {
            console.log(`${this.productName}: Unable to unsubscribe, no callbacks found for topic: ${topic}`);
        }
    }


    unsubscribeFromTopic(topic) {
        // check that there is an unsubscribe callback for the topic

        if (topic in this.unsubscribeCallbacks) {
            // calls the unsubscribe() function for this topic (this callback is returned from the subscribe() function)
            this.unsubscribeCallbacks[topic]();
            // remove the unsubscribe callback
            delete this.unsubscribeCallbacks[topic];
        }
        else {
            console.log(`${this.productName}: Unable to unsubscribe, no callbacks found for topic: ${topic}`);
        }
    }

    subscribe(topic, callback, serviceName, workerType, max_rate_hertz, priority_broker = true) {
        var msg = new mqttWorkerMsg({ 
            topic,
            max_rate_hertz,
            priority_broker
        }, mqttWorkerMsg.MQTT_SUB_MSG);

        this[workerType].postMessage(msg);

        var model = new callbackModel(callback, serviceName, workerType);
        this.callbacks.push(topic, model);

        var unsubscribeCallback = () => {
            this.unsubscribe(topic, model, workerType);
        };
        this.unsubscribeCallbacks[topic] = unsubscribeCallback;

        return unsubscribeCallback;
    }


    reInitSubs() {
        for(var topic in this.callbacks.dict){
            var models = this.callbacks.dict[topic];
            var msg = new mqttWorkerMsg({ topic }, mqttWorkerMsg.MQTT_SUB_MSG);

            if(models.length > 0){
                var model = models[0];
                this[model.workerType].postMessage(msg);
            }
            else{
                console.log(`${this.productName}: reinit found topic: ${topic}, without a callback`);
            }
        }
    }

    end() {
        //SEND END
        var msg = new mqttWorkerMsg({}, mqttWorkerMsg.END_MSG);

        for (var key in WorkerTypes){
            if (this[key] != null)
            {
                this[key].postMessage(msg);
                this[key].terminate();
                this[key] = null;
            }
        }
    }
};

export default CommManagerReciever;
