export const CoverageMapData = {
  SourceId: 'coverage',
  Layers: [{
    id: 'coverage-fill',
    type: 'fill',
    source: 'coverage',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'fill-color': '#86C93D',
      'fill-opacity': 0.1
    }
  }, {
    id: 'coverage-border',
    type: 'line',
    source: 'coverage',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'line-color': '#7FC141',
      'line-width': 2
    }
  }]
}

export const TractorCoverageMapData = {
  SourceId: 'tractor-coverage',
  Layers: [{
    id: 'tractor-coverage-fill',
    type: 'fill',
    source: 'tractor-coverage',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'fill-color': '#00B2FF',
      'fill-opacity': 0.1
    }
  }, {
    id: 'tractor-coverage-border',
    type: 'line',
    source: 'tractor-coverage',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'line-color': '#004968',
      'line-width': 2
    }
  }]
}

export const ManualCoverageMapData = {
  SourceId: 'manual-coverage',
  Layers: [{
    id: 'manual-coverage-fill',
    type: 'fill',
    source: 'manual-coverage',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'fill-color': 'yellow',
      'fill-opacity': 0.1
    }
  }, {
    id: 'manual-coverage-border',
    type: 'line',
    source: 'manual-coverage',
    layout: {
      'visibility': 'visible'
    },
    paint: {
      'line-color': 'yellow',
      'line-width': 2
    }
  }]
}