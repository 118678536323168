export const mountPoint = 'modal';
export const actions = {
  OPEN_MODAL: 'modal/OPEN_MODAL',
  CLOSE_MODAL: 'modal/CLOSE_MODAL',
  UPDATE_MODAL_BODY: 'modal/UPDATE_MODAL_BODY',
};

export const openModal = options => {
  return dispatch => {
    dispatch({
      options: options,
      type: actions.OPEN_MODAL
    });
  }
}

export const closeModal = () => {
  return dispatch => {
    dispatch({
      type: actions.CLOSE_MODAL
    });
  }
}

export const updateModalBody = newBody => {
  return dispatch => {
    dispatch({
      type: actions.UPDATE_MODAL_BODY,
      newBody
    });
  }
}

const initialState = {
  options: undefined,
  showModal: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
  case actions.OPEN_MODAL:
    return {
      ...state,
      options: action.options,
      showModal: true
    }
  case actions.CLOSE_MODAL:
    return {
      ...state,
      options: undefined,
      showModal: false
    }
  case actions.UPDATE_MODAL_BODY:
    return {
      ...state,
      options: {
        ...state.options,
        body: action.newBody
      }
    };
  default:
    return state;
  }
}