import axios from 'axios';
import { clearUserTokens } from '../dux/account-management-dux';

export const setupAxios = (dispatch, tokens) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  if (tokens['jwt']) {
    axios.defaults.headers = {
      'jwt': tokens['jwt']
    }
  } else {
    axios.defaults.headers = {
      'X-Access-Token': tokens.accessToken,
      'X-Id-Token': tokens.idToken,
      'X-Refresh-Token': tokens.refreshToken,
    };
  }

  axios.interceptors.response.use(null, error => {
    if (error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      dispatch(clearUserTokens());
      window.location.assign(`${process.env.REACT_APP_ACCOUNTS_UI_SLINGSHOT}${window.location.href}`);
    }

    let newError = error;

    if (!error.response) {
      newError.response = {
        data: {
          name: 'InternetNotAvailable'
        }
      };
    }

    return Promise.reject(newError);
  });
};