import axios from 'axios';

export default {
  checkConnection: async () => {
    const response = await axios.get(`${process.env.REACT_APP_MACHINE_CONTROL_URL}/connectionstatus`, {
      // Cannot pass headers because of Access-Control-Allow-Headers, so we have to delete them here
      transformRequest: [
        (data, headers) => {
          delete headers['X-Access-Token'];
          delete headers['X-Id-Token'];
          delete headers['X-Refresh-Token'];
          delete headers['jwt'];
          return data;
        }
      ]
    });

    return response.status === 200;
  }
}