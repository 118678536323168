// utilities
import timeCompare from './timeCompare';

export default class messageTimestampFilter {
    constructor() {
        this.dict = {};
        this.per_topic_throttle = {};
        this.per_machine_dict = {};
    }

    isMessageOldPerTopic(topic, timestamp) {
        var isMessageOld = false;
        var existingMessageTimeStamp = this.dict[topic];
        // if there hasn't been a timestamp saved, we know it's new 
        if (existingMessageTimeStamp != null) {
            isMessageOld = !timeCompare.isTimestampNew(existingMessageTimeStamp, timestamp)
        }
        // if the message is new we need to update the timestamp at the index
        if (!isMessageOld) {
            this.dict[topic] = timestamp;
        } else {
            console.log('*** isMessageOldPerTopic ***', `message is old, topic: ${topic} - message timestamp: ${timestamp} - existing timestamp: ${existingMessageTimeStamp}`);
        }
        return isMessageOld;
    }

    shouldThrottleMessage(topic){
        
        if (topic in this.per_topic_throttle){
            const val = this.per_topic_throttle[topic];
            const last_used_ms = val[0];
            const max_rate_hertz = 1.0 / val[1];
            const curr_secs = new Date().getTime() / 1000;
            const delta_t = curr_secs - last_used_ms;
            const should_throttle = delta_t < max_rate_hertz;
            
            if(!should_throttle){
                this.per_topic_throttle[topic] = [curr_secs, val[1]];
            }

            if (last_used_ms == 0) {
                return false
            }

            return should_throttle;
        }

        return false;
    }

    initThrottleMessage(topic, max_rate_hertz){
        this.per_topic_throttle[topic] = [0.0, max_rate_hertz];
    }

    isMessageOldPerMachine(timestamp_secs, topic) {
        var topic_split = topic.split('/');
        var machine = topic_split[1];

        var shouldUpdateTimestamp = true;
        var isMessageOld = false;
        var existingMessageTimeStamp = this.per_machine_dict[machine];
        // if there hasn't been a timestamp saved, we know it's new 
        if (existingMessageTimeStamp != null) {
            isMessageOld = !timeCompare.isTimestampNew(existingMessageTimeStamp, timestamp_secs, 10)
            shouldUpdateTimestamp = timeCompare.isTimestampNew(existingMessageTimeStamp, timestamp_secs)
        }

        // if the message is new we need to update the timestamp at the index
        if (shouldUpdateTimestamp) {
            this.per_machine_dict[machine] = timestamp_secs;
        }

        return isMessageOld;
    }


    remove(topic) {
        // if the topic exists, delete it
        if (topic in this.dict) {
            delete this.dict[topic];
        }
        if (topic in this.per_topic_throttle) {
            delete this.per_topic_throttle[topic];
        }
    }
}