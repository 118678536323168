import React from 'react';
import '../../styles/machines-view.scss';
import MachineList from '../MachineList';
import { setMachineList, setSelectedMachine } from '../../dux/machine-dux';
import Loader from '../Loader';
import { Routes } from '../../constants/routes';

class MachinesView extends React.Component {

  componentDidMount() {
    this.props.dispatch(setMachineList(this.props.accountManagement.activeAccount.account_id));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accountManagement.activeAccount.account_id !== this.props.accountManagement.activeAccount.account_id) {
      this.props.dispatch(setMachineList(this.props.accountManagement.activeAccount.account_id));
    }
  }

  handleMachineClick = machine => {
    this.props.dispatch(setSelectedMachine(machine));
    this.props.history.push(Routes.MachineSettings(machine.dbId));
  }

  chooseMachineType = () => {
    this.props.history.push(Routes.MachineType);
  }

  render() {
    if (!this.props.machine.machineList) {
      return <Loader />;
    }

    return (
      <div className='machines-view'>
        <h1>{'Machine Settings'}</h1>
        <MachineList onMachineClick={this.handleMachineClick} />
        <button className='add-button'
          onClick={this.chooseMachineType}>
          {'+ Add New Equipment'}
        </button>
      </div>
    );
  }
}

export default MachinesView;