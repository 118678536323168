import {Record} from 'immutable';

export const GPSInfo = Record({
  lat: null,
  lon: null,
  heading: 0,
  yaw_rate: '0',
  direction: 'N',
  speed_mph: '0',
}, 'GPSInfo');

export const MachineConfig = Record({
  data: null,
  version: null
}, 'MachineConfig');

export const MachineConfigOptions = Record({
  turning_radius_default: null,
  turning_radius_min: null
}, 'MachineConfigOptions');

export const CANInfo = Record({
  gear: 0,
  eng_rpm: 0,
  fuel_level: 0
}, 'CANInfo');

const SensorStatusRecord = Record({
  gps_fail: 1,
  can0_fail: 1,
  can1_fail: 1,
  gps_acquiring: 1,
  ignition_key: 0,
  shutdown_timer: 0,
}, 'SensorStatus');

export class SensorStatus extends SensorStatusRecord {
  static GPS_FAIL = 0;
  static GPS_ACQUIRING = 1;
  static GPS_LOCKED = 2;

  isInFailureState(){
    var gpsHasFailed = this.getGPSStatus() != SensorStatus.GPS_LOCKED;
    return gpsHasFailed || this.can0_fail === 1 || this.can1_fail === 1;
  }

  getGPSStatus(){
    if(this.gps_fail)
    {
      return SensorStatus.GPS_FAIL;
    }else if(this.gps_acquiring){
      return SensorStatus.GPS_ACQUIRING;
    }else{
      return SensorStatus.GPS_LOCKED;
    }
  }
}

/*
 * errors bitmask:
 * bypass : bit 0
 * estop : bit 1
 * vision stop : bit 2
 * predictive stop : bit 3
 * sync stop : bit 4
 * field boundary: bit 5
 * navigation not ready: bit 6
 * perception not ready: bit 7
 * radar stop : bit 8
 * object tracking stop : bit 10
*/
export const MachineStatus = Record({
  armed: false,
  errors: 0
}, 'MachineStatus');

export const MachineStatusExtra = Record({
  armed: false,
  errors: 0,
  field_id: 0,
  config_version: 0,
  cov_mode: 'OFF',
}, 'MachineStatusExtra');
