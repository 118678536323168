// utilities
import * as commandCreatorsNoRedux from './commandCreatorsNoRedux';
import commManagerMsgTypes from './commManagerMsgTypes';
import machineConfigConstants from './machineConfigConstants';
import machineCommandTopics from './machineCommandTopics';
import {guid} from './guid';
import commandCreatorsHelper from './commandCreatorsHelper';
import WorkerTypes from './WorkerTypes';

export default class heartbeatHelper {
    constructor(client, maxHeartbeatResponseMs=3000, delayOnHeartbeatRestartMs=10000) {
        this.lastReceivedPingMs = 0;
        this.command = commandCreatorsNoRedux.middlewarePing();
        this.maxHeartbeatResponseMs = maxHeartbeatResponseMs;
        this.delayOnHeartbeatRestartMs = delayOnHeartbeatRestartMs;
        this.interval = null;
        this.client = client;
    }

    start() {
        this.updateLastReceivedPing();
        this.interval = setInterval(this.intervalFunc.bind(this), 1000);
    }

    intervalFunc() {
        // send a ping command to check communication link
        this.sendCommand();
        // disarm system if communication link is not stable
        if (!this.isCommunicationLinkStable()) {
            this.timeout();
            this.delayInterval.bind(this)();
        }
    }

    delayInterval() {
        clearInterval(this.interval);
        setTimeout(this.start.bind(this), this.delayOnHeartbeatRestartMs);
    }

    updateLastReceivedPing() {
        this.lastReceivedPingMs = Date.now();
    }

    isCommunicationLinkStable() {
        var currentTimestampMs = Date.now();
        var diffMs = currentTimestampMs - this.lastReceivedPingMs
        return diffMs < this.maxHeartbeatResponseMs;
    }

    sendCommand() {
        this.command.id = guid();
        var commandTopic = machineCommandTopics.createCommandTopic(this.client.accountId, machineConfigConstants.TRACTOR_NAME);
        var commandResponseTopic = machineCommandTopics.createCommandResponseTopic(this.client.accountId, machineConfigConstants.TRACTOR_NAME, this.command.id);
        
        var timeout = setTimeout(() => {
            clearTimeout(timeout);
            //this.client.unsubscribe(commandResponseTopic);
            unsub();
        }, this.maxHeartbeatResponseMs);

        var unsub = this.client.subscribe(commandResponseTopic, { data: { priority_broker: true } }, () => {
            this.updateLastReceivedPing();
            //this.client.unsubscribe(commandResponseTopic);
            unsub();
            clearTimeout(timeout);
        });

        commandCreatorsHelper.updateCommandTimestamp(this.command);
        
        this.client.publish(commandTopic, JSON.stringify(this.command));
    }

    timeout() {
        var timeoutMsg = {
            type: commManagerMsgTypes.HEARTBEAT_TIMEOUT_MSG
        }
        this.client.relayMessageCallback(timeoutMsg);
    }
}