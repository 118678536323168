export const CREATE_SOCKET_EVENTS = 'createsocketevents';
export const UPDATE_MACHINE_CONFIG = 'machine_control/machine/update_config';
export const MACHINE_CONTROL_READY = 'machine_control/ready';
export const OPERATION_DATA = 'machine_control/operation_data';
export const SET_NEW_IOT_TOKEN = 'machine_control/set_new_iot_token';
export const UPDATE_COVERAGE_MAP = 'machine_control/field/update_coverage_map_data';
export const GET_COVERAGE_MAP = 'machine_control/get_coverage_map_data';
export const UPDATE_FIELD = 'machine_control/field/update';
export const SAVE_SYNC_POINT = 'machine_control/save_sync_point';
export const CHANGED_FIELD = 'machine_control/field/changed';
export const EXIT_OPERATION = 'machine_control/exit_operation';
export const GET_NEW_IOT_TOKEN = 'machine_control/get_new_iot_token';
export const DATABASE = 'machine_control/db';
export const TOGGLE_DARK_MODE = 'machine_control/toggle_dark_mode';
export const SEND_LOG = 'machine_control/send_log';
export const AAVI_CONNECTED_CLIENTS = 'machine_control/aavi_connected_clients';
export const DUPLICATE_SESSION_STARTED = 'machine_control/duplicate_session_started';
export const DISARM = 'machine_control/DISARM';
export const DISARM_SENT = 'machine_control/DISARM_SENT';

/**
 * Creates the update machine config topic
 @return {string} topic of the update machine config command
 */
export function updateMachineConfig() {
  return UPDATE_MACHINE_CONFIG
}

/**
 * Creates the machine control ready topic
 @return {string} topic of the machine control ready command
 */
export function machineControlReady() {
  return MACHINE_CONTROL_READY;
}

/**
 * Creates the operation data topic
 @return {string} topic of the operation data command
 */
export function operationData() {
  return OPERATION_DATA;
}

/**
 * Creates the update coverage map topic
 * @return {string} topic of the update coverage map command
 */
export function updateCoverageMap() {
  return UPDATE_COVERAGE_MAP;
}

/**
 * Creates the get coverage map topic
 * @param {string} accountId optional account id to add into the topic
 * @return {string} topic of the get coverage map command
 */
export function getCoverageMap(accountId = null) {
  if (!accountId) {
    return GET_COVERAGE_MAP;
  } else {
    return accountId + '/' + GET_COVERAGE_MAP;
  }
}

/**
 * Creates the update field topic
 @return {string} topic of the update field command
 */
export function fieldSaveTopic() {
  return UPDATE_FIELD;
}

/**
 * Creates the update field topic
 @return {string} topic of the update field command
 */
export function fieldChangedTopic() {
  return CHANGED_FIELD;
}

/**
 * Creates the save sync point topic
 @return {string} topic of the save sync point command
 */
export function saveSyncPoint() {
  return SAVE_SYNC_POINT;
}

export function objectTrackingStateTopic(accountId) {
  return `${accountId}/tractor/state/object-tracking`;
}


export function cameraFeedTopic(accountId) {
  return `${accountId}/tractor/state/object-tracking/frames`;
}

export function guidanceSyncBreadcrumbs(accountId) {
  return `${accountId}/tractor/guidance/breadcrumbs`;
}

export function combineNonPrimaryStateTopicSlimCore(accountId) {
  return `${accountId}/+/state/slim/core`;
}

export function machineStatusTopic(accountId, machineId) {
  return `${accountId}/${machineId}/status`;
}

export function machineStateTopicSlimCore(accountId, machineId) {
  return `${accountId}/${machineId}/state/slim/core`;
}

export function machineStateTopicSlimExtra(accountId, machineId) {
  return `${accountId}/${machineId}/state/slim/extra`;
}

export function machineStatusTopicSlimCore(accountId, machineId) {
  return `${accountId}/${machineId}/status/slim/core`;
}

export function machineStatusTopicSlimExtra(accountId, machineId) {
  return `${accountId}/${machineId}/status/slim/extra`;
}

export function machineConnectionTopic(accountId, machineId) {
  return `${accountId}/${machineId}/connection`;
}

export function machineCoverageTopic(accountId, machineId) {
  return `${accountId}/${machineId}/coverage`;
}

export function machineLogErrorTopic(accountId, machineId) {
  return `${accountId}/${machineId}/log/error`;
}

export function combineBubblePathPlanner(accountId, machineId) {
  return `${accountId}/${machineId}/combinepoly/large`;
}

export function manualCoverageTopic(accountId) {
  return `${accountId}/ui/manualcoverage`;
}