export const MachineType = {
  Tractor: 'T',
  Combine: 'C'
};

export const TractorType = {
  PST: 'PST',
  IVT: 'IVT'
};

export const PSTConfigKeys = {
  MinRPM: 'minRPM',
  MaxRPM: 'maxRPM'
};

export const IVTConfigKeys = {
  SpeedDialF1: 'speedDialF1',
  SpeedDialF2: 'speedDialF2'
};

export const MachineTypeIcons = {
  Combine: 'combine',
  Tractor: 'tractor',
  CombineSecondary: 'combine-secondary'
};