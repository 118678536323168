import { getLocalItem, setLocalItem } from '../helpers/local-storage';
import { LocalStorageKeys } from '../constants/localStorageKeys';
import { DocumentAttributes, Themes } from '../constants/theme';

export const mountPoint = 'settings';
export const actions = {
  INIT_DARK_MODE: 'settings/INIT_DARK_MODE',
  TOGGLE_DARK_MODE: 'settings/TOGGLE_DARK_MODE'
};

export const initDarkMode = () => {
  const theme = getLocalItem(LocalStorageKeys.DarkMode) ? Themes.Dark : Themes.Light;

  document.documentElement.setAttribute(DocumentAttributes.Theme, theme);

  return dispatch => {
    dispatch({
      type: actions.INIT_DARK_MODE
    });
  }
}

export const toggleDarkMode = () => {
  return dispatch => {
    dispatch({
      type: actions.TOGGLE_DARK_MODE
    });

    setLocalItem(LocalStorageKeys.DarkMode, !getLocalItem(LocalStorageKeys.DarkMode));
    const theme = getLocalItem(LocalStorageKeys.DarkMode) ? Themes.Dark : Themes.Light;

    document.documentElement.setAttribute(DocumentAttributes.Theme, theme);
  }
}

const initialState = {
  darkMode: false
};

export function reducer(state = initialState, action) {
  switch (action.type) {
  case actions.INIT_DARK_MODE:
    return {
      ...state,
      darkMode: getLocalItem(LocalStorageKeys.DarkMode) ? getLocalItem(LocalStorageKeys.DarkMode) : false,
    }
  case actions.TOGGLE_DARK_MODE:
    return {
      ...state,
      darkMode: !state.darkMode
    };
  default:
    return state;
  }
}