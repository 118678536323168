import FieldsGeoJson from './FieldsGeoJson';
import GeoJsonHelper from './GeoJsonHelper';
import FieldGeoJson from './FieldGeoJson';
import shp from 'shpjs';
import { ErrorMessages } from '../constants/errorMessages';

export default class ZipFileParser {
  static async parseFieldsFromZipFiles(zipFileList) {
    const zipFiles = [...zipFileList];

    if (zipFiles.length === 0) {
      const fieldObj = new FieldsGeoJson([]);

      return fieldObj;
    }

    try {
      const parsedFieldPromises = zipFiles.map(zip => {
        return ZipFileParser.parseFieldsFromZipFile(zip);
      });
      const parsedFields = await Promise.all(parsedFieldPromises);
      const allFields = [].concat.apply([], parsedFields);
      const fieldObj = new FieldsGeoJson(allFields);

      return fieldObj;
    } catch (error) {
      throw new Error(ErrorMessages.Parse);
    }
  }

  static readFile(zipFile) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = loadedEvent => {
        resolve(loadedEvent);
      };
      reader.readAsArrayBuffer(zipFile);
    });
  }

  static async parseFieldsFromZipFile(zipFile) {
    const loadedEvent = await ZipFileParser.readFile(zipFile);
    const arrayBuffer = loadedEvent.target.result;
    const buffer = new DataView(arrayBuffer, 0, arrayBuffer.byteLength);
    const fileName = zipFile.name.split('.zip')[0];
    const geoJsonData = await shp(buffer);
    const fieldPolygons = GeoJsonHelper.standardizePoly(geoJsonData);
    const standardFeatureCollection = GeoJsonHelper.standardPolyToFeatureCollection(fieldPolygons);
    const fields = standardFeatureCollection.features.map((feature, index) => {
      let newFileName = `${fileName}`;

      if (standardFeatureCollection.features.length > 1) {
        newFileName = `${newFileName} - ${index + 1}`
      }

      let clonedFieldData = { ...geoJsonData };

      clonedFieldData.features = [feature];

      const field = new FieldGeoJson(clonedFieldData, newFileName);

      return field;
    });

    return fields;
  }
}