import camelCase from 'lodash.camelcase';
import { TractorInfo } from '../models/machineInfoModel';

export function reducerFromModel(klass) {
    return function (state=new klass(), action) {
        var fn = state[camelCase(action.type + '_REDUCER')];
        if (fn) {
            return state[camelCase(action.type + '_REDUCER')](action);
        } else {
            if (state[camelCase(action.type)]) {
                console.warn('You tried to call an action method, but no such action method provided.', action.type)
            }
            return state;
        }
    }
};