import React from 'react';
import ReactLoading from 'react-loading';
import '../styles/loader.scss';
import { LoaderProps } from '../constants/loaderProps';

class Loader extends React.Component {
  render() {
    return (
      <div className='loader'>
        <ReactLoading type={LoaderProps.Style} color={LoaderProps.Color} />
      </div>
    );
  }
}

export default Loader;