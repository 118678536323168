import secondaryCombineModel from "./secondaryCombineModel";

export default class secondaryCombineModels{
    constructor()
    {
        this.combines = [];
    }

    removeInvalid(){
        for(var i = 0; i < this.combines.length; i++){
            var combine = this.combines[i];
            
            if(!combine.isValid())
                this.combines.splice(i,1);
        }
    }

    upsertCombine(model){
        var index = this.combines.findIndex(x => x.combineName === model.combineName);
        if (index >= 0) {
            this.combines[index].update(model.combineName, model.lon, model.lat, model.heading)
        }
        else{
            var newCombine = new secondaryCombineModel(model.combineName, model.lon, model.lat, model.heading);
            this.combines.push(newCombine);
        }

        this.removeInvalid();
    }
}