import React from 'react';
import '../styles/field-list.scss';

class FieldList extends React.Component {
  renderRow(field) {
    return (
      <li
        key={field.id}
        className='field'
        onClick={() => this.props.onFieldClick(field)}
      >
        <div className='bottom-border'>
          {field.displayName}
        </div>
      </li>
    );
  }

  render() {
    const fields = this.props.fieldList.map(field =>
      this.renderRow(field)
    );

    return (
      <ul className='field-list'>
        {fields}
      </ul>
    );
  }
}
export default FieldList;