import React from 'react';
import '../../styles/field-settings-sidebar.scss';
import { ReactComponent as BackButton } from '../../assets/BackButton.svg';
import { connect } from 'react-redux';
import FieldService from '../../services/v1/FieldService';
import { ErrorMessages } from '../../constants/errorMessages';
import { openDialog } from '../../dux/dialog-dux';
import { deleteField, updateSelectedFieldProperty, FieldProperties } from '../../dux/field-dux';
import { showDefaultErrorToast, dismissToast } from '../../helpers/toast-helper';
import { openModal, closeModal, updateModalBody } from '../../dux/modal-dux';
import { Routes } from '../../constants/routes';

export class FieldSettingsSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldName: this.props.field.displayName,
      editedFieldName: this.props.field.displayName
    };
    this.fieldNameRef = null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accountManagement.activeAccount.account_id !== this.props.accountManagement.activeAccount.account_id) {
      // if user was editing a field then switched accounts, we need to go back to the home page
      this.props.history.push(Routes.Home);
    }
  }

  handleEditFieldName = () => {
    const fieldNameInput = <textarea
      key='field-name'
      className='edit-field-name'
      maxLength='100'
      value={this.state.editedFieldName}
      onChange={this.handleFieldNameChange}
    ></textarea>;

    const editFieldNameModalOptions = {
      title: 'Edit Field Name',
      body: [fieldNameInput],
      rightButton: 'Save',
      onRightButtonClick: this.handleSaveButton,
      onExit: this.exitModal
    };

    this.props.dispatch(openModal(editFieldNameModalOptions));
  }

  handleFieldNameChange = event => {
    this.setState({
      editedFieldName: event.target.value
    });
    const fieldNameInput = <textarea
      key='field-name'
      className='edit-field-name'
      maxLength='100'
      value={event.target.value}
      onChange={this.handleFieldNameChange}
    />;

    this.props.dispatch(updateModalBody([fieldNameInput]));
  }

  exitModal = () => {
    this.props.dispatch(closeModal());
    dismissToast(ErrorMessages.NoFieldName);
    this.setState({
      editedFieldName: this.state.fieldName
    });
  }

  handleSaveButton = async () => {
    const updateFieldNameModel = {
      id: this.props.field.id,
      displayName: this.state.editedFieldName
    };

    if (this.state.editedFieldName.length === 0) {
      showDefaultErrorToast(ErrorMessages.NoFieldName);
      return;
    }

    try {
      await FieldService.updateFieldName(updateFieldNameModel);
      this.setState({
        fieldName: this.state.editedFieldName
      });
      this.exitModal();
      this.props.dispatch(updateSelectedFieldProperty(FieldProperties.Name, updateFieldNameModel.displayName));
    } catch (error) {
      showDefaultErrorToast(ErrorMessages.UpdateFieldName);
    }
  }

  handleSetupOperationButton = () => {
    this.props.history.push(Routes.FieldSetupOperation);
  }

  handleModifyField = () => {
    this.props.history.push(Routes.FieldCreateModify);
  }

  handleDeleteField = () => {
    this.props.dispatch(openDialog({
      content: `Are you sure you want to delete the field "${this.props.field.displayName}"?`,
      yesButton: async () => {
        try {
          const fieldId = this.props.field.id;

          await FieldService.deleteField(fieldId);
          this.props.dispatch(deleteField(fieldId));
          this.props.history.push(Routes.Home);
          this.props.removeFieldFromSource(fieldId);
        } catch (error) {
          showDefaultErrorToast(ErrorMessages.DeleteField);
        }
      }
    }));
  }

  render() {
    return (
      <div className='field-settings-sidebar'>
        <div className='back-button'>
          {/* Push to Home because if we use `goBack` here, and they have just modified a field and saved, it would take them back to modify */}
          <BackButton className='back' onClick={() => this.props.history.push(Routes.Home)} />
        </div>

        <h3>{this.state.fieldName}</h3>

        <ul className='button-list'>
          <li className='item' onClick={this.handleModifyField}>
            <div className='bottom-border'>
              {'Modify Field'}
            </div>
          </li>
          <li className='item' onClick={this.handleEditFieldName}>
            <div className='bottom-border'>
              {'Edit Field Name'}
            </div>
          </li>
          <li className='item' onClick={this.handleDeleteField}>
            <div className='bottom-border'>
              {'Delete Field'}
            </div>
          </li>
        </ul>

        <button onClick={this.handleSetupOperationButton}>{'Setup Operation'}</button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    field: state.field.selectedField,
    accountManagement: state.accountManagement,
    modal: state.modal
  }
}

export default connect(mapStateToProps)(FieldSettingsSidebar);