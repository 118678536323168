import MachineService from '../services/v1/MachineService';
export const mountPoint = 'machine';
export const actions = {
  SET_MACHINE_LIST: 'machine/SET_MACHINE_LIST',
  SET_MACHINE_LIST_ERROR: 'machine/SET_MACHINE_LIST_ERROR',
  LOADING_MACHINE_LIST: 'machine/LOADING_MACHINE_LIST',
  SET_SELECTED_MACHINE: 'machine/SET_SELECTED_MACHINE'
};

export const setMachineList = accountId => {
  return async dispatch => {
    dispatch({
      type: actions.LOADING_MACHINE_LIST
    });

    try {
      const result = await MachineService.getMachineList(accountId);

      dispatch({
        machineList: result.data,
        type: actions.SET_MACHINE_LIST
      });

    } catch (err) {
      dispatch({
        error: err.response.data,
        type: actions.SET_MACHINE_LIST_ERROR
      });
    }
  }
}

export const setSelectedMachine = machine => {
  return dispatch => {
    dispatch({
      type: actions.SET_SELECTED_MACHINE,
      machine: machine
    });
  }
}

const initialState = {
  machineList: undefined,
  selectedMachine: undefined,
  error: ''
};

export function reducer(state = initialState, action) {
  switch (action.type) {
  case actions.LOADING_MACHINE_LIST:
    return {
      ...state,
      machineList: undefined
    };
  case actions.SET_MACHINE_LIST:
    return {
      ...state,
      machineList: action.machineList
    };
  case actions.SET_MACHINE_LIST_ERROR:
    return {
      ...state,
      error: action.error
    };
  case actions.SET_SELECTED_MACHINE:
    return {
      ...state,
      selectedMachine: action.machine
    };
  default:
    return state;
  }
}