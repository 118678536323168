// actions
import * as commActions from './actions/commActions';
import * as combineInfoActions from './actions/combineInfoActions';
import * as secondaryCombineActions from './actions/secondaryCombineActions';
import * as trackedObjectsActions from './actions/trackedObjectsActions';
import * as cameraFeedActions from './actions/cameraFeedActions';
import * as tractorInfoActions from './actions/tractorInfoActions';
import * as combineBubblesActions from './actions/combineBubblesActions';
import { updateCoverageMap } from './dux/coverage-map-dux';

// utilities
import machineConfigConstants from './machineConfigConstants';
import * as commTopicGenerator from './commTopicGenerator';

// models
import WorkerTypes from './WorkerTypes';

export default class commEventListeners {
  /* COMBINE STATE */
  static combineCoverageListener(dispatch, getCoverageMapCallback, prefix, logErrorCallback = null) {
    const combineCoverageTopic = commTopicGenerator.machineCoverageTopic(prefix, machineConfigConstants.COMBINE_NAME);
    const callback = newCoverageMap => {
      // Get the coverage map currently in the state
      // Validate the new coverage
      if (newCoverageMap.type != null && newCoverageMap.coordinates != null) {
        const curr_time = new Date().getTime();

        console.log(`Got coverage in: ${Math.abs(curr_time - newCoverageMap.timestamp * 1000) + ' ms'}`);
        dispatch(updateCoverageMap(newCoverageMap, logErrorCallback));
      } else {
        const message = 'Received invalid coverage: ' + JSON.stringify(newCoverageMap);

        console.error(message);

        if (logErrorCallback) {
          dispatch(logErrorCallback({ message }));
        }
      }
    };
    const action = commActions.subscribe(
      combineCoverageTopic,
      callback,
      machineConfigConstants.COMBINE_NAME,
      WorkerTypes.BLOBS_WORKER,
      6.0);

    dispatch(action);
  }

  static combineBubblePathPlanner(dispatch, prefix) {
    const combineBubblePathPlannerTopic = commTopicGenerator.combineBubblePathPlanner(prefix, machineConfigConstants.TRACTOR_NAME);
    const callback = message => {
      const action = combineBubblesActions.updateCombineBubblePathPlanner(message);

      dispatch(action);
    };
    const action = commActions.subscribe(
      combineBubblePathPlannerTopic,
      callback,
      machineConfigConstants.TRACTOR_NAME,
      WorkerTypes.BLOBS_WORKER,
      2.0);

    dispatch(action);
  }

  static combineStateListenerSlimCore(dispatch, getCombineInfoCallback, prefix) {
    const combineStateTopic = commTopicGenerator.machineStateTopicSlimCore(prefix, machineConfigConstants.COMBINE_NAME);
    const callback = (message, broker_name, topic) => {
      const combineInfo = getCombineInfoCallback();

      combineInfoActions.updateCombineStateSlimCore(dispatch, message);
      if (!combineInfo.machine.isBrokerConnected(broker_name)) {
        combineInfoActions.updateCombineBrokerStatus(dispatch, broker_name, true);
      }
    };
    const action = commActions.subscribe(
      combineStateTopic,
      callback,
      machineConfigConstants.COMBINE_NAME,
      WorkerTypes.STREAMS_WORKER,
      2.0);

    dispatch(action);
  }

  static combineStateListenerSlimExtra(dispatch, getCombineInfoCallback, prefix) {
    const combineStateTopic = commTopicGenerator.machineStateTopicSlimExtra(prefix, machineConfigConstants.COMBINE_NAME);
    const callback = (message, broker_name, topic) => {
      const combineInfo = getCombineInfoCallback();

      combineInfoActions.updateCombineStateSlimExtra(dispatch, message);
      if (!combineInfo.machine.isBrokerConnected(broker_name)) {
        combineInfoActions.updateCombineBrokerStatus(dispatch, broker_name, true);
      }
    };
    const action = commActions.subscribe(
      combineStateTopic,
      callback,
      machineConfigConstants.COMBINE_NAME,
      WorkerTypes.STREAMS_WORKER,
      6.0);

    dispatch(action);
  }

  static combineSecondaryStateListenerSlimCore(dispatch, prefix) {
    const combineStateTopic = commTopicGenerator.combineNonPrimaryStateTopicSlimCore(prefix);
    const callback = (message, broker_name, topic) => {
      const vehicleName = topic.split('/')[1];
      const notPrimaryCombine = vehicleName.toLowerCase() !== machineConfigConstants.COMBINE_NAME.toLowerCase();
      const notPrimaryTractor = vehicleName.toLowerCase() !== machineConfigConstants.TRACTOR_NAME.toLowerCase();

      if (notPrimaryCombine && notPrimaryTractor) {
        secondaryCombineActions.updateSecondaryCombineStateSlimCore(dispatch, vehicleName, message);
      }
    };
    const action = commActions.subscribe(
      combineStateTopic,
      callback,
      machineConfigConstants.SECONDARY_COMBINE_NAME,
      WorkerTypes.STREAMS_WORKER,
      2.0);

    dispatch(action);
  }
  /* COMBINE STATE */
  /* COMBINE STATUS */

  static combineStatusListenerSlimCore(dispatch, prefix) {
    const combineStatusTopic = commTopicGenerator.machineStatusTopicSlimCore(prefix, machineConfigConstants.COMBINE_NAME);
    const callback = (message, brokerName) => {
      // update machine status
      combineInfoActions.updateCombineStatusSlimCore(dispatch, message);
    };
    const action = commActions.subscribe(
      combineStatusTopic,
      callback,
      machineConfigConstants.COMBINE_NAME,
      WorkerTypes.STREAMS_WORKER,
      2.0);

    dispatch(action);
  }

  static combineStatusListenerSlimExtra(dispatch, prefix) {
    const combineStatusTopic = commTopicGenerator.machineStatusTopicSlimExtra(prefix, machineConfigConstants.COMBINE_NAME);
    const callback = (message, brokerName) => {
      // update machine status
      combineInfoActions.updateCombineStatusSlimExtra(dispatch, message);
    };
    const action = commActions.subscribe(
      combineStatusTopic,
      callback,
      machineConfigConstants.COMBINE_NAME,
      WorkerTypes.STREAMS_WORKER,
      6.0);

    dispatch(action);
  }

  /* COMBINE STATUS */

  static trackedObjStateListener(dispatch, prefix) {
    const topic = commTopicGenerator.objectTrackingStateTopic(prefix);
    const callback = (message, broker_name, topic) => {
      trackedObjectsActions.updateTrackedObjectsState(dispatch, message);
    };
    const action = commActions.subscribe(
      topic,
      callback,
      'object-tracking',
      WorkerTypes.STREAMS_WORKER,
      1.0);

    dispatch(action);
  }

  static cameraFrameListener(dispatch, prefix) {
    const topic = commTopicGenerator.cameraFeedTopic(prefix);
    const callback = (message, broker_name, topic) => {
      cameraFeedActions.updateCameraFeedAction(dispatch, message);
    };
    const action = commActions.subscribe(
      topic,
      callback,
      'camera-feed',
      WorkerTypes.BLOBS_WORKER,
      1.0);

    dispatch(action);
  }

  /* TRACTOR State */
  static tractorStateListenerSlimCore(dispatch, getTractorInfoCallback, prefix) {
    const tractorStateTopic = commTopicGenerator.machineStateTopicSlimCore(prefix, machineConfigConstants.TRACTOR_NAME);
    const callback = (message, brokerName) => {
      const tractorInfo = getTractorInfoCallback();

      tractorInfoActions.updateTractorStateSlimCore(dispatch, message);
      if (!tractorInfo.machine.isBrokerConnected(brokerName)) {
        tractorInfoActions.updateTractorBrokerStatus(dispatch, brokerName, true);
      }
    };
    const action = commActions.subscribe(
      tractorStateTopic,
      callback,
      machineConfigConstants.TRACTOR_NAME,
      WorkerTypes.STREAMS_WORKER,
      2.0);

    dispatch(action);
  }


  static tractorStateListenerSlimExtra(dispatch, getTractorInfoCallback, prefix) {
    const tractorStateTopic = commTopicGenerator.machineStateTopicSlimExtra(prefix, machineConfigConstants.TRACTOR_NAME);
    const callback = (message, brokerName) => {
      const tractorInfo = getTractorInfoCallback();

      tractorInfoActions.updateTractorStateSlimExtra(dispatch, message);
      if (!tractorInfo.machine.isBrokerConnected(brokerName)) {
        tractorInfoActions.updateTractorBrokerStatus(dispatch, brokerName, true);
      }
    };
    const action = commActions.subscribe(
      tractorStateTopic,
      callback,
      machineConfigConstants.TRACTOR_NAME,
      WorkerTypes.STREAMS_WORKER,
      6.0);

    dispatch(action);
  }
  /* END TRACTOR State */

  /* TRACTOR status */
  static tractorStatusListenerSlimCore(dispatch, prefix) {
    const tractorStatusTopic = commTopicGenerator.machineStatusTopicSlimCore(prefix, machineConfigConstants.TRACTOR_NAME);
    const callback = message => {
      tractorInfoActions.updateTractorStatusSlimCore(dispatch, message);
    };
    const action = commActions.subscribe(
      tractorStatusTopic,
      callback,
      machineConfigConstants.TRACTOR_NAME,
      WorkerTypes.STREAMS_WORKER,
      2.0);

    dispatch(action);
  }

  static tractorStatusListenerSlimExtra(dispatch, prefix) {
    const tractorStatusTopic = commTopicGenerator.machineStatusTopicSlimExtra(prefix, machineConfigConstants.TRACTOR_NAME);
    const callback = message => {
      tractorInfoActions.updateTractorStatusSlimExtra(dispatch, message);
    };
    const action = commActions.subscribe(
      tractorStatusTopic,
      callback,
      machineConfigConstants.TRACTOR_NAME,
      WorkerTypes.STREAMS_WORKER,
      6.0);

    dispatch(action);
  }
  /* END TRACTOR status */

  /* TRACTOR Guidance Sync Breadcrumbs */
  static tractorGuidanceSyncBreadcrumbs(dispatch, prefix, updateGuidanceRouteSyncBreadcrumbsAction) {
    const tractorGuidanceSyncBreadcrumbsTopic = commTopicGenerator.guidanceSyncBreadcrumbs(prefix);
    const callback = message => {
      dispatch(updateGuidanceRouteSyncBreadcrumbsAction(message.coordinates));
    };

    const action = commActions.subscribe(
      tractorGuidanceSyncBreadcrumbsTopic,
      callback,
      machineConfigConstants.TRACTOR_NAME,
      WorkerTypes.BLOBS_WORKER,
      5000);

    dispatch(action);
  }
  /* END TRACTOR Guidance Sync Breadcrumbs */

  /* END TRACTOR Log Error */
  static tractorLogErrorListener(dispatch, prefix, addNotificationNoDuplicatesCallback = null) {
    const tractorLogErrorTopic = commTopicGenerator.machineLogErrorTopic(prefix, machineConfigConstants.TRACTOR_NAME);
    const callback = message => {
      if (addNotificationNoDuplicatesCallback) {
        dispatch(addNotificationNoDuplicatesCallback(message.message));
      }
    };
    const action = commActions.subscribe(
      tractorLogErrorTopic,
      callback,
      machineConfigConstants.TRACTOR_NAME,
      WorkerTypes.STREAMS_WORKER,
      2.0);

    dispatch(action);
  }
  /* END TRACTOR Log Error */
}