import coverageMapModel from '../models/coverageMapModel';
import { CoverageMapData } from '../constants/mapConstants';

export const mountPoint = 'coverageMap';
export const actions = {
  UPDATE_COVERAGE_MAP: 'coverageMap/UPDATE_COVERAGE_MAP'
};

export const updateCoverageMap = (coverageFeature, logErrorCallback = null) => {
  return (dispatch, getState) => {
    const { map } = getState();
    const coverageSource = map.instance.getSource(CoverageMapData.SourceId);

    if (!coverageSource) {
      const message = 'Could not update coverage map because map does not have a coverage source';

      console.warn(message);

      if (logErrorCallback) {
        dispatch(logErrorCallback({ message }));
      }

      return;
    }

    coverageSource.setData(coverageFeature);

    dispatch({
      type: actions.UPDATE_COVERAGE_MAP,
      data: coverageFeature,
      geometryType: coverageFeature.type
    });
  };
}

const initialState = new coverageMapModel(1, null, null, new Date(), false);

export function reducer(state = initialState, action) {
  switch (action.type) {
  case actions.UPDATE_COVERAGE_MAP:
    return {
      ...state,
      data: action.data,
      geometryType: action.geometryType
    };
  default:
    return state;
  }
}