export default class GeoJsonHelper {
  static standardizePoly(geoJson) {
    const polygons = geoJson.features.map(feature => {
      const { geometry, properties } = feature;
      const { type, coordinates } = geometry;
      const isMultiPolygon = type.toLowerCase() === 'multipolygon';

      if (isMultiPolygon) {
        return coordinates.map(coordinate => {
          return this.buildPolygon(coordinate, properties);
        });
      }

      return [this.buildPolygon(coordinates, properties)];
    });

    // Flatten
    return polygons.reduce((acc, val) => acc.concat(val), []);
  }

  static standardPolyToFeatureCollection(polygons) {
    const features = polygons.map(polygon => {
      return this.buildFeature(polygon);
    });

    return {
      type: 'FeatureCollection',
      features: features
    };
  }

  static buildPolygon(coordinates, properties) {
    const cleanedCoordinates = this.cleanCoordinatePairs(coordinates);

    return {
      type: 'Polygon',
      coordinates: cleanedCoordinates,
      properties: properties
    };
  }

  // Some files provide coordinates as [X, X, NaN]
  // This is invalid when used with mapbox-gl-draw. Coordinates should only have two values.
  static cleanCoordinatePairs(coordinates) {
    return coordinates.map(polygonCoords => {
      return polygonCoords.map(coords => {
        return coords.slice(0, 2);
      });
    });
  }

  static buildFeature(polygon) {
    return {
      type: 'Feature',
      geometry: {
        type: polygon.type,
        coordinates: polygon.coordinates,
      },
      properties: polygon.properties
    }
  }
}
