import FieldService from '../services/v1/FieldService';
import { ErrorMessages } from '../constants/errorMessages';

export default class FieldBulkUploader {
  static async bulkCreateNewFields(fieldsGeoJson, accountID) {
    const uploadFieldPromises = fieldsGeoJson.fields.map(field => {
      return FieldBulkUploader.createNewField(accountID, field);
    });

    await Promise.all(uploadFieldPromises);
    return fieldsGeoJson;
  }

  static async createNewField(accountID, fieldGeoJson) {
    const fieldModel = {
      accountID: accountID,
      displayName: fieldGeoJson.fieldName,
      fieldData: fieldGeoJson.dataJson
    };

    try {
      await FieldService.createField(fieldModel);
    } catch (e) {
      throw new Error(ErrorMessages.Upload);
    }
  }
}