import axios from 'axios';
export const BASE_URL_v1 = '/v1/account';

export default {
  verify: async () => {
    const response = await axios.post(`${BASE_URL_v1}/verify`);

    return response.data;
  },
  getNewIotToken: async accountId => {
    const response = await axios.get(`${BASE_URL_v1}/getNewIotToken/${accountId}`);

    return response.data;
  }
}