// utilities
import commandCreatorsHelper from './commandCreatorsHelper';

/**
 * Formats the 'ui_ping' command
 * @return {Array} index 0 is the command object to send through mqtt and
 * index 1 is the object that contains the action types for each command response
 */
export function middlewarePing() {
    var commandObj = commandCreatorsHelper.createCommandStructure('ui_ping', {});
    return commandObj;
}