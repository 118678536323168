import signUrl from 'aws-device-gateway-signed-url'
import {guid} from './guid';
import commManagerConstants from './commManagerConstants';
import timeUtil from './timeUtil';
import mqttConstants from './mqttConstants';

export const generateSignedUrl = (config) => {
    let signedURL = '';
    signedURL = signUrl(config.params);
    console.log("signed mqtt url config.params:", config.params);
    const sessionParam = '&X-Amz-Security-Token=' + encodeURIComponent(config.sessionToken);
    // need session token param added when using temporary tokens (AWS STS)
    return signedURL + sessionParam;
}

export const generateNewConfig = (params) => {
    // parameters to connect to AWS IoT
    var newConfig = {
        params: {
            regionName: mqttConstants.REGION_NAME,
            endpoint: mqttConstants.ENDPOINT,
            secretKey: params.secretKey,
            accessKey: params.accessKey
        },
        sessionToken: params.sessionToken,
        tokenExpirationTimestamp: Date.parse(params.expiration),
    }

    return newConfig;
}

export const hasTokenExpired = (currentTimestamp, tokenExpirationTimestamp) => {
    console.log(`hasTokenExpired ${currentTimestamp} - ${tokenExpirationTimestamp}`, currentTimestamp - tokenExpirationTimestamp);
    return currentTimestamp - tokenExpirationTimestamp >= 0;
}

export const transformUrl = (getNewIotTokenCallback, url, options, client) => {
    console.log('*** transformWsUrl ***');
    var currentTimestamp = timeUtil.getCurrentUtcTimestamp();
    var config = generateNewConfig(client.options.properties.userProperties.iotToken);
    
    if (hasTokenExpired(currentTimestamp, config.tokenExpirationTimestamp)) {
        console.log('*** token has expired ***', config.tokenExpirationTimestamp);
        getNewIotTokenCallback();
        console.log('*** transformWsUrl *** options', client.options);
        config = generateNewConfig(client.options.properties.userProperties.iotToken);
    }

    var newSignedUrl = generateSignedUrl(config);
    console.log('*** transformWsUrl signed url ***');
    console.log(newSignedUrl);
    return newSignedUrl;
}

const cloudMqttFactory = (iotToken, getNewIotTokenCallback) => {
    console.log('tokens')
    var config = generateNewConfig(iotToken);
    var signedURL = generateSignedUrl(config)
    // transformUrl.bind(this, config, getNewTokensCallback);
    console.log('*** cloud factory signed url ***');
    console.log(signedURL);
    const id = guid();
    var mqtt = {
        name: commManagerConstants.CLOUD_BROKER,
        host: signedURL,
        priority_broker: true,
        options: {
            queueQoSZero: false,
            qos: 0,
            clientId: id,
            resubscribe: false,
            properties: {
                userProperties: {
                    iotToken: iotToken
                }
            },
            transformWsUrl: transformUrl.bind(this, getNewIotTokenCallback)
        }
    };

    return mqtt;
  };

  export default cloudMqttFactory;