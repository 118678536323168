import React from 'react';
import { Provider, connect } from 'react-redux';
import configureStore, { history } from './stores/store';
import { Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import ContentContainer from './ContentContainer';
import SidebarView from './components/views/SidebarView';
import MapView from './components/views/MapView';
import MapToggleView from './components/views/MapToggleView';
import NotificationsView from './components/views/NotificationsView';
import MachinesView from './components/views/MachinesView';
import SettingsView from './components/views/SettingsView';
import ChooseMachineTypeView from './components/views/ChooseMachineTypeView';
import CreateMachineView from './components/views/CreateMachineView';
import MachineSettingsView from './components/views/MachineSettingsView';
import SetupOperationView from './components/views/SetupOperationView';
import OperationView from './components/views/OperationView';
import { initUserTokens, setCurrentAccounts } from './dux/account-management-dux';
import { setupAxios } from './helpers/axios-setup';
import { getTokens, setupAccountInfo} from './helpers/auth-helper';
import './styles/global.scss';
import 'mapbox-gl/src/css/mapbox-gl.css';
import 'react-toastify/dist/ReactToastify.css';
import { initDarkMode } from './dux/settings-dux';
import { ToastContainer, toast } from 'react-toastify';
import { Routes } from './constants/routes';

function getState(state) {
  return state;
}

function connectComponent(component) {
  return connect(getState)(component);
}

function connectComponentWithRef(component) {
  return connect(getState, null, null, { forwardRef: true })(component);
}

class Root extends React.Component {
  render() {
    const url_string = window.location.href;
    const url = new URL(url_string);

    setupAccountInfo(url)

    let store = configureStore();

    store.dispatch(initUserTokens());
    store.dispatch(initDarkMode());

    const tokens = getTokens();

    setupAxios(store.dispatch, tokens);
    store.dispatch(setCurrentAccounts());
    const ConnectedMapView = connectComponentWithRef(MapView);
    const ConnectedSidebar = connectComponent(SidebarView);
    const ConnectedOperationView = connectComponent(OperationView);

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App history={history}>
            {/* Main toast container (bottom center) */}
            <ToastContainer
              enableMultiContainer
              containerId={'main'}
              autoClose={5000}
              position={toast.POSITION.BOTTOM_CENTER}
              hideProgressBar />

            {/* Map toast container (top center, wider) */}
            <ToastContainer
              className={'map-toast-container'}
              enableMultiContainer
              containerId={'map'}
              autoClose={5000}
              position={toast.POSITION.TOP_CENTER}
              hideProgressBar />

            <ContentContainer>
              <Route
                path={[Routes.Home, Routes.FieldSettings(), Routes.FieldCreateModify]}
                render={props => <ConnectedSidebar {...props} mapRef={this.mapRef} />}
                exact
              />

              <MapToggleView>
                <ConnectedMapView
                  ref={ref => this.mapRef = ref}
                  history={history}
                />
              </MapToggleView>

              <Route
                path={Routes.Notifications}
                component={connectComponent(NotificationsView)}
                exact
              />
              <Route
                path={Routes.Machines}
                component={connectComponent(MachinesView)}
                exact
              />
              <Route
                path={Routes.Settings}
                component={connectComponent(SettingsView)}
                exact
              />
              <Route
                path={Routes.MachineType}
                component={connectComponent(ChooseMachineTypeView)}
                exact
              />
              <Route
                path={Routes.MachineCreate}
                component={connectComponent(CreateMachineView)}
                exact
              />
              <Route
                path={Routes.MachineSettings()}
                component={connectComponent(MachineSettingsView)}
                exact
              />
              <Route
                path={Routes.FieldSetupOperation}
                component={connectComponent(SetupOperationView)}
                exact
              />
              <Route
                path={Routes.Operation}
                render={props => <ConnectedOperationView {...props} mapRef={this.mapRef} />}
                exact
              />
            </ContentContainer>
          </App>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default Root;