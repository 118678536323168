// Use style-optimized vector tilesets: https://docs.mapbox.com/help/troubleshooting/mapbox-gl-js-performance/#remove-unused-features
export const MapStyle = 'mapbox://styles/smartag/cjoq82rph5y092snzuq2zhvsq?optimize=true';
export const FieldsSource = 'fields';
export const DrawEventTypes = {
  Create: 'draw.create',
  Update: 'draw.update',
  Delete: 'draw.delete',
  SelectionChange: 'draw.selectionchange',
  ModeChange: 'draw.modechange',
  Render: 'draw.render'
};
export const MapToastContainerId = 'map';

// The feature-state dependent fill-opacity expression will render the hover effect
// when a feature's hover state is set to true.
export const FieldsFillLayer = {
  id: 'field-fills',
  type: 'fill',
  source: FieldsSource,
  layout: {},
  paint: {
    'fill-color': '#ffffff',
    'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false],
      1, // hovered opacity
      0.5 // normal opacity
    ]
  }
};

export const FieldsBorderLayer = {
  id: 'field-borders',
  type: 'line',
  source: FieldsSource,
  layout: {},
  paint: {
    'line-color': '#ffffff',
    'line-width': 2
  }
};

export const MachinesMapData = {
  SourceId: 'machines',
  Layers: [{
    'id': 'machine-icons',
    'type': 'symbol',
    'source': 'machines',
    'layout': {
      'icon-image': ['get', 'iconImage'],
      'icon-rotate': ['get', 'bearing'],
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-size': ['get', 'iconSize'],
      'visibility': 'visible'
    }
  }, {
    id: 'secondary-combine-labels',
    type: 'symbol',
    source: 'machines',
    'layout': {
      'text-size': 15,
      'text-field': ['get', 'label'],
      'text-justify': 'center',
      'visibility': 'visible',
      'text-ignore-placement': true,
      'text-keep-upright': false,
      'text-rotate': ['get', 'bearing'],
      // Center the number with the icon
      'text-offset': [-0.28, 0.6],
    },
    paint: {
      'text-halo-width': 1,
      'text-halo-color': '#fff'
    }
  }]
};

export const MapHintToastSettings = {
  autoClose: 10000,
  containerId: MapToastContainerId
};

export const MapHints = {
  StartDrawing: 'Tap on the map to begin drawing. Finish drawing by tapping on an existing point.',
  StartEditingOuterBoundaries: 'Drag points to modify field boundaries. Tap between two points to create a midpoint.',
  StartDeleting: 'Draw a polygon around points you wish to delete. Once you complete the polygon, the points will be deleted.',
  OuterBoundaryOnly: 'Finish creating the field by tapping "Save & Exit" before adding inner boundaries.',
};

export const VertexStyleProperties = {
  Radius: 'circle-radius',
  Color: 'circle-color',
};

export const VertexStyleIDs = {
  UnselectedOutline: 'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
  UnselectedFill: 'gl-draw-polygon-and-line-vertex-inactive.cold',
  SelectedOutline: 'gl-draw-point-stroke-active.cold',
  SelectedFill: 'gl-draw-point-active.cold'
};

export const SelectedVertexStyle = {
  SelectedFill: '#fff',
  SelectedOutline: '#fbb03b'
};

export const DrawnFeatureProperties = {
  InvalidInnerBoundary: 'isInvalidInnerBoundary',
  DrawId: 'drawId'
};