// Open Layers layers / interactions
export const olLayerTypes = {
	INVISIBLE: "invisible",
	MACHINERY: "machinery",
	FIELD_PINS: "fieldPins",
	MACHINE_TRAVEL_PATH: "machineTravelPath",
	FIELD: "vector",
	ADD_FIELD: "addField",
	INNER_BOUNDARY: "innerBoundary",
	SELECT_INNER_BOUNDARY: "selectInnerBoundary",
	COVERAGE_MAP: "coverageMap",
    SELECT_VERTICES: "selectVertices",
    SYNC_INTERCEPT: "syncIntercept",
    COMBINE_BUBBLES: "COMBINE_BUBBLES"
}

export const olGroupTypes = {
	PINS: "PINS",
	FIELDS: "FIELDS",
	BOUNDARIES: "BOUNDARIES"
}

export const olInteractionTypes = {
	MODIFY: "modify",
	MODIFY_INNER_BOUNDARY: "modifyInnerBoundary",
	SELECT: "select",
	DRAG: "drag",
	DRAW_ROUTE: "drawRoute",
	DRAW_POLYGON: "drawPoly",
	PLACE_PIN: "placePin",
	DRAW_INNER_BOUNDARY: "drawInnerBoundary",
	SELECT_INNER_BOUNDARY: "selectInnerBoundary",
	SELECT_VERTICES: "selectVertices",
	SNAP: "snap",
}