import React from 'react';
import '../styles/switch.scss';

class Switch extends React.Component {
  render() {
    return (
      <label className="switch">
        <input type="checkbox" checked={this.props.checked} onChange={this.props.onChange}/>
        <span className="slider"></span>
      </label>
    );
  }
}
export default Switch;