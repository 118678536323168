import actionTypes from '../actions/actionTypes';


export function updateCameraFeedAction(dispatch, img_url) {
    var camera_index = 0;
    var action = {
        type: actionTypes.CAMERA_FEEDS_RECIEVED_NEW_IMG,
        payload: {
            img: img_url,
            camera_index: camera_index
        }
    };

    dispatch(action);
}

export function startCameraFeedAction() {
    return {
        type: actionTypes.CAMERA_FEEDS_START,
        payload: {}
    };
}

export function stopCameraFeedAction() {
    return {
        type: actionTypes.CAMERA_FEEDS_STOP,
        payload: {}
    };
}