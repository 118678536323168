// modules
import turfKinks from '@turf/kinks';

// utilities
import * as mapHelper from './mapHelper';

export default class boundaryValidationHelper {
    static isValidField(fieldFeatureObject, currentInnerBoundaryFeatureObjects) {
        // check if field contains a kink
        if (mapHelper.doesBoundaryContainKink(fieldFeatureObject.geometry)) {
            return false;
        } else {
            // check all new/modified inner boundaries against the field and other boundaries for validation
            for (var i = 0; i < currentInnerBoundaryFeatureObjects.length; i++) {
                if (mapHelper.doesBoundaryContainKink(currentInnerBoundaryFeatureObjects[i].geometry)) {
                    return false;
                } else if (!mapHelper.isBoundaryWithinField(currentInnerBoundaryFeatureObjects[i], fieldFeatureObject)) {
                    return false;
                } else if (mapHelper.isBoundaryOverlappingOtherBoundaries(currentInnerBoundaryFeatureObjects[i], currentInnerBoundaryFeatureObjects, i)) {
                    // check overlapping current inner boundaries
                    // check overlapping other new inner boundaries
                    return false;
                }
            }
        }
        return true;
    }
    
    static isValidInnerBoundary(innerBoundaryFeatureObjectToCheck, newInnerBoundaryFeatureObjects, fieldFeatureObject, ignoreIndex) {
        // action to update the state that the field data is being saved
        var validInnerBoundary = true;
        // fieldPolygons
        // check all new/modified inner boundaries against the field and other boundaries for validation
        for (var i=0; i<newInnerBoundaryFeatureObjects.length; i++) {
            // check contains kink
            if (mapHelper.doesBoundaryContainKink(innerBoundaryFeatureObjectToCheck)) {
                return false;
            } else if (!mapHelper.isBoundaryWithinField(innerBoundaryFeatureObjectToCheck, fieldFeatureObject)) { // check if inner boundary is within the field boundary
                return false;
                break;
            } else if (mapHelper.isBoundaryOverlappingOtherBoundaries(innerBoundaryFeatureObjectToCheck, newInnerBoundaryFeatureObjects, ignoreIndex)) {
                // check overlapping current inner boundaries
                // check overlapping other new inner boundaries
                return false;
                break;
            } 
        }
        return true;
    }

    // Takes an array of coordinates and creates a linestring between each coordinate
    // and checks if any cross each other
    static doCoordinatesCross(coordinates) {
        var lineString = {
            type: 'LineString',
            coordinates: coordinates
        }
        // if there number of kinks is greater than 0, then the line crosses itself
        var kinks = turfKinks(lineString);
        var result = kinks.features.length > 0;
        return result;
    }
}
