import React from 'react';
import { connect } from 'react-redux';

export class MapToggleView extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.isOperationRunning !== nextProps.isOperationRunning) return true;
  }

  render() {
    if (this.props.isOperationRunning) {
      return null;
    }

    return this.props.children;
  }
}

const mapStateToProps = state => {
  return {
    isOperationRunning: state.operation.isRunning
  };
}

export default connect(mapStateToProps)(MapToggleView);
