export const Routes = {
  Home: '/',
  Machines: '/machines',
  Settings: '/settings',
  Notifications: '/notifications',
  FieldSettings: id => getFieldSettingsRoute(id),
  FieldSetupOperation: '/field/setup',
  FieldCreateModify: '/field/modify',
  MachineType: '/machine/type',
  MachineCreate: '/machine/create',
  MachineSettings: id => getMachineSettingsRoute(id),
  Operation: '/operation',
};

export const getFieldSettingsRoute = id => {
  return id ? `/field/${id}/settings` : '/field/:id/settings';
};

export const getMachineSettingsRoute = id => {
  return id ? `/machine/${id}/settings` : '/machine/:id/settings';
};