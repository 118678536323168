import axios from 'axios';
export const BASE_URL_v1 = '/v1/machine';

export default {
  getMachineList: async accountId => {
    const response = await axios.get(`${BASE_URL_v1}/list/${accountId}`);

    // TODO: Change API to do this?
    if (response.data.length === 0) {
      return {
        data: []
      };
    }

    const { info, machineIDs } = response.data.machinesList;
    const machineData = {
      data: machineIDs.map(id => info[id])
    };

    return machineData;
  },
  createMachine: async machineDataModel => {
    const response = await axios.post(BASE_URL_v1, machineDataModel);
    const { machineData } = response.data;

    return machineData;
  },
  updateSyncPoint: async (machineId, syncPoint) => {
    const response = await axios.post(`${BASE_URL_v1}/syncpoint/${machineId}`, syncPoint);

    return response;
  },
  deleteMachine: async machineId => {
    const response = await axios.delete(`${BASE_URL_v1}/${machineId}`);
    const { success } = response.data;

    return success;
  },
  updateMachine: async updateMachineModel => {
    const response = await axios.put(`${BASE_URL_v1}/update`, updateMachineModel);
    const { success } = response.data;

    return success;
  }
}