// modules
import { cloneDeep } from 'lodash';

// utilities
import actionTypes from '../actions/actionTypes';

// models
import secondaryCombineModels from '../models/secondaryCombineModels';

var initState = new secondaryCombineModels();

export default function secondaryCombine(state=initState, action) {
	switch (action.type) {
		case actionTypes.UPDATE_SECONDARY_COMBINE_STATE:
			var newState = cloneDeep(state);
			newState.upsertCombine(action.payload.model);
			return newState;
		default:
			return state;
	}
}
