import { getLocalItem, setLocalItemAsObject, getTokenOrDefault } from '../helpers/local-storage';
import { LocalStorageKeys } from '../constants/localStorageKeys';
import AccountService from '../services/v1/AccountService';
import AccountServiceV2 from '../services/v2/AccountServiceV2';
import { ErrorMessages } from '../constants/errorMessages';
import { showDefaultErrorToastNonClosing } from '../helpers/toast-helper';
import LogService from '../services/v1/LogService';
import { setNewIotTokenSuccess } from './iot-token-dux';
import { removeTokensFromStorage, removeTokensFromCookies } from '../helpers/auth-helper';

export const mountPoint = 'accountManagement';
export const actions = {
  CLEAR_USER_TOKENS: 'accountManagement/CLEAR_USER_TOKENS',
  INIT_USER_TOKENS: 'accountManagement/INIT_USER_TOKENS',
  SET_CURRENT_ACCOUNTS: 'accountManagement/SET_CURRENT_ACCOUNTS',
  SET_ACTIVE_ACCOUNT: 'accountManagement/SET_ACTIVE_ACCOUNT',
  SET_IOT_TOKEN_FOR_ACCOUNT: 'accountManagement/SET_IOT_TOKEN_FOR_ACCOUNT',
  SET_CURRENT_ACCOUNTS_ERROR: 'accountManagement/SET_CURRENT_ACCOUNTS_ERROR',
};

export const getAccountFromCompanyId = (accounts, companyId) => {
  let selectedAccount = null;

  for (const account of accounts) {

    if (account.company_id === companyId) {
      selectedAccount = account;
      break;
    }
  }
  return selectedAccount;
}

export const verifySuccessGetSelectedSlingshotAccount = (accounts, storedAccountInfo) => {
  let selectedAccount = null;

  // check that the scid (Selected Company ID) is set
  if (storedAccountInfo.scid) {
    // if scid is set, parse out the account with that id
    selectedAccount = getAccountFromCompanyId(accounts, parseInt(storedAccountInfo.scid));

    if (!selectedAccount) {
      console.error(`Company ID ${storedAccountInfo.scid}, was not found in the list of authorized accounts`);
      // We will set it to the first result since there is a chance
      // a user logs into slingshot -> goes to aavi -> has access to account x
      // then the next time the user goes to aavi, account x is no longer accessible to them.
      // We need to allow the scid saved in local storage to be updated
      selectedAccount = accounts[0];
    }
  } else {
    // No scid set, likely will happen if the user has never been to aavi
    // and had not been to apps.ravenslingshot.com to set the scid
    selectedAccount = accounts[0];
  }

  return selectedAccount;
}

export const clearUserTokens = () => {
  return dispatch => {
    removeTokensFromStorage();
    removeTokensFromCookies();
    dispatch({
      type: actions.CLEAR_USER_TOKENS
    });
  }
}

export const initUserTokens = () => {
  return dispatch => {
    dispatch({
      type: actions.INIT_USER_TOKENS
    });
  }
}

export const setCurrentAccounts = () => {
  return async (dispatch, getState) => {
    try {
      let result = null;

      let selectedAccount = null;
      const account_info = getLocalItem(LocalStorageKeys.AccountInfo);

      console.log('account_info:', account_info);
      console.log('account_info.jwt:', account_info.jwt);

      // Get slingshot account
      if (account_info.jwt) {
        console.log('Calling v2 auth');
        result = await AccountServiceV2.verify(account_info);
        selectedAccount = verifySuccessGetSelectedSlingshotAccount(result.data, account_info);
      } else { // Get smartag account
        console.log('Calling v1 auth');
        result = await AccountService.verify();
        // With cognito, there will always only be 1 account
        selectedAccount = result.data[0];
      }

      const accountInfo = {
        accounts: result.data,
        activeAccount: selectedAccount
      };
      const updatedLocalStorage = {
        ...getLocalItem(LocalStorageKeys.AccountInfo),
        ...accountInfo
      };

      // Update in local storage
      setLocalItemAsObject(LocalStorageKeys.AccountInfo, updatedLocalStorage);
      // update the account details
      dispatch({
        type: actions.SET_CURRENT_ACCOUNTS,
        data: accountInfo
      });

      // update the iot tokens to use
      dispatch(setNewIotTokenSuccess(accountInfo.activeAccount.iotToken));
    } catch (error) {
      // Show error toast that stays open
      showDefaultErrorToastNonClosing(ErrorMessages.SetCurrentAccount, {
        autoClose: false
      });
      dispatch({
        type: actions.SET_CURRENT_ACCOUNTS_ERROR,
        error: ErrorMessages.SetCurrentAccount
      });
    }
  }
}


export const setIotTokenForAccount = iotToken => {
  return {
    type: actions.SET_IOT_TOKEN_FOR_ACCOUNT,
    iotToken
  }
}

export const setActiveAccount = (accounts, selectedCompanyId) => {
  return dispatch => {
    const selectedAccount = getAccountFromCompanyId(accounts, parseInt(selectedCompanyId));

    dispatch({
      type: actions.SET_ACTIVE_ACCOUNT,
      activeAccount: selectedAccount
    });

    dispatch(setNewIotTokenSuccess(selectedAccount.iotToken));
  }
}

export const logError = (error, optionalDetails = null) => {
  return (dispatch, getState) => {
    const { accountManagement } = getState();

    console.log('in log error ****', getState())
    const stack = error.stack ? '\nStack: ' + error.stack : '';
    const details = optionalDetails ? '\nDetails: ' + optionalDetails : '';
    const logMessage = 'Message: ' + error.message + stack + details;

    LogService.createLog(accountManagement.activeAccount.company_id, logMessage);
  };
}

// we don't save 'scid' (Selected Company ID) from slingshot in the account management state
// because it will already be tracked by the activeAccount property, which contains company_id
const initialState = {
  accessToken: '',
  idToken: '',
  refreshToken: '',
  jwt: '',
  accounts: [],
  activeAccount: {},
  error: undefined
};

export function reducer(state = initialState, action) {
  switch (action.type) {
  case actions.INIT_USER_TOKENS:
    return {
      ...state,
      idToken: getTokenOrDefault('idToken'),
      accessToken: getTokenOrDefault('accessToken'),
      refreshToken: getTokenOrDefault('refreshToken'),
      jwt: getTokenOrDefault('jwt')
    }
  case actions.CLEAR_USER_TOKENS:
    return {
      ...state,
      idToken: '',
      accessToken: '',
      refreshToken: '',
      jwt: ''
    }
  case actions.SET_CURRENT_ACCOUNTS:
    return {
      ...state,
      ...action.data
    }
  case actions.SET_ACTIVE_ACCOUNT:
    return {
      ...state,
      activeAccount: action.activeAccount
    }
  case actions.SET_IOT_TOKEN_FOR_ACCOUNT: {
    // slice returns new object
    let clonedAccounts = state.accounts.slice();
    const accountIndex = state.accounts.indexOf(state.activeAccount);

    clonedAccounts[accountIndex].iotToken = action.iotToken;

    return {
      ...state,
      activeAccount: {
        iotToken: action.iotToken
      },
      accounts: clonedAccounts
    }
  }
  case actions.SET_CURRENT_ACCOUNTS_ERROR:
    return {
      ...state,
      error: action.error
    }
  default:
    return state;
  }
}