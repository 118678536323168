
import wifiMqttFactory from './wifiMqttFactory';
import radioMqttFactory from './radioMqttFactory';
import cloudMqttFactory from './cloudMqttFactory';
import mqttConstants from './mqttConstants';

export default class brokerSelector{

    static get_mqtts = (brokers, ip, iotToken, getNewIotTokenCb) => {
        console.log(`WORKER ip: `, ip);
        var mqtts = [];
        for(var i =0; i < brokers.length; i++){
            var broker_name = brokers[i]
            
            if(broker_name === mqttConstants.WIFI_BROKER){

                var wifiMqtt = wifiMqttFactory(ip);
                mqtts.push(wifiMqtt);
            }
            else if(broker_name === mqttConstants.RADIO_BROKER){
                var radioMqtt = radioMqttFactory();
                mqtts.push(radioMqtt);
            }
            else if(broker_name === mqttConstants.CLOUD_BROKER){
                const cloudMqtt = cloudMqttFactory(iotToken, getNewIotTokenCb);
                mqtts.push(cloudMqtt);
            }
            else{
                throw new Error(`Broker: ${broker_name} was not a valid option.`)
            }
        }

        return mqtts;
    }
}