// utilities
import actionTypes from './actionTypes';

const VALID_FALLBACK_GEOJSON = {
  coordinates: [[]],
  type: 'Polygon'
};

export function updateCombineBubblePathPlanner(geoJsonGeometry) {
  // Ensure we provide valid geojson.
  const data = geoJsonGeometry && geoJsonGeometry.coordinates && geoJsonGeometry.coordinates.length ? geoJsonGeometry : VALID_FALLBACK_GEOJSON;
  const action = {
    type: actionTypes.UPDATE_COMBINE_BUBBLES_PATH_PLANNER,
    payload: {
      geoJson: {
        type: 'Feature',
        geometry: {
          ...data
        },
        properties: {
          sourceType: 'path-planner'
        }
      }
    }
  }

  return action;
}

export function updateCombineBubblesDisplay(display) {
  const action = {
    type: actionTypes.UPDATE_COMBINE_BUBBLES_DISPLAY,
    payload: {
      display
    }
  }

  return action;
}