import React from 'react';
import * as MapboxGL from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import '../../styles/map-view.scss';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  createFieldsGeoJsonSource, createMachinesGeoJsonSource, createSecondaryCombineFeature,
  isValidInnerBoundary, initDrawModes, validatePolygonHasNoKinks, getSelectedFieldFeature,
  getActualFieldBoundaries, cleanPolygonCoordinates,
  removeDeletedCoordinates, validateField, getIDsOfInnerBoundariesOutOfBounds,
  customizeDrawStyles, getDrawnFeatures
} from '../../helpers/map-helper';
import {
  showMapErrorToastAutoClosing, showMapHintToastAutoClosing,
  showMapErrorToastNonClosing, dismissToast, dismissAllToasts,
  dismissIrrelevantMapToasts
} from '../../helpers/toast-helper';
import {
  MapStyle, FieldsFillLayer, FieldsBorderLayer, MachinesMapData,
  FieldsSource, DrawEventTypes, MapHints, DrawnFeatureProperties
} from '../../constants/mapConstants';
import { setSelectedField } from '../../dux/field-dux';
import { ErrorMessages } from '../../constants/errorMessages';
import clone from '@turf/clone';
import intersect from '@turf/intersect';
import FieldService from '../../services/v1/FieldService';
import { polygon } from '@turf/helpers';
import { Routes } from '../../constants/routes';
import { MachineTypeIcons } from '../../constants/machineType';
import conversionUtil from '../../SmartAgUI-Common/conversionUtil';
import { logError } from '../../dux/account-management-dux';
import { toggleMapShouldResize } from '../../dux/operation-dux';

MapboxGL.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

class MapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      draw: null,
      hoveredFieldId: null,
      fieldsSource: null,
      isDrawing: false,
      isCreating: false,
      isDeleting: false,
      showDeleteHint: true,
      hasModifiedField: false,
      mapStyleLoaded: false,
      mapInitialized: false,
      windowHasResized: false,
      headerElement: null
    };
    this.updateMachines = true;
  }

  componentDidMount() {
    const map = new MapboxGL.Map({
      container: 'map',
      style: MapStyle,
      center: [-100, 40],
      zoom: 8,
      minzoom: 7,
      maxzoom: 12
    });

    map.once('styledata', () => {
      this.setState({ mapStyleLoaded: true });
    });

    // Any Mapbox errors will now be logged to CloudWatch
    map.on('error', data => {
      console.error('Mapbox error:\n', data);
      this.props.dispatch(logError(data.error));
    });

    const headerElement = document.getElementById('header');

    this.setState({ map, headerElement });

    // Tapping the header when in direct select mode will put user back into simple select mode.
    // Ensures user can't get stuck when zoomed in too closely.
    headerElement.addEventListener('click', this.switchToSimpleSelectMode);

    // Track window resize events so that we can trigger map resize when we show it again.
    window.addEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    this.setState({ windowHasResized: true });
  }

  switchToSimpleSelectMode = () => {
    const isEditingField = this.props.router.location.pathname === Routes.FieldCreateModify;
    const isInDirectSelectMode = this.state.draw && this.state.draw.getMode() === this.state.draw.modes.DIRECT_SELECT;

    if (isEditingField && isInDirectSelectMode) {
      this.state.draw.changeMode(this.state.draw.modes.SIMPLE_SELECT);
    }
  }

  initializeMap = async () => {
    const fieldsSource = createFieldsGeoJsonSource(this.props.field.fieldList);

    this.state.map.addSource(FieldsSource, fieldsSource);
    this.state.map.addLayer(FieldsFillLayer);
    this.state.map.addLayer(FieldsBorderLayer);
    this.addMachinesToMap();

    await this.setState({ fieldsSource });
    // Since we are launching from here, we want to zoom to all fields
    this.setupFieldsListMapView(true);
    this.state.map.on('mousemove', FieldsFillLayer.id, this.onMouseMove);
    this.state.map.on('mouseleave', FieldsFillLayer.id, this.onMouseLeave);
    this.state.map.on('click', this.onMapClick);
    this.state.map.on(DrawEventTypes.Create, this.handleCreate);
    this.state.map.on(DrawEventTypes.Update, this.handleUpdate);
    this.state.map.on(DrawEventTypes.ModeChange, this.handleDrawModeChange);
    this.setState({ mapInitialized: true });
  }

  // Map should only update if:
  // - map style gets loaded
  // - fieldList is initialized
  // - webState was provided (from Header) which tells us we need to clean up the map
  // - map needs to change visibility (be hidden or shown)
  // - sidebar route changes
  shouldComponentUpdate(nextProps, nextState) {
    // Map style gets loaded
    if (!this.state.mapStyleLoaded && nextState.mapStyleLoaded) return true;
    // fieldList has changed
    if (!this.props.field.fieldList && nextProps.field.fieldList) return true;

    const oldPath = this.props.router.location.pathname;
    const newPath = nextProps.router.location.pathname;

    // webState is provided from Header
    if (!this.props.router.location.state && nextProps.router.location.state) return true;

    // Does not need to update if the path has not changed
    if (oldPath === newPath) return false;

    // Check if map visibility needs to be changed
    const wasShowingMap = this.isMapRoute(oldPath);
    const wantToShowMap = this.isMapRoute(newPath);
    // Need to hide the map (e.g. going from fields to machines)
    const needToHideVisibleMap = wasShowingMap && !wantToShowMap;
    // Need to show the map (e.g. going from machines to fields)
    const needToShowHiddenMap = !wasShowingMap && wantToShowMap;
    const needToUpdateMapVisibility = needToShowHiddenMap || needToHideVisibleMap;
    // Sidebar is changing (map is still showing e.g. field list to field settings to modify field)
    const sidebarChanged = wasShowingMap && wantToShowMap;

    if (needToUpdateMapVisibility) return true;
    if (sidebarChanged) return true;
    return false;
  }

  componentDidUpdate(prevProps) {
    // Map has not been initialized. Style is loaded, and fieldList is ready. Need to init map - this should only happen once!
    if (!this.state.mapInitialized && this.state.mapStyleLoaded && this.props.field.fieldList) {
      this.initializeMap();
      return;
    }

    if (this.state.mapInitialized && prevProps.field.fieldList != this.props.field.fieldList) {

      if (!this.props.field.fieldList) {
        // fieldList is temporarily undefined, clear the fields
        this.refreshMapSourceFromFieldList([]);
      } else { // fields were just loaded, add them to source
        this.refreshMapSourceFromFieldList(this.props.field.fieldList);
      }
    }

    const path = this.props.router.location.pathname;
    const oldPath = prevProps.router.location.pathname;
    const selectedField = this.props.field.selectedField;
    const webState = this.props.router.location.state;
    const wasShowingMap = this.isMapRoute(oldPath);
    const wantToShowMap = this.isMapRoute(path);
    const needToShowHiddenMap = !wasShowingMap && wantToShowMap;
    const mapIsShownAfterBeingRemounted = this.props.operation.mapShouldResize && this.isMapRoute(path);

    // Need to tell the map to resize since it was hidden (mapbox handles resize automatically when not hidden)
    if ((needToShowHiddenMap && this.state.windowHasResized) || mapIsShownAfterBeingRemounted) {
      this.state.map.resize();
      this.props.dispatch(toggleMapShouldResize(false));
      this.setState({ windowHasResized: false });
    }

    if (webState && webState.reset && selectedField) {
      // Reset the selected field
      this.resetFieldInSource(selectedField.id, selectedField.data.features[0]);
    }

    if (path === Routes.Home) {
      this.setupFieldsListMapView(false);
    } else if (this.isFieldSettingsRoute(path)) {
      this.setupFieldSettingsMapView(selectedField);
    } else if (path === Routes.FieldCreateModify) {
      // If we have a selected field, we know we're modifying
      if (selectedField) {
        this.setupFieldModifyMapView(selectedField);
      } else {
        this.setupFieldCreateMapView();
      }
    }
  }

  componentWillUnmount() {
    // Since we are unmounting, we know we need to resize the map next time it's mounted & shown.
    this.props.dispatch(toggleMapShouldResize(true));
    dismissAllToasts();
    this.updateMachines = false;
    cancelAnimationFrame(this.animateMachineIcons);
    window.removeEventListener('resize', this.handleWindowResize);
    this.state.headerElement.removeEventListener('click', this.switchToSimpleSelectMode);
    this.state.map.remove();
  }

  addMachinesToMap = () => {
    const machinesSource = createMachinesGeoJsonSource();
    const combineAsset = require('../../assets/CombineIconAugerLeft.png');
    const tractorAsset = require('../../assets/TractorIcon.png');
    const combineSecondaryAsset = require('../../assets/CombineSecondary.png');

    // Add each icon to the map
    this.state.map.loadImage(combineAsset, (error, image) => {
      if (error) throw error;
      this.state.map.addImage(MachineTypeIcons.Combine, image);
    });
    this.state.map.loadImage(tractorAsset, (error, image) => {
      if (error) throw error;
      this.state.map.addImage(MachineTypeIcons.Tractor, image);
    });
    this.state.map.loadImage(combineSecondaryAsset, (error, image) => {
      if (error) throw error;
      this.state.map.addImage(MachineTypeIcons.CombineSecondary, image);
    });

    this.state.map.addSource(MachinesMapData.SourceId, machinesSource);
    MachinesMapData.Layers.forEach(layer => {
      this.state.map.addLayer(layer);
    });

    // Start machine animations
    this.animateMachineIcons();
  }

  animateMachineIcons = () => {
    if (!this.updateMachines) {
      // Skip update while draw is being added to the map.
      return requestAnimationFrame(this.animateMachineIcons);
    }

    const machinesSource = this.state.map.getSource(MachinesMapData.SourceId);
    const machinesData = machinesSource._data;
    const combineGps = this.props.combineInfo.machine.gps;
    const tractorGps = this.props.tractorInfo.machine.gps;

    // Combine is first feature
    machinesData.features[0].geometry.coordinates = [combineGps.lon, combineGps.lat];
    machinesData.features[0].properties.bearing = conversionUtil.radiansToDegrees(combineGps.heading);

    // Tractor is second feature
    machinesData.features[1].geometry.coordinates = [tractorGps.lon, tractorGps.lat];
    machinesData.features[1].properties.bearing = conversionUtil.radiansToDegrees(tractorGps.heading);

    // Remaining features are secondary combines
    const secondaryCombineFeatures = this.getSecondaryCombineFeatures(machinesData);

    machinesData.features = machinesData.features.slice(0, 2).concat(secondaryCombineFeatures);

    // Update machines map source
    machinesSource.setData(machinesData);
    requestAnimationFrame(this.animateMachineIcons);
  }

  getSecondaryCombineFeatures = machinesData => {
    let secondaryCombineFeatures = [];

    this.props.secondaryCombines.combines.forEach(secondaryCombine => {
      if (!secondaryCombine.isValid()) return;
      const label = secondaryCombine.combineName.split('combine')[1];
      const secondaryCombineCoordinates = [secondaryCombine.lon, secondaryCombine.lat];
      const secondaryCombineHeadingInDegrees = conversionUtil.radiansToDegrees(secondaryCombine.heading);
      const secondaryCombineFeature = machinesData.features.find(feature => feature.properties.id === secondaryCombine.id);

      if (secondaryCombineFeature) {
        secondaryCombineFeature.geometry.coordinates = secondaryCombineCoordinates;
        secondaryCombineFeature.properties.bearing = secondaryCombineHeadingInDegrees;
        secondaryCombineFeatures.push(secondaryCombineFeature);
      } else {
        const secondaryCombineFeature =
          createSecondaryCombineFeature(secondaryCombine.id, secondaryCombineCoordinates, secondaryCombineHeadingInDegrees,label);

        secondaryCombineFeatures.push(secondaryCombineFeature);
      }
    });

    return secondaryCombineFeatures;
  }

  handleDrawModeChange = event => {
    // Go to direct select mode instead of simple select mode
    if (event.mode === this.state.draw.modes.SIMPLE_SELECT) {
      const drawData = this.state.draw.getAll();

      // Can't do anything if they've deleted all features.
      if (!drawData.features.length) return;
      const firstInvalidInnerBoundary = drawData.features.find(feature => feature.properties.isInvalidInnerBoundary);
      const selectedFeatureCollection = this.state.draw.getSelected();
      const selectedFeature = selectedFeatureCollection.features.length > 0 ? selectedFeatureCollection.features[0] : null;

      let id;

      // We know an invalid inner boundary exists
      if (firstInvalidInnerBoundary) {
        // If the selected feature is an invalid IB, we want to keep that selected.
        if (selectedFeature && selectedFeature.properties.isInvalidInnerBoundary) {
          id = selectedFeature.id;
        } else {
          // Otherwise, we can just select the first invalid inner boundary
          id = firstInvalidInnerBoundary.id;
        }
      } else {
        // No invalid inner boundaries, so just select the field.
        id = drawData.features[0].id;
      }

      this.state.draw.changeMode(this.state.draw.modes.DIRECT_SELECT, { featureId: id });
    }
  }

  // MapView when FieldsListSidebar is showing
  // All fields should be visible. No drawing.
  setupFieldsListMapView = shouldZoomToAllFields => {
    if (shouldZoomToAllFields) {
      this.zoomToAllFields();
    } else {
      this.state.map.stop();
    }
    this.disableDrawing();
    this.setState({
      isDeleting: false,
      isCreating: false,
      isDrawing: false
    });
  }

  // MapView when FieldSettingsSidebar is showing
  // Selected field should be the only one visible, zoomed in. No drawing.
  setupFieldSettingsMapView = selectedField => {
    // Zoom to selected field and hide all other fields
    this.zoomToSelectedField(selectedField);
    // Set hover false because iPad will highlight on click
    this.setFieldHoverState(selectedField.id, false);
    this.disableDrawing();
    this.setState({
      isDeleting: false,
      isCreating: false,
      isDrawing: false
    });
  }

  // MapView when DrawToolsSidebar is showing (as Modify)
  // Selected field should be the only one visible, zoomed in. Drawing. Dragging is not allowed.
  setupFieldModifyMapView = selectedField => {
    // Stop updating machines.
    this.updateMachines = false;
    // Zoom to the selectedField in case they moved the map around when they were on FieldSettings
    this.zoomToSelectedField(selectedField);
    const selectedFieldFeature = getSelectedFieldFeature(this.state.fieldsSource, selectedField.id);

    let draw = new MapboxDraw({
      displayControlsDefault: false,
      modes: initDrawModes(selectedField.id),
      userProperties: true
    });

    draw.options.styles = customizeDrawStyles(draw.options.styles);
    this.state.map.addControl(draw);

    // Set hover false because iPad will highlight on click
    this.setFieldHoverState(selectedField.id, false);

    draw.set({
      type: 'FeatureCollection',
      features: [selectedFieldFeature]
    });

    this.state.map.once(DrawEventTypes.Render, () => {
      // Field is now added to draw, hide the field source since we only need to show draw right now.
      this.hideFieldSource(selectedFieldFeature.id);
      // Machines are now okay to update again.
      this.updateMachines = true;
    });

    // Start in direct select mode
    draw.changeMode(draw.modes.DIRECT_SELECT, { featureId: selectedFieldFeature.id });

    this.setState({
      isDrawing: true,
      draw,
      isCreating: false,
      isDeleting: false,
      showDeleteHint: true,
      hasModifiedField: false, // Reset flag that tells us they've made any edits to the field.
    });
  }

  // MapView when DrawToolsSidebar is showing (as Create)
  // Drawing.
  setupFieldCreateMapView = () => {
    // Stop updating machines.
    this.updateMachines = false;
    // Start in Draw Polygon mode, do not show controls.
    let draw = new MapboxDraw({
      displayControlsDefault: false,
      modes: initDrawModes(),
      defaultMode: 'draw_polygon'
    });

    draw.options.styles = customizeDrawStyles(draw.options.styles);

    this.state.map.addControl(draw);

    this.state.map.once(DrawEventTypes.Render, () => {
      // Machines are now okay to update again.
      this.updateMachines = true;
    });

    // Start fresh in case other things have been drawn before.
    draw.deleteAll();
    // For some reason, have to manually change to draw_polygon mode to be able to start drawing right away.
    draw.changeMode(draw.modes.DRAW_POLYGON);

    this.setState({
      isDrawing: true,
      draw,
      isCreating: true,
      isDeleting: false,
      showDeleteHint: true
    });

    // Show user hint
    showMapHintToastAutoClosing(MapHints.StartDrawing);
  }

  onMapClick = event => {
    if (this.state.isDrawing) return;

    const path = this.props.router.location.pathname;

    // Clicking on a field will take you to the next view in certain cases
    if (path === Routes.Home) {
      this.goToFieldSettings(event);
    } else if (this.isFieldSettingsRoute(path)) {
      this.goToModifyField(event);
    }
  }

  handleCreate = event => {
    dismissIrrelevantMapToasts(true, this.state.isCreating, this.state.isDeleting);

    // Delete mode means they just finished creating the delete polygon
    if (this.state.isDeleting) {
      this.deletePoints(event);
      return;
    }

    if (this.state.isCreating) {
      this.handleCreateInCreateMode(event);
    } else {
      this.handleCreateInModifyMode(event);
      // If we get here, we know they've made an update to the field. Set that flag in case they want to leave.
      this.setState({ hasModifiedField: true });
    }
  }

  // Called when a polygon is completed in create mode
  handleCreateInCreateMode = event => {
    const drawData = this.state.draw.getAll();

    if (drawData.features.length === 0) return;

    // First feature will always be fieldBoundariesPolygon since they are only allowed to draw a single polygon
    let fieldBoundariesPolygon = drawData.features[0];

    // Only need to check if it has kinks
    validatePolygonHasNoKinks(fieldBoundariesPolygon);

    // Set outerBoundary property
    this.state.draw.setFeatureProperty(fieldBoundariesPolygon.id, 'outerBoundary', true);

    // Show user hint to update boundaries
    showMapHintToastAutoClosing(MapHints.StartEditingOuterBoundaries);
  }

  // Called when a polygon is completed in modify mode.
  // Could be an inner boundary. Could also be field boundaries if they deleted them.
  handleCreateInModifyMode = event => {
    // Find the field boundaries polygon if it exists.
    let fieldBoundariesPolygon = this.getFieldBoundariesPolygon();

    if (!fieldBoundariesPolygon) {
      // Outer boundaries have been deleted - need to recreate fieldBoundariesPolygon
      fieldBoundariesPolygon = this.reinitializeFieldBoundariesPolygon();
    } else {
      this.state.draw.setFeatureProperty(fieldBoundariesPolygon.id, DrawnFeatureProperties.DrawId, fieldBoundariesPolygon.id);
    }

    validatePolygonHasNoKinks(fieldBoundariesPolygon);

    const thisPolygon = event.features[0];

    // We are creating the outer boundaries, no inner boundaries exist yet.
    if (thisPolygon.id === fieldBoundariesPolygon.properties.drawId) {
      // Set flag so that we know to enter direct select mode instead of simple select mode (handleDrawModeChange)
      return;
    }

    // Need to treat thisPolygon as an inner boundary.
    // First check if it's within fieldBoundariesPolygon.
    if (!isValidInnerBoundary(fieldBoundariesPolygon, thisPolygon)) {
      // Created an invalid inner boundary. Added as a separate feature with an isInvalidInnerBoundary property.
      // Show error. Will not create a duplicate toast, but will show again if they have already closed it previously.
      this.state.draw.setFeatureProperty(thisPolygon.id, DrawnFeatureProperties.InvalidInnerBoundary, true);
      showMapErrorToastNonClosing(ErrorMessages.InvalidInnerBoundary);
    } else {
      fieldBoundariesPolygon = this.addValidInnerBoundary(fieldBoundariesPolygon, thisPolygon);
    }

    // Now check if it has kinks.
    validatePolygonHasNoKinks(thisPolygon);
  }

  handleUpdate = event => {
    dismissIrrelevantMapToasts(false, this.state.isCreating, this.state.isDeleting);

    if (this.state.isCreating) {
      this.handleUpdateInCreateMode(event);
    } else {
      this.handleUpdateInModifyMode(event);
      this.selectInvalidInnerBoundaryIfExists();
      // If we get here, we know they've made an update to the field. Set that flag in case they want to leave.
      this.setState({ hasModifiedField: true });
    }
  }

  selectInvalidInnerBoundaryIfExists = () => {
    const drawData = this.state.draw.getAll();
    const firstInvalidInnerBoundary = drawData.features.find(feature => feature.properties.isInvalidInnerBoundary);

    if (!firstInvalidInnerBoundary) return;
    const selectedFeatureCollection = this.state.draw.getSelected();
    const selectedFeature = selectedFeatureCollection.features.length > 0 ? selectedFeatureCollection.features[0] : null;

    let id;

    // If the selected feature is an invalid IB, we want to keep that selected.
    if (selectedFeature && selectedFeature.properties.isInvalidInnerBoundary) {
      id = selectedFeature.id;
    } else {
      // Otherwise, we can just select the first invalid inner boundary
      id = firstInvalidInnerBoundary.id;
    }

    this.state.draw.changeMode(this.state.draw.modes.DIRECT_SELECT, { featureId: id });
  }

  // Called when a polygon is updated in some way in create mode (outer boundary)
  handleUpdateInCreateMode = event => {
    const fieldBoundariesPolygon = this.getFieldBoundariesPolygon();

    // Check for kinks. If it doesn't have any, we can safely dismiss the toast.
    if (validatePolygonHasNoKinks(fieldBoundariesPolygon)) {
      dismissToast(ErrorMessages.InvalidField);
    }
  }

  // Called when a polygon is updated in modify mode.
  handleUpdateInModifyMode = event => {
    let fieldBoundariesPolygon = this.getFieldBoundariesPolygon();

    // Any invalid inner boundaries need to be removed from fieldBoundariesPolygon and added as a separate feature.
    fieldBoundariesPolygon = this.removeInvalidInnerBoundariesFromField(fieldBoundariesPolygon);

    // Validate the field now that we've removed invalid inner boundaries.
    const fieldErrors = validateField(fieldBoundariesPolygon);

    if (fieldErrors.length > 0) {
      fieldErrors.forEach(error => {
        showMapErrorToastNonClosing(error);
      });
    }

    let dismissKinkToast = !fieldErrors.includes(ErrorMessages.InvalidField);

    // Check all previously invalid inner boundaries...(everything NOT validated by validateField)
    // Draw data has changed, so get it again.
    const invalidBoundariesToCheckAgain = this.state.draw.getAll().features.slice(1);

    invalidBoundariesToCheckAgain.forEach(previouslyInvalidInnerBoundary => {
      const doesNotHaveKinks = this.validatePreviousInvalidBoundary(fieldBoundariesPolygon, previouslyInvalidInnerBoundary);

      if (!doesNotHaveKinks) dismissKinkToast = false;
    });

    // Only dismiss InvalidField toast if validatePolygonHasNoKinks never returned false.
    if (dismissKinkToast) dismissToast(ErrorMessages.InvalidField);
  }

  validatePreviousInvalidBoundary = (fieldBoundariesPolygon, previouslyInvalidInnerBoundary) => {
    if (!isValidInnerBoundary(fieldBoundariesPolygon, previouslyInvalidInnerBoundary)) {
      // Still not valid, just updating it
      this.state.draw.setFeatureProperty(previouslyInvalidInnerBoundary.id, DrawnFeatureProperties.InvalidInnerBoundary, true);
      showMapErrorToastNonClosing(ErrorMessages.InvalidInnerBoundary);
    } else {
      // This is an inner boundary for the fieldBoundariesPolygon, don't create new polygon, just add it to fieldBoundariesPolygon.
      fieldBoundariesPolygon = this.addValidInnerBoundary(fieldBoundariesPolygon, previouslyInvalidInnerBoundary);
      // Dismiss toast if there's no invalid boundaries.
      const invalidBoundariesExist = this.state.draw.getAll().features.some(feature => feature.properties.isInvalidInnerBoundary);

      if (!invalidBoundariesExist) {
        dismissToast(ErrorMessages.InvalidInnerBoundary);
      }
      // Go back to direct_select mode for the field.
      this.state.draw.changeMode(this.state.draw.modes.DIRECT_SELECT, { featureId: fieldBoundariesPolygon.id });
    }

    return validatePolygonHasNoKinks(previouslyInvalidInnerBoundary);
  }

  addValidInnerBoundary = (firstPolygon, innerPolygon) => {
    // Add innerPolygon's coordinates to firstPolygon as an inner boundary.
    firstPolygon.geometry.coordinates.push(innerPolygon.geometry.coordinates[0]);

    // Make a copy of drawn features without the innerPolygon (we don't need it anymore).
    let copiedFeatures = this.state.draw.getAll().features.filter(feature => feature.id !== innerPolygon.id).map(clone);

    // Update the first feature (outer boundaries) since we've added to it's coordinates.
    copiedFeatures[0] = firstPolygon;

    this.updateDrawDataWithNewFeatures(copiedFeatures);
    return firstPolygon;
  }

  removeInvalidInnerBoundariesFromField = fieldBoundariesPolygon => {
    const innerBoundaryIDsToRemove = getIDsOfInnerBoundariesOutOfBounds(fieldBoundariesPolygon);

    // If there's no invalid inner boundaries we can stop now.
    if (innerBoundaryIDsToRemove.length === 0) return fieldBoundariesPolygon;

    const invalidBoundaryPolygonsToCreate = innerBoundaryIDsToRemove.map(invalidInnerBoundaryID => {
      const invalidBoundaryCoordinates = fieldBoundariesPolygon.geometry.coordinates[invalidInnerBoundaryID];

      // Create a polygon from invalid boundary coordinates
      return polygon([invalidBoundaryCoordinates]);
    });

    // Create all invalid boundaries and add to draw
    invalidBoundaryPolygonsToCreate.forEach(invalidBoundaryPolygon => {
      invalidBoundaryPolygon.properties.isInvalidInnerBoundary = true;
      this.state.draw.add(invalidBoundaryPolygon);
    });

    // Now we can remove all invalid boundaries from fieldBoundariesPolygon
    fieldBoundariesPolygon.geometry.coordinates = fieldBoundariesPolygon.geometry.coordinates.filter((boundary, index) =>
      !innerBoundaryIDsToRemove.includes(index)
    );

    // Make a copy of drawn features.
    let copiedFeatures = this.state.draw.getAll().features.map(clone);

    // Update the first feature since we've removed some of it's coordinates.
    copiedFeatures[0] = fieldBoundariesPolygon;
    this.updateDrawDataWithNewFeatures(copiedFeatures);

    return fieldBoundariesPolygon;
  }

  enterDeleteMode = () => {
    if (this.state.isCreating) {
      const fieldPolygon = this.getFieldBoundariesPolygon();

      // Cannot delete field until it's created.
      if (!fieldPolygon) {
        showMapErrorToastAutoClosing(ErrorMessages.CreateBeforeDelete);
        return;
      }
    }

    this.state.draw.changeMode(this.state.draw.modes.DRAW_POLYGON);

    // Don't show the hint again if they've already deleted before
    if (this.state.showDeleteHint) {
      showMapHintToastAutoClosing(MapHints.StartDeleting);
    }

    this.setState({
      isDrawing: true,
      isDeleting: true,
      showDeleteHint: false
    });
  }

  // Called when a polygon is completed in delete mode
  deletePoints = event => {
    const deletePolygon = event.features[0];
    const fieldPolygon = this.getFieldBoundariesPolygon();

    // Cannot do anything if don't have a field polygon
    if (!fieldPolygon) {
      this.exitDeleteMode(deletePolygon);
      return;
    }

    const intersectsWithFieldPolygon = intersect(fieldPolygon, deletePolygon);

    // Do not have to worry about inner boundaries when creating
    if (!this.state.isCreating) {
      this.deletePointsNotPartOfField(deletePolygon);
    }

    if (!intersectsWithFieldPolygon) {
      this.exitDeleteMode(deletePolygon);
      this.validateFieldAfterDeletePoints(fieldPolygon);
      return;
    }

    let clonedFieldPolygon = clone(fieldPolygon);

    let boundaryIndex, newFieldBoundaries;

    newFieldBoundaries = [];

    // Eliminate all coordinates in deletePolygon
    for (boundaryIndex = 0; boundaryIndex < clonedFieldPolygon.geometry.coordinates.length; boundaryIndex++) {
      const coordinatesToCheck = clonedFieldPolygon.geometry.coordinates[boundaryIndex];

      newFieldBoundaries[boundaryIndex] = removeDeletedCoordinates(coordinatesToCheck, deletePolygon);

      if (newFieldBoundaries[boundaryIndex].length === 0) {
        // Deleting the outer field boundary (first boundary is always outer boundary), delete it and we're done.
        if (boundaryIndex === 0) {
          this.state.draw.delete(clonedFieldPolygon.id);
          this.exitDeleteMode(deletePolygon);
          // They updated the field, set flag.
          this.setState({ hasModifiedField: true });
          return;
        }
      } else {
        newFieldBoundaries[boundaryIndex] = cleanPolygonCoordinates(newFieldBoundaries[boundaryIndex]);
      }
    }

    // Replace the old field boundaries with new ones.
    clonedFieldPolygon.geometry.coordinates = getActualFieldBoundaries(newFieldBoundaries);

    const addedSuccessfully = this.tryToDrawUpdatedPolygon(clonedFieldPolygon, fieldPolygon, deletePolygon);

    // No need to validate if we didn't actually delete/redraw successfully
    if (!addedSuccessfully) return;
    this.exitDeleteMode(deletePolygon);
    this.validateFieldAfterDeletePoints(clonedFieldPolygon);
  }

  deletePointsNotPartOfField = deletePolygon => {
    // Check if they are deleting an invalid inner boundary
    const drawData = this.state.draw.getAll();
    const invalidBoundaries = drawData.features.filter(drawnFeature =>
      drawnFeature.id !== deletePolygon.id // Leave out deletePolygon
      && drawnFeature.properties.isInvalidInnerBoundary // is an invalidInnerBoundary
      && intersect(drawnFeature, deletePolygon) // is intersecting with deletePolygon
    );

    if (invalidBoundaries.length === 0) return;

    for (let i = 0; i < invalidBoundaries.length; i++) {
      const invalidPolygon = invalidBoundaries[i];

      let clonedInvalidPolygon = clone(invalidPolygon);

      // We know invalid inner boundaries do not have any inner boundaries of their own, so we just grab the first boundaries.
      const coordinatesToCheck = clonedInvalidPolygon.geometry.coordinates[0];

      let newInvalidPolygonCoordinates = removeDeletedCoordinates(coordinatesToCheck, deletePolygon);

      if (newInvalidPolygonCoordinates.length === 0) {
        // Deleting the entire invalid boundary
        this.state.draw.delete(clonedInvalidPolygon.id);
        // Dismiss the invalid boundary toast for this invalid boundary since we won't have access to it later.
        dismissToast(ErrorMessages.InvalidInnerBoundary);
        continue;
      } else {
        newInvalidPolygonCoordinates = cleanPolygonCoordinates(newInvalidPolygonCoordinates);
      }

      // Replace the old invalid polygon boundaries with new ones.
      clonedInvalidPolygon.geometry.coordinates = [newInvalidPolygonCoordinates];

      this.tryToDrawUpdatedPolygon(clonedInvalidPolygon, invalidPolygon, deletePolygon);
    }
  }

  tryToDrawUpdatedPolygon = (updatedPolygon, originalPolygon, deletePolygon) => {
    try {
      // Delete the old so we can add the new (ids are the same)
      this.state.draw.delete(updatedPolygon.id);
      this.state.draw.add(updatedPolygon);
      return true;
    } catch (error) {
      // The new polygon doesn't have enough points or it is no longer a single polygon.
      showMapErrorToastAutoClosing(ErrorMessages.InvalidDeletePolygon);
      this.exitDeleteMode(deletePolygon);
      // Add the unmodified field back since this failed.
      this.state.draw.add(originalPolygon);
      return false;
    }
  }

  exitDeleteMode = deletePolygon => {
    this.state.draw.delete(deletePolygon.id);
    this.setState({
      isDrawing: true,
      isDeleting: false,
    });
  }

  disableDrawing = () => {
    if (!this.state.draw) return;

    this.state.draw.deleteAll();
    this.state.map.removeControl(this.state.draw);

    this.setState({
      isDrawing: false,
      draw: null
    });
  }

  enableDrawMode = () => {
    const drawnFeatures = getDrawnFeatures(this.state.draw.getAll());

    // If in create mode, don't let them draw again if they have already drawn something.
    // Tell them they need to save before creating inner boundaries.
    if (this.state.isCreating && drawnFeatures.length > 0) {
      showMapHintToastAutoClosing(MapHints.OuterBoundaryOnly);
      return;
    }

    this.setState({
      isDrawing: true,
      isDeleting: false
    });

    this.state.draw.changeMode(this.state.draw.modes.DRAW_POLYGON);
  }

  refreshMapSourceFromFieldList = fieldList => {
    const fieldsSource = createFieldsGeoJsonSource(fieldList);

    let fieldSource = this.state.map.getSource(FieldsSource);

    if (fieldSource) {
      fieldSource.setData(fieldsSource.data);
      this.setState({
        fieldsSource
      });
    }
  }

  refreshMapSource = async () => {
    // This is used when the fieldList is stale after a file upload, have to refresh it.
    const result = await FieldService.getFieldList(this.props.accountManagement.activeAccount.account_id);
    const newFieldList = result.data;
    const fieldsSource = createFieldsGeoJsonSource(newFieldList);

    this.state.map.getSource(FieldsSource).setData(fieldsSource.data);
    this.setState({
      fieldsSource
    });
  }

  // Remove a field (feature) from the map.
  removeFieldFromMapSource = id => {
    const featuresWithoutField = this.state.fieldsSource.data.features.filter(feature => feature.id !== id).map(clone);

    this.updateFieldsSourceWithNewFeatures(featuresWithoutField);
  }

  // When abandoning field modifications, need to reset to original field data
  // AND remove any invalid inner boundaries that could have been added during the modification process.
  resetFieldInSource = (id, actualFieldFeature) => {
    // Filter out invalid inner boundaries!
    let copiedFieldFeatures = this.state.fieldsSource.data.features.filter(feature => !feature.properties.isInvalidInnerBoundary).map(clone);

    const fieldIndex = copiedFieldFeatures.findIndex(field => field.id === id);

    if (fieldIndex < 0) {
      // They deleted the entire field. Need to readd it to field source.
      // Ensure the id persists (if it is a newly created field, the id would be the draw id)
      actualFieldFeature.id = id;
      copiedFieldFeatures.push(actualFieldFeature);
    } else {
      // Update field geometry
      copiedFieldFeatures[fieldIndex].geometry = actualFieldFeature.geometry;
    }

    this.showFieldSource();
    this.updateFieldsSourceWithNewFeatures(copiedFieldFeatures);
  }

  addDrawnFieldToFieldsSource = (drawId, newId) => {
    const drawData = this.state.draw.getAll();

    // Delete the drawn feature
    this.state.draw.delete(drawId);
    let drawnField = drawData.features[0];

    // Reset id so that it matches the id in the database
    drawnField.id = newId;

    // Add it to fieldsSource
    let copiedFeatures = this.state.fieldsSource.data.features.map(clone);

    copiedFeatures.push(drawnField);
    this.updateFieldsSourceWithNewFeatures(copiedFeatures);
  }

  updateFieldInSource = newFieldFeature => {
    let copiedFieldFeatures = this.state.fieldsSource.data.features.map(clone);

    const fieldIndex = copiedFieldFeatures.findIndex(field => field.id === newFieldFeature.id);

    // Update field geometry
    copiedFieldFeatures[fieldIndex].geometry = newFieldFeature.geometry;

    this.showFieldSource();
    this.updateFieldsSourceWithNewFeatures(copiedFieldFeatures);
  }

  updateFieldsSourceWithNewFeatures = newFeatures => {
    const newFieldsSource = {
      ...this.state.fieldsSource,
      data: {
        ...this.state.fieldsSource.data,
        features: newFeatures
      }
    };

    this.state.map.getSource(FieldsSource).setData(newFieldsSource.data);
    this.setState({
      fieldsSource: newFieldsSource
    });
  }

  updateDrawDataWithNewFeatures = newFeatures => {
    const newDrawData = {
      ...this.state.draw.getAll(),
      features: newFeatures
    };

    // draw.set should have better performance than doing draw.delete + draw.add
    this.state.draw.set(newDrawData);
  }

  goToFieldSettings = event => {
    const features = this.state.map.queryRenderedFeatures(event.point, {
      layers: [FieldsFillLayer.id]
    });

    if (features.length === 0) return;

    const feature = features[0];
    const field = this.props.field.fieldList.find(field => field.id === feature.id);

    this.props.dispatch(setSelectedField(field));
    this.props.history.push(Routes.FieldSettings(field.id));
  }

  goToModifyField = event => {
    const features = this.state.map.queryRenderedFeatures(event.point, {
      layers: [FieldsFillLayer.id]
    });

    if (features.length === 0) return;

    const feature = features[0];

    // We only let them click on the selected field
    if (feature.id !== this.props.field.selectedField.id) return;

    this.props.history.push(Routes.FieldCreateModify);
  }

  zoomToAllFields = padding => {
    //  No fields
    if (!this.state.fieldsSource.data.features.length) return;

    const coordinates = this.state.fieldsSource.data.features[0].geometry.coordinates[0];
    const bounds = new MapboxGL.LngLatBounds(coordinates[0], coordinates[0]);

    // Pass the first coordinates from the first field to `LngLatBounds`
    // & wrap each coordinate pair for all fields in `extend` to include them in the bounds result.
    this.state.fieldsSource.data.features.map(feature => {
      return feature.geometry.coordinates.map(polygon => {
        return polygon.map(coordinate => {
          return bounds.extend(coordinate);
        });
      });
    });

    this.state.map.fitBounds(bounds, {
      padding: padding || 50,
      duration: 1000
    });
  }

  zoomToSelectedField = selectedField => {
    const coordinates = selectedField.data.features[0].geometry.coordinates[0];

    // Pass the first coordinates from the first field to `LngLatBounds`
    // & wrap each coordinate pair for all fields in `extend` to include them in the bounds result.
    const bounds = coordinates.reduce(function (bounds, coordinates) {
      return bounds.extend(coordinates);
    }, new MapboxGL.LngLatBounds(coordinates[0], coordinates[0]));

    // Can mess with duration and whether linear or not
    this.state.map.fitBounds(bounds, {
      padding: 50,
      linear: false,
      duration: 2000
    });
  }

  onMouseMove = event => {
    // If there is a selectedField, we ignore the hovering for all other fields.
    if (this.props.field.selectedField) return;

    if (this.state.isDrawing || event.features.length === 0) return;

    const featureId = event.features[0].id;

    if (this.state.hoveredFieldId) {
      this.setFieldHoverState(this.state.hoveredFieldId, false);
    }

    this.setFieldHoverState(featureId, true);

    this.setState({
      hoveredFieldId: featureId
    });
  }

  onMouseLeave = () => {
    if (this.state.isDrawing) return;

    if (this.state.hoveredFieldId) {
      this.setFieldHoverState(this.state.hoveredFieldId, false);
    }

    this.setState({
      hoveredFieldId: null
    });
  }

  setFieldHoverState = (fieldId, isHovered) => {
    this.state.map.setFeatureState({
      source: FieldsSource,
      id: fieldId
    }, {
      hover: isHovered
    });
  }

  // Trigger update with a manually provided event so that we can just reuse this function.
  validateFieldAfterDeletePoints = fieldPolygon => {
    const manualEvent = {
      features: [fieldPolygon]
    };

    this.handleUpdate(manualEvent);
  }

  // Init outerBoundary and reset the id to match the actual field ID, not the draw ID.
  // Keep the draw ID so that we can still find it in draw (to delete it).
  reinitializeFieldBoundariesPolygon = () => {
    let fieldBoundariesPolygon = this.state.draw.getAll().features[0];

    fieldBoundariesPolygon.properties.outerBoundary = true;
    fieldBoundariesPolygon.properties.drawId = fieldBoundariesPolygon.id;
    fieldBoundariesPolygon.id = this.props.field.selectedField.id;

    // Make a copy of drawn features without the innerPolygon (we don't need it anymore).
    let copiedFeatures = this.state.draw.getAll().features.map(clone);

    // Update the first feature since we've made some changes to it's metadata
    copiedFeatures[0] = fieldBoundariesPolygon
    this.updateDrawDataWithNewFeatures(copiedFeatures);

    return fieldBoundariesPolygon;
  }

  getFieldBoundariesPolygon = () => {
    const drawData = this.state.draw.getAll();

    return drawData.features.find(feature => feature.properties.outerBoundary);
  }

  hideFieldSource = fieldID => {
    const filter = [
      '!=',
      ['id'],
      fieldID
    ];

    this.state.map.setFilter(FieldsFillLayer.id, filter);
    this.state.map.setFilter(FieldsBorderLayer.id, filter);
  }

  showFieldSource = () => {
    this.state.map.setFilter(FieldsFillLayer.id, null);
    this.state.map.setFilter(FieldsBorderLayer.id, null);
  }

  isMapRoute = path => {
    return path === Routes.Home || path === Routes.FieldCreateModify || this.isFieldSettingsRoute(path);
  }

  isFieldSettingsRoute = path => {
    return path.match(/field\/\d+\/settings/);
  }

  render() {
    // Hide the map for other views.
    const path = this.props.router.location.pathname;
    const hidden = this.isMapRoute(path) ? '' : ' hidden';

    // Need the mapboxgl-map class as well
    return <div id='map' className={`map-view mapboxgl-map${hidden}`}></div>
  }
}

export default MapView;