import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import * as accountManagement from './account-management-dux';
import * as iotToken from './iot-token-dux';
import * as field from './field-dux';
import * as machine from './machine-dux';
import * as settings from './settings-dux';
import * as dialog from './dialog-dux';
import * as modal from './modal-dux';
import * as websocket from './websocket-dux';
import * as operation from './operation-dux';
import comm from '../SmartAgUI-Common/reducers/commReducer';
import secondaryCombines from '../SmartAgUI-Common/reducers/secondaryCombineReducer';
import { reducerFromModel } from '../SmartAgUI-Common/reducers/reducerUtils';
import { TractorInfo, CombineInfo } from '../SmartAgUI-Common/models/machineInfoModel';

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  [accountManagement.mountPoint]: accountManagement.reducer,
  [iotToken.mountPoint]: iotToken.reducer,
  [field.mountPoint]: field.reducer,
  [machine.mountPoint]: machine.reducer,
  [settings.mountPoint]: settings.reducer,
  [dialog.mountPoint]: dialog.reducer,
  [modal.mountPoint]: modal.reducer,
  [websocket.mountPoint]: websocket.reducer,
  [operation.mountPoint]: operation.reducer,
  comm,
  secondaryCombines,
  tractorInfo: reducerFromModel(TractorInfo),
  combineInfo: reducerFromModel(CombineInfo),
});

export default rootReducer;