import commWorkerMsgHelper from './commWorkerMsgHelper';
import mqttWorkerMsg from './mqttWorkerMsg';
import mqttWorkerFactory from './mqttWorkerFactory';
import { non_json_topic_transform_dict } from './topicTransformers';

export default class workerEventHandler {
    static processMessage(self, client, commName, data) {
        console.log('processMessage');
        if (data.event_type === mqttWorkerMsg.INIT_MSG) {
            var broker_names = data.data.broker_names;
            var ip = data.data.ip;
            var iotToken = data.data.iotToken;
            var heartbeatTimeout = data.data.heartbeatTimeout;
            var accountId = data.data.accountId;
            var client = mqttWorkerFactory.getCommanagerWorkerClient(
                self,
                broker_names,
                ip,
                accountId,
                iotToken,
                heartbeatTimeout,
                non_json_topic_transform_dict
            );
            console.log(`${commName} init client`);
            return client;
        } else {
            commWorkerMsgHelper.processWorkerCommand(self, client, data);
            return null;
        }
    }
}
