import React from 'react';
import { closeModal } from '../dux/modal-dux';
import '../styles/modal.scss';
import { ReactComponent as PlusIcon } from '../assets/PlusIcon.svg';
import { ReactComponent as CloseIcon } from '../assets/CloseIcon.svg';

class Modal extends React.Component {
  renderPlusIcon() {
    if (!this.props.modal.options.showIcon) return null;
    return <PlusIcon className='plus-icon' />;
  }

  renderButtons() {
    const { options } = this.props.modal;

    if (options.leftButton && options.rightButton) {
      return (
        <div className='buttons'>
          {this.renderButton('left-button', options.leftButton, options.onLeftButtonClick)}
          {this.renderButton('right-button', options.rightButton, options.onRightButtonClick)}
        </div>
      );
    }
    else if (!options.leftButton) {
      return (
        <div className='buttons'>
          {this.renderButton('single-button', options.rightButton, options.onRightButtonClick)}
        </div>
      );
    }
    else {
      return (
        <div className='buttons'>
          {this.renderButton('single-button', options.leftButton, options.onLeftButtonClick)}
        </div>
      );
    }
  }

  renderButton(className, text, handler) {
    return (
      <button
        className={className}
        onClick={handler}
      >
        {text}
      </button>
    );
  }

  closeModal = () => {
    const { options } = this.props.modal;

    if (options.onExit) {
      options.onExit();
    } else {
      this.props.dispatch(closeModal());
    }
  }

  render() {
    const { options, showModal } = this.props.modal;

    if (!showModal) return null;

    return (
      <div className='modal'>
        <div className='modal-content'>
          <CloseIcon className='close-icon' onClick={this.closeModal} />
          <div className='header'>
            {this.renderPlusIcon()}
            <h4 className='title'>{options.title}</h4>
          </div>

          <div className='body'>
            {options.body}
          </div>

          {this.renderButtons()}
        </div>
      </div>
    );
  }
}

export default Modal;