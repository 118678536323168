export const ErrorMessages = {
  Parse: 'Failed to parse files.',
  Upload: 'Failed to upload files.',
  CreateField: 'Failed to create field.',
  UpdateField: 'Failed to update field.',
  DeleteField: 'Failed to delete field.',
  CreateMachine: 'Failed to create machine.',
  UpdateMachine: 'Failed to update machine.',
  DeleteMachine: 'Failed to delete machine.',
  NoFieldName: 'Field must have a name.',
  FieldNameTooLong: 'Field name must be less than 100 characters.',
  NoPoints: 'Field must have points.',
  UpdateFieldName: 'Failed to update field name.',
  SetCurrentAccount: 'Failed to fetch current account information.',
  NoFeatures: 'Please draw a field.',
  NotPolygon: 'Field must be a polygon.',
  NotFeature: 'Geometry must be Feature.',
  TooManyFeatures: 'Only one field can be drawn at a time.',
  UpdateSyncPoint: 'Failed to update sync point.',
  Socket: 'Failed to connect to Machine Control socket.',
  NewOperationStarted: 'Connection with machine closed because another operation has been started.',
  SocketClosed: 'Connection with machine was closed.',
  SubscriptionInvalid: 'Could not find a valid AutoCart subscription. Please check your account settings.',
  MachineControlConnection: 'There was a problem connecting to Machine Control.',
  MachineControlOffline: 'Could not connect to Machine Control.',
  InvalidInnerBoundary:
    `Inner boundaries must be completely within outer field bounds and cannot overlap other inner boundaries. 
    Try expanding your field bounds or rearranging your inner bounds.`,
  InvalidInnerBoundaries: 'Please resolve any invalid inner boundaries.',
  InvalidField: 'Field cannot overlap itself. Try moving points so that no lines are crossing.',
  FieldNotFinished: 'Please finish drawing the field by tapping an existing point.',
  InvalidDeletePolygon: 'Could not safely delete points within that polygon. Try deleting fewer points.',
  CreateBeforeDelete: 'Please finish drawing the field before deleting points.',
  AnotherSessionStarted: 'Operation was cancelled because another operation was started.',
  EndedOtherSessions: 'Warning: You have ended another operation in order to start this operation.',
  FailedGetFields: 'Failed to fetch fields.',
  CannotExitOperation: 'Cannot exit because socket connection with MC has not been established.'
};