import React from 'react';
import '../../styles/notifications-view.scss';

class NotificationsView extends React.Component {
  render() {
    return (
      <div className='notifications-view'>
        {'Notifications View Setup'}
      </div>
    );
  }
}

export default NotificationsView;