import { LocalStorageKeys } from '../constants/localStorageKeys';

let store = require('store/dist/store.modern');

export const setLocalItem = (keyName, keyValue) => {
  store.set(keyName, JSON.stringify(keyValue));
};

export const setLocalItemAsObject = (keyName, keyValue) => {
  store.set(keyName, keyValue);
}

export const getLocalItem = keyName => {
  try {
    return JSON.parse(store.get(keyName));
  } catch (err) {
    return store.get(keyName);
  }
};

export const removeLocalItem = keyName => {
  store.remove(keyName);
};

export const getTokenOrDefault = tokenKey => {
  return store.get(LocalStorageKeys.AccountInfo) ? store.get(LocalStorageKeys.AccountInfo)[tokenKey] : '';
};