import React from 'react';
import '../../styles/header.scss';
import FormInput from '../FormInput';
import { ReactComponent as FieldsIcon } from '../../assets/FieldsIcon.svg';
import { ReactComponent as MachinesIcon } from '../../assets/MachinesIcon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/SettingsIcon.svg';
import { ReactComponent as DarkLogo } from '../../assets/raven-omnidrive-white-white-logo.svg';
import { ReactComponent as LightLogo } from '../../assets/raven-omnidrive-blue-black-logo.svg';
import { push, goBack } from 'connected-react-router';
import { connect } from 'react-redux';
import { openDialog } from '../../dux/dialog-dux';
import { DialogPrompts } from '../../constants/dialogPrompts';
import { closeModal } from '../../dux/modal-dux';
import { Routes } from '../../constants/routes';
import { dismissAllToasts } from '../../helpers/toast-helper';
import { setActiveAccount } from '../../dux/account-management-dux';


class Header extends React.Component {
  checkIfFieldsActive = () => {
    const path = this.props.router.location.pathname;

    return path.match('/field') || path === Routes.Home ? 'icon-active' : 'icon';
  };

  checkIfMachinesActive = () => {
    const path = this.props.router.location.pathname;

    return path.match('/machine') ? 'icon-active' : 'icon';
  };

  checkIfSettingsIconActive = () => {
    const path = this.props.router.location.pathname;

    return path === Routes.Settings ? 'icon-settings-active' : 'icon-settings';
  }

  goToRoute = route => {
    // If the modal is showing, close it
    if (this.props.modal.showModal) {
      this.props.dispatch(closeModal());
    }

    const currentPath = this.props.router.location.pathname;

    if (currentPath === Routes.FieldCreateModify) {
      // Dismissing toasts here because they can get in the way of the dialog.
      dismissAllToasts();

      // They are trying to leave when drawing. Make them confirm.
      this.props.dispatch(openDialog({
        content: DialogPrompts.LeaveDrawing,
        yesButton: () => {
          const isModifyingField = this.props.field.selectedField;

          // Use webState to tell map to reset selected field since they are abandoning edits.
          if (isModifyingField) {
            this.props.push(route, { reset: true });
          } else {
            this.props.push(route);
          }
        }
      }));
    } else {
      this.props.push(route);
    }
  }

  getBlueStripClassName = () => {
    const path = this.props.router.location.pathname;

    if (path.match('/field') || path === Routes.Home) {
      return 'strip field'
    }
    if (path.match('/machine')) {
      return 'strip machine'
    }
    if (path === Routes.Settings) {
      return 'strip settings';
    }

    return 'strip';
  }

  renderLogo = () => {
    if (this.props.settings.darkMode) {
      return <DarkLogo className='logo' />
    } else {
      return <LightLogo className='logo' />
    }
  }


  getAccounts = () => {
    const { accountManagement } = this.props;
    const accounts = accountManagement.accounts;

    let items = [];

    for (const account of accounts) {
      items.push({name: account.account_name, value: account.company_id});
    }
    return items;
  }

  onChangeAccount = event => {
    // value = selected company id
    const { value } = event.target;
    const { accountManagement } = this.props;
    const setActiveAccountAction = setActiveAccount(accountManagement.accounts, value);

    this.props.dispatch(setActiveAccountAction);
  }

  render() {
    if (this.props.operation.isRunning) {
      return null;
    }


    return (
      <div id='header' className='top-header'>
        <div className='pages'>
          <div
            className='page-icon'
            onClick={() => {
              this.goToRoute(Routes.Home);
            }}
          >
            <FieldsIcon className={this.checkIfFieldsActive()} />
          </div>
          <div
            className='page-icon'
            onClick={() => {
              this.goToRoute(Routes.Machines);
            }}
          >
            <MachinesIcon className={this.checkIfMachinesActive()} />
          </div>
          <div
            className='page-icon'
            onClick={() => {
              this.goToRoute(Routes.Settings);
            }}
          >
            <SettingsIcon className={this.checkIfSettingsIconActive()} />
          </div>
        </div>
        {this.renderLogo()}
        <FormInput
          onChange={this.onChangeAccount}
          type='select'
          name='account-selection'
          selectOptions={this.getAccounts()}
          value={this.props.accountManagement.activeAccount.company_id}
          dropdownType='account-selection'
        />
        <span className='title'>{'OMNiDRIVE'}</span>
        <div className={this.getBlueStripClassName()}>
          <div className='triangle'></div>
        </div>
      </div>
    );
  }
}

export default connect(null, { push, goBack })(Header);