import React from 'react';
import { connect } from 'react-redux';
import Header from './components/header/Header';
import Dialog from './components/Dialog';
import { Routes } from './constants/routes';
import Modal from './components/Modal';

// TODO: Test this function
/* istanbul ignore next */
function getState(state) {
  return state;
}

function connectComponent(component) {
  return connect(getState)(component);
}

class App extends React.Component {
  componentDidMount() {
    this.props.history.push(Routes.Home);
  }

  render() {
    const ConnectedHeader = connectComponent(Header);
    const ConnectedDialog = connectComponent(Dialog);
    const ConnectedModal = connectComponent(Modal);

    return (
      <div className='app'>
        <ConnectedHeader />
        <ConnectedDialog />
        <ConnectedModal />

        {this.props.children}
      </div>
    );
  }
}

export default App;