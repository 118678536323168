import React from 'react';
import '../../styles/operation-view.scss';
import connect from 'socket.io-client';
import FieldService from '../../services/v1/FieldService';
import MachineService from '../../services/v1/MachineService';
import { MachineControlEvents } from '../../constants/machineControlEvents';
import { showDefaultErrorToast, showDefaultSuccessToast, dismissSuccessToast } from '../../helpers/toast-helper';
import socketServerHelper from '../../SmartAgUI-Common/socketServerHelper';
import { websocketConnected, websocketInitiated, websocketDisconnect } from '../../dux/websocket-dux';
import { destroyWorkersAction, restartWorkersAction } from '../../SmartAgUI-Common/actions/commActions';
import { ErrorMessages } from '../../constants/errorMessages';
import Loader from '../Loader';
import { updateSelectedFieldProperty, FieldProperties } from '../../dux/field-dux';
import { TractorType } from '../../constants/machineType';
import { endOperation } from '../../dux/operation-dux';
import { toggleDarkMode } from '../../dux/settings-dux';
import LogService from '../../services/v1/LogService';
import { logError } from '../../dux/account-management-dux';
import { getNewIotToken } from '../../dux/iot-token-dux';
import {
  MACHINE_CONTROL_READY,
  EXIT_OPERATION, UPDATE_FIELD, TOGGLE_DARK_MODE, SEND_LOG,
  AAVI_CONNECTED_CLIENTS, DUPLICATE_SESSION_STARTED, DISARM, DISARM_SENT, GET_NEW_IOT_TOKEN
} from '../../SmartAgUI-Common/commTopicGenerator';
import { SuccessMessages } from '../../constants/successMessages';
import { openDialog } from '../../dux/dialog-dux';
import { DialogPrompts } from '../../constants/dialogPrompts';

class OperationView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      combine: this.props.operation.combine,
      tractor: this.props.operation.tractor,
      hasExited: false,
      hasErrored: false,
      connected: false,
    };

    this.unmounted = false;
    this.socket = null;
  }

  componentDidMount() {
    // Kill UI workers when MC active
    this.props.dispatch(destroyWorkersAction());
    this.connectMachineControlSocket();

    // Add same state to browser history.
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = () => {
      // Make user confirm when using the back button to exit the operation.
      this.props.dispatch(openDialog({
        content: DialogPrompts.ExitOperation,
        yesButton: () => {
          if (!this.socket) {
            showDefaultErrorToast(ErrorMessages.CannotExitOperation);
            this.props.history.go(1);
            return;
          }

          // Wait until we know MC sent the disarm command before leaving.
          this.socket.on(DISARM_SENT, () => {
            // If they confirmed, actually go back twice because we pushed to our history.
            // Setting onpopstate to null so that we don't go through this again.
            window.onpopstate = null;
            this.props.history.go(-2);
          });

          console.log('Sending disarm command...');
          // Tell MC to disarm
          this.socket.emit(DISARM);
        },
        noButton: () => {
          // Ignore back button by (fake) going forward
          this.props.history.go(1);
        }
      }));

      // Doing this here to handle user clicking back multiple times.
      // Ignore back button by (fake) going forward
      this.props.history.go(1);
    };
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.props.dispatch(restartWorkersAction());
    this.props.dispatch(websocketDisconnect());
    this.props.dispatch(endOperation());
  }

  connectMachineControlSocket = () => {
    const { dispatch } = this.props;
    const socketConnectionURL = process.env.REACT_APP_MACHINE_CONTROL_URL + socketServerHelper.getAaviSocketName();

    try {
      const socket = connect(socketConnectionURL);

      this.socket = socket;

      dispatch(websocketInitiated());

      socket.on(MachineControlEvents.Connected, function () {
        dispatch(websocketConnected(this));
      });

      socket.on(MACHINE_CONTROL_READY, () => {
        // Send operation data to MC
        this.sendOperationData(socket);
      });

      socket.on(UPDATE_FIELD, fieldData => {
        this.saveField(fieldData);
      });

      socket.on(MachineControlEvents.SyncPointUpdate, data => {
        this.saveSyncPoint(data);
      });

      socket.on(TOGGLE_DARK_MODE, () => {
        this.toggleDarkMode();
      });

      socket.on(SEND_LOG, data => {
        LogService.createLog(data.id, data.message);
      });

      socket.on(EXIT_OPERATION, () => {
        this.exitOperation();
      });

      socket.on(DUPLICATE_SESSION_STARTED, () => {
        showDefaultErrorToast(ErrorMessages.AnotherSessionStarted);
        this.setState({ hasErrored: true });
      });

      socket.on(AAVI_CONNECTED_CLIENTS, data => {
        // Server had an error, so we can't continue.
        if (data.error) {
          showDefaultErrorToast(data.message);
          dispatch(logError(data, JSON.stringify(data.error)));
          this.exitOperation();
          return;
        }

        this.setState({ connected: true });
        if (data.numberOfClients && data.numberOfClients > 1) {
          showDefaultErrorToast(ErrorMessages.EndedOtherSessions);
        }
      });

      socket.on(MachineControlEvents.Disconnected, () => {
        console.log('AAVI-UI OperationView: Socket disconnect');
        // Only do this if we know it hasn't been done already (from EXIT_OPERATION)
        if (this.state.hasExited || this.unmounted) return;
        if (!this.state.hasErrored) {
          showDefaultErrorToast(ErrorMessages.SocketClosed);
        }

        this.exitOperation();
      });

      socket.on(GET_NEW_IOT_TOKEN, () => {
        dispatch(getNewIotToken());
      });
    } catch (error) {
      console.error(error);
      this.props.dispatch(logError(error));
      showDefaultErrorToast(ErrorMessages.Socket)
    }
  }

  toggleDarkMode = () => {
    this.props.dispatch(toggleDarkMode());
  }

  exitOperation = () => {
    console.log('EXIT OPERATION');
    // Do not want to show another confirmation
    window.onpopstate = null;
    dismissSuccessToast();
    this.setState({ hasExited: true });
    // Actually go back twice because we pushed to our history on mount.
    this.props.history.go(-2);
  }

  formatCombine = () => {
    return {
      ...this.state.combine,
      config: {
        navigation: {
          machine_width: this.state.combine.headWidth
        }
      }
    };
  }

  formatTractor = () => {
    if (this.state.tractor.machine_model === TractorType.IVT) {
      return {
        ...this.state.tractor,
        config: {
          controllers: {
            speed: {
              speed_dial_f1: this.state.tractor.speedDialF1,
              speed_dial_f2: this.state.tractor.speedDialF2,
            }
          }
        }
      };
    }

    return {
      ...this.state.tractor,
      config: {
        controllers: {
          speed: {
            minRPM: this.state.tractor.minRPM,
            maxRPM: this.state.tractor.maxRPM
          }
        }
      }
    };
  }

  sendOperationData = socket => {
    const { iotToken } = this.props;
    const operationData = {
      accountName: this.props.accountManagement.activeAccount.company_id,
      field: this.props.field.selectedField,
      machineInfo: {},
      selectedCombine: this.formatCombine(),
      selectedTractor: this.formatTractor(),
      selectedCombineHead: {},
      iotToken: iotToken,
      darkMode: this.props.settings.darkMode
    };

    socket.emit(MachineControlEvents.OperationData, JSON.stringify(operationData));
  }

  saveField = async fieldData => {
    const updateFieldModel = {
      fieldId: fieldData.id,
      fieldData: fieldData.data
    };

    try {
      await FieldService.updateFieldData(updateFieldModel);
      this.props.dispatch(updateSelectedFieldProperty(FieldProperties.Data, updateFieldModel.fieldData));
      showDefaultSuccessToast(SuccessMessages.FieldSaved);
    } catch (error) {
      const details = 'FieldService.updateFieldData called with updateFieldModel: ' + JSON.stringify(updateFieldModel);

      console.error(error, details);
      this.props.dispatch(logError(error, details));
      showDefaultErrorToast(ErrorMessages.UpdateField);
    }
  }

  saveSyncPoint = async data => {
    const { combineID, syncPoint } = data;
    const syncPointData = {
      syncPointX: syncPoint[0],
      syncPointY: syncPoint[1]
    };

    try {
      await MachineService.updateSyncPoint(combineID, syncPointData);
      showDefaultSuccessToast(SuccessMessages.SyncPointSaved);
    } catch (error) {
      const details = 'MachineService.updateSyncPoint called with combineID: ' + combineID + ', syncPointData: ' + JSON.stringify(syncPointData);

      console.error(error, details);
      this.props.dispatch(logError(error, details));
      showDefaultErrorToast(ErrorMessages.UpdateSyncPoint);
    }
  }

  render() {
    if (!this.state.connected) {
      return <Loader />;
    }

    const iFrameURL = process.env.REACT_APP_MACHINE_CONTROL_URL +
      '/machinecontrol?connectionID=' +
      this.props.operation.connectionID + '&account=' +
      this.props.accountManagement.activeAccount.company_id +
      '&mqttUrl=' + process.env.REACT_APP_MQTT_URL;

    return (
      <div className='operation-view'>
        {/* Show loader behind iframe so you can see when iFrame is actually loading. */}
        <Loader />
        <iframe
          title='Machine Control'
          src={iFrameURL}></iframe>
      </div>
    );
  }
}

export default OperationView;