import { batch } from 'react-redux'
// utilities
import actionTypes from './actionTypes';
import { getSha1Hash } from '../hashHelper';
// Dispatches all actions required to update combine state using MQTT message

export function updateCombineId(dispatch, id) {
    const action = {
        type: actionTypes.UPDATE_COMBINE_ID,
        payload: { id }
    };
    dispatch(action);
}

export function updateCombineBrokerStatus(dispatch, brokerName, isConnected) {
    const action = {
        type: actionTypes.UPDATE_COMBINE_BROKER_STATUS,
        payload: {
            brokerName,
            isConnected
        }
    };
    dispatch(action);
}

export function updateCombineSyncPoint(dispatch, sync_x, sync_y) {
    const action = {
        type: actionTypes.UPDATE_COMBINE_SYNC_POINT,
        payload: {
            sync_x,
            sync_y
        }
    };
    dispatch(action);
}

export function updateCombineName(dispatch, name) {
    const nameAction = {
        type: actionTypes.UPDATE_COMBINE_NAME,
        payload: { name }
    };
    dispatch(nameAction);
}

export function updateCombineHead(dispatch, newHead) {
    const action = {
        type: actionTypes.UPDATE_COMBINE_HEAD,
        payload: JSON.parse(newHead)
    };
    dispatch(action);
}

export function updateCombineRunningCommand(dispatch, runningCommand) {
    const runningCommandAction = {
        type: actionTypes.UPDATE_COMBINE_RUNNING_COMMAND,
        payload: { runningCommand }
    };
    dispatch(runningCommandAction);
}

/* COMBINE STATE */
export function updateCombineStateSlimCore(dispatch, mqttPayload) {
    try {
        const { yaw_rate, veh_speed, timestamp } = mqttPayload;
        const lat = parseFloat(mqttPayload.lat);
        const lon = parseFloat(mqttPayload.lon);

        var speed_mph = formatSpeedInMPH(veh_speed);
        // convert degrees to radians
        const heading_deg = parseFloat(mqttPayload.heading);
        const heading = heading_deg * (Math.PI / 180);

        const direction = headingToDirection(heading_deg);

        const gpsAction = {
            type: actionTypes.UPDATE_COMBINE_GPS,
            payload: {
                lat, lon, speed_mph, direction, heading, yaw_rate, timestamp
            }
        };

        dispatch(gpsAction);
    } catch (error) {
        console.error(`Error in updateCombineStateSlimCore: ${error}`);
        invalidateCombineDataAction(dispatch);
    }
}

export function updateCombineStateSlimExtraWrapper(veh_speed, dispatch, mqttPayload) {
    try {
        var speed_mph = formatSpeedInMPH(veh_speed);

        const { cur_gear, eng_rpm, fuel_level } = mqttPayload;
        const fuel_level_str = formatFuelLevel(fuel_level);
        const cur_gear_str = formatGear(cur_gear, speed_mph);

        const gps_fail = parseInt(mqttPayload.gps_fail);
        const can0_fail = parseInt(mqttPayload.can0_fail);
        const can1_fail = parseInt(mqttPayload.can1_fail);
        const gps_acquiring = parseInt(mqttPayload.gps_acquiring);
        const ignition_key = mqttPayload.ignition_key;
        const shutdown_timer = mqttPayload.shutdown_timer;

        const canAction = {
            type: actionTypes.UPDATE_COMBINE_CAN,
            payload: {
                speed_mph, eng_rpm, fuel_level: fuel_level_str, gear: cur_gear_str
            }
        };
        const sensorStatusAction = {
            type: actionTypes.UPDATE_COMBINE_SENSOR_STATUS,
            payload: { gps_fail, can0_fail, can1_fail, gps_acquiring, ignition_key, shutdown_timer }
        };

        batch(() => {
            dispatch(canAction);
            dispatch(sensorStatusAction);
        });
    } catch (error) {
        console.error(`Error in updateCombineStateSlimExtraWrapper: ${error}`);
        invalidateCombineDataAction(dispatch);
    }
}

export function updateCombineStateSlimExtra(dispatch, mqttPayload) {

    const fn = function (dispatch, getState) {
        const veh_speed = getState().tractorInfo.machine.gps.veh_speed;
        updateCombineStateSlimExtraWrapper(veh_speed, dispatch, mqttPayload);
    };

    dispatch(fn);
}
/* COMBINE STATE */

/* COMBINE STATUS */
export function updateCombineStatusSlimCore(dispatch, mqttPayload) {
    try {
        const statusAction = {
            type: actionTypes.UPDATE_COMBINE_MACHINE_STATUS,
            payload: mqttPayload
        };
        dispatch(statusAction);
    } catch (error) {
        console.error(`Error in updateCombineStatusSlimCore: ${error}`);
        invalidateCombineDataAction(dispatch);
    }
}

export function updateCombineStatusSlimExtra(dispatch, mqttPayload) {
    try {
        const statusAction = {
            type: actionTypes.UPDATE_COMBINE_MACHINE_STATUS_EXTRA,
            payload: mqttPayload
        };
        dispatch(statusAction);
    } catch (error) {
        console.error(`Error in updateCombineStatusSlimExtra: ${error}`);
        invalidateCombineDataAction(dispatch);
    }
}
/* COMBINE STATUS */

export function headingToDirection(heading) {
    var headingDirection = '';
    if (heading > 0 && heading < 90) {
        headingDirection = 'NE';
    } else if (heading > 90 && heading < 180) {
        headingDirection = 'SE'
    } else if (heading > 180 && heading < 270) {
        headingDirection = 'SW';
    } else if (heading > 270 && heading < 360) {
        headingDirection = 'NW';
    } else if (heading === 0 || heading === 360) {
        headingDirection = 'N';
    } else if (heading === 90) {
        headingDirection = 'E';
    } else if (heading === 180) {
        headingDirection = 'S';
    } else if (heading === 270) {
        headingDirection = 'W';
    }

    return headingDirection;
}

export function formatSpeedInMPH(speed) {
    var formattedMPH = parseFloat(speed) * 2.23694;
    formattedMPH = formattedMPH.toFixed(2);
    return formattedMPH;
}

export function formatGear(cur_gear, speed) {
    // check  the gear
    var cur_gear = parseInt(cur_gear);
    var formattedCurGear;

    if (cur_gear > 0) { // Forward gears
        formattedCurGear = 'F' + cur_gear;
    } else if (cur_gear < 0) { // Reverse gears
        formattedCurGear = 'R' + (cur_gear * (-1));
    } else { // Park or Neutral - 0
        // gear is 0 - which could be park or neutral - check speed to verify
        if (parseFloat(speed) === 0) {
            formattedCurGear = 'Park';
        } else {
            formattedCurGear = 'Neutral';
        }
    }
    return formattedCurGear;
}
export function formatFuelLevel(fuel_level) {
    return parseFloat(fuel_level).toFixed(2);
}

export function invalidateCombineDataAction(dispatch) {
    dispatch({
        type: actionTypes.INVALIDATE_COMBINE_DATA
    })
}

export function updateCombineConfig(dispatch, data) {
    var version = getSha1Hash(data);
    const updateConfigAction = {
        type: actionTypes.UPDATE_COMBINE_CONFIG,
        payload: {
            data,
            version
        }
    };
    dispatch(updateConfigAction);
}