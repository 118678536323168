import {Record} from 'immutable';
import { GPSInfo, SensorStatus, MachineStatus, MachineStatusExtra, CANInfo, MachineConfig, MachineConfigOptions} from './baseInfoModels';
import machineTypes from './machineTypes';
import commManagerConstants from '../commManagerConstants';
import combineHelper from '../combineHelper';

const ONLINE_MESSAGE_COUNT = 8;
const HEART_BEAT_TIMEOUT_LIMIT_MS = 5000;
const DISTANCE_TO_COMBINE_THRESH_METERS = .5;
const MachineInfoRecord = Record ({
  id: 0,
  msgCounterWifi: 0,
  msgCounterCloud: 0,
  radioBrokerConnected: false,
  type: 'n/a',
  name: 'n/a',
  gps: new GPSInfo(),
  can: new CANInfo(),
  sensorStatus: new SensorStatus(),
  machineStatus: new MachineStatus(),
  machineStatusExtra: new MachineStatusExtra(),
  config: new MachineConfig(),
  configOptions: new MachineConfigOptions(),
  stateTimestamp: 0,
  statusTimestamp: 0
}, 'MachineInfo');

/*
 * errors bitmask:
 * bypass : bit 0
 * estop : bit 1
 * vision stop : bit 2
 * predictive stop : bit 3
 * sync stop : bit 4
 * field boundary: bit 5
 * navigation not ready: bit 6
 * perception not ready: bit 7
 * radar stop : bit 8
 * perception disabled: bit 9
 * object tracking stop : bit 10
 * hazard camera stop : bit 11
*/
export const tractor_errors = {
  BYPASS: 1 << 0,
  ESTOP: 1 << 1,
  VISION_STOP: 1 << 2,
  PREDICTIVE_STOP: 1 << 3,
  SYNC_STOP: 1 << 4,
  FIELD_BOUNDARY: 1 << 5,
  NAVIGATION: 1 << 6,
  RADAR_STOP: 1 << 7,
  PERCEPTION_DISABLED: 1 << 8,
  OBJECT_TRACKING_STOP: 1 << 9,
  HAZARD_CAMERA_STOP: 1 << 10,
  HP_GANDALF_DISCONNECTED: 1 << 11,
  PREDICTIVE_OFFLINE: 1 << 12,
  OBJECT_TRACKING_OFFLINE: 1 << 13,
  CAMERA_OFFLINE: 1 << 14,
  RADAR_OFFLINE: 1 << 15,
  GEOFENCE_OFFLINE: 1 << 16,
  UI_HEARTBEAT_OFFLINE: 1 << 17,
  HARDWARE_OFFLINE: 1 << 18,
  HAZARD_CAMERA_OFFLINE: 1 << 19,
  COMBINE_RTK_GPS_CHECK_OFFLINE: 1 << 20
}

function initMachineInfo(type){
  return new MachineInfo({type: type});
}

export class MachineInfo extends MachineInfoRecord {
  updateMachineIdReducer({payload}){
    return this.set('id', payload.id);
  }

  updateMachineNameReducer({payload}){
    return this.set('name', payload.name);
  }

  updateMachineGpsReducer({payload}){
    const {lat, lon, speed_mph, direction, yaw_rate, heading} = payload
    const newGPS = new GPSInfo({lat, lon, speed_mph, direction, yaw_rate, heading});

    return this.set('gps', newGPS).set('stateTimestamp', payload.timestamp);
  }

  updateMachineCanReducer({payload}){
    const {eng_rpm, fuel_level, gear} = payload
    const newCAN = new CANInfo({eng_rpm, fuel_level, gear});

    return this.set('can', newCAN);
  }

  updateMachineSensorStatusReducer({payload}){
    const { gps_fail, can0_fail, can1_fail, gps_acquiring, ignition_key, shutdown_timer } = payload
    const newSensorStatus = new SensorStatus({ gps_fail, can0_fail, can1_fail, gps_acquiring, ignition_key, shutdown_timer });

    return this.set('sensorStatus', newSensorStatus);
  }

  updateMachineStatusReducer({ payload }) {
    const { armed, errors, timestamp } = payload
    const newMachineStatus = new MachineStatus({ armed, errors, timestamp });

    return this.set('machineStatus', newMachineStatus)
      .set('statusTimestamp', timestamp);
  }

  updateMachineStatusExtraReducer({ payload }) {
    const { field_id, config_version, cov_mode, timestamp } = payload
    const newMachineStatus = new MachineStatusExtra({ field_id, config_version, cov_mode, timestamp });

    return this.set('machineStatusExtra', newMachineStatus)
      .set('statusTimestamp', timestamp);
  }

  invalidateMachineDataReducer(action) {
    return this.set('gps', new GPSInfo())
      .set('can', new CANInfo())
  }

  updateBrokerStatusReducer({payload}) {
    let {brokerName, isConnected} = payload

    if(commManagerConstants.CLOUD_BROKER === brokerName){
      if(!isConnected) {
        if(!this.isWifiConnected()) {
          return this.getResetBaseInfo();
        }

        console.log('Resetting Cloud counter');
        return this.set('msgCounterCloud', 0);
      }

      return this.set('msgCounterCloud', this.msgCounterCloud >= ONLINE_MESSAGE_COUNT ? this.msgCounterCloud : this.msgCounterCloud + 1);
    }
    else if(commManagerConstants.WIFI_BROKER === brokerName){
      if(!isConnected) {
        if(!this.isCloudConnected()) {
          return this.getResetBaseInfo();
        }

        console.log('Resetting Wifi counter');
        return this.set('msgCounterWifi', 0);
      }

      return this.set('msgCounterWifi', this.msgCounterWifi >= ONLINE_MESSAGE_COUNT ? this.msgCounterWifi : this.msgCounterWifi + 1);
    }
    else if(commManagerConstants.RADIO_BROKER === brokerName){
      return this.set('radioBrokerConnected', true);
    }

    throw new Error (`Cannot update broker: ${brokerName} for state is connected: ${isConnected}`);
  }

  updateMachineConfigReducer({payload}) {
    const { data, version } = payload;
    const newMachineConfig = new MachineConfig({data, version});

    return this.set('config', newMachineConfig)
  }

  updateMachineConfigOptionsReducer({payload}) {
    const { turning_radius_default, turning_radius_min } = payload;
    const newMachineConfigOptions = new MachineConfigOptions({turning_radius_default, turning_radius_min});

    return this.set('configOptions', newMachineConfigOptions)
  }

  getResetBaseInfo(){
    console.log('Resetting base state');
    return this.set('gps', new GPSInfo())
      .set('msgCounterWifi', 0)
      .set('msgCounterCloud', 0)
      .set('can', new CANInfo())
      .set('sensorStatus', new SensorStatus())
      .set('machineStatus',new MachineStatus())
  }

  isWifiConnected(){
    return this.msgCounterWifi >= ONLINE_MESSAGE_COUNT;
  }

  isCloudConnected(){
    return this.msgCounterCloud >= ONLINE_MESSAGE_COUNT;
  }

  hasValidName() {
    return this.name !== 'n/a';
  }

  hasValidCoordinates() {
    return this.gps.lat !== null &&
           this.gps.lon !== null;
    //  !this.sensorStatus.gps_fail &&
    //  !this.sensorStatus.can0_fail &&
    //  !this.sensorStatus.can1_fail &&
    //  !this.sensorStatus.gps_acquiring;
  }

  getXY() {
    return [this.gps.lon, this.gps.lat];
  }

  isVisionStop(){
    return (this.machineStatus.errors & tractor_errors.VISION_STOP) > 0;
  }

  isRadarStop(){
    return (this.machineStatus.errors & tractor_errors.RADAR_STOP) > 0;
  }

  isEStop(){
    return (this.machineStatus.errors & tractor_errors.ESTOP) > 0;
  }

  isManualOverride(){
    return (this.machineStatus.errors & tractor_errors.BYPASS) > 0;
  }

  isPredictiveStop(){
    return (this.machineStatus.errors & tractor_errors.PREDICTIVE_STOP) > 0;
  }

  isSyncStop(){
    return (this.machineStatus.errors & tractor_errors.SYNC_STOP) > 0;
  }

  isOutsideFieldBoundaries(){
    return (this.machineStatus.errors & tractor_errors.FIELD_BOUNDARY) > 0;
  }

  isSmarthpNavigationDown(){
    return (this.machineStatus.errors & tractor_errors.NAVIGATION) > 0;
  }

  isSmarthpPerceptionDisabled(){
    return (this.machineStatus.errors & tractor_errors.PERCEPTION_DISABLED) > 0;
  }

  isObjectTrackingStop(){
    return (this.machineStatus.errors & tractor_errors.OBJECT_TRACKING_STOP) > 0;
  }

  isHazardCameraStop(){
    return (this.machineStatus.errors & tractor_errors.HAZARD_CAMERA_STOP) > 0;
  }

  isHpGandalfDisconnected() {
      return (this.machineStatus.errors & tractor_errors.HP_GANDALF_DISCONNECTED) > 0;
  }

  isPredictiveOffline() {
    return (this.machineStatus.errors & tractor_errors.PREDICTIVE_OFFLINE) > 0;
  }

  isObjectTrackingOffline() {
    return (this.machineStatus.errors & tractor_errors.OBJECT_TRACKING_OFFLINE) > 0;
  }

  isCameraOffline() {
    return (this.machineStatus.errors & tractor_errors.CAMERA_OFFLINE) > 0;
  }

  isRadarOffline() {
    return (this.machineStatus.errors & tractor_errors.RADAR_OFFLINE) > 0;
  }

  isGeofenceOffline() {
    return (this.machineStatus.errors & tractor_errors.GEOFENCE_OFFLINE) > 0;
  }

  isUiHeartbeatOffline() {
    return (this.machineStatus.errors & tractor_errors.UI_HEARTBEAT_OFFLINE) > 0;
  }

  isHardwareOffline() {
    return (this.machineStatus.errors & tractor_errors.HARDWARE_OFFLINE) > 0;
  }

  isHazardCameraOffline() {
    return (this.machineStatus.errors & tractor_errors.HAZARD_CAMERA_OFFLINE) > 0;
  }

  isCombineRtkGpsCheckOffline() {
    return (this.machineStatus.errors & tractor_errors.COMBINE_RTK_GPS_CHECK_OFFLINE) > 0;
  }
    
  isInDisarmState(){
    return this.isOutsideFieldBoundaries()
    || this.isSmarthpNavigationDown()
    || this.isHpGandalfDisconnected()
    || this.isPredictiveOffline()
    || this.isObjectTrackingOffline()
    || this.isCameraOffline()
    || this.isRadarOffline()
    || this.isGeofenceOffline()
    || this.isUiHeartbeatOffline()
    || this.isHardwareOffline()
    || this.isHazardCameraOffline()
    || this.isCombineRtkGpsCheckOffline()
  }

  getDisarmReason(){
    let reason = 'The system was disarmed due to: ';

    if(this.isOutsideFieldBoundaries()){
      reason += 'The machine is outside field boundaries. ';
    }
    if(this.isSmarthpNavigationDown()){
      reason += 'The machine\'s navigation system is malfunctioning. ';
    }
    if(this.isHpGandalfDisconnected()){
      reason += 'SmartHP disconnected from Safety Manager. ';
    }
    if(this.isPredictiveOffline()){
      reason += 'Predictive subsystem is offline. ';
    }
    if(this.isObjectTrackingOffline()){
      reason += 'Object Tracking subsystem is offline. ';
    }
    if(this.isCameraOffline()){
      reason += 'Camera subsystem is offline. ';
    }
    if(this.isRadarOffline()){
      reason += 'Radar subsystem is offline. ';
    }
    if(this.isGeofenceOffline()){
      reason += 'Geofence subsystem is offline. ';
    }
    if(this.isUiHeartbeatOffline()){
      reason += 'UI Heartbeat subsystem is offline. ';
    }
    if(this.isHardwareOffline()){
      reason += 'Hardware subsystem is offline. ';
    }
    if(this.isHazardCameraOffline()){
      reason += 'Hazard Camera subsystem is offline. ';
    }
    if(this.isCombineRtkGpsCheckOffline()){
      reason += 'Combine Rtk Gps Check subsystem is offline. ';
    }

    return reason;
  }

  getEmergencyError() {
    let message = 'Operation has stopped for the following reason(s): ';

    if (this.isEStop()) {
      message += 'An emergency stop was initiated. ';
    }
    if (this.isPredictiveStop()) {
      message += 'The vehicle was predicted to go out of bounds. ';
    }
    if (this.isSyncStop()) {
      message += 'Sync conditions were broken. ';
    }
    if (this.isSmarthpNavigationDown()) {
      message += 'The machine\'s navigation system is malfunctioning. ';
    }
    if (this.isOutsideFieldBoundaries()) {
      message += 'The machine is outside field boundaries. ';
    }
    if (this.isHpGandalfDisconnected()) {
      message += 'SmartHP disconnected from Safety Manager. ';
    }
    if (this.isPredictiveOffline()) {
      message += 'Predictive subsystem is offline. ';
    }
    if (this.isObjectTrackingOffline()) {
      message += 'Object Tracking subsystem is offline. ';
    }
    if (this.isCameraOffline()) {
      message += 'Camera subsystem is offline. ';
    }
    if (this.isRadarOffline()) {
      message += 'Radar subsystem is offline. ';
    }
    if (this.isGeofenceOffline()) {
      message += 'Geofence subsystem is offline. ';
    }
    if (this.isUiHeartbeatOffline()) {
      message += 'UI Heartbeat subsystem is offline. ';
    }
    if (this.isHardwareOffline()) {
      message += 'Hardware subsystem is offline. ';
    }
    if (this.isHazardCameraOffline()) {
      message += 'Hazard Camera subsystem is offline. ';
    }
    if (this.isCombineRtkGpsCheckOffline()) {
      message += 'Combine Rtk Gps Check subsystem is offline. ';
    }
    return message;
  }

  shouldDisplayEmergencyPopup(){
    return this.isEStop()
            || this.isPredictiveStop()
            || this.isSyncStop();
  }

  isEmergencyStopState(){
    return this.isEStop()
            || this.isPredictiveStop()
            || this.isSyncStop()
            || this.isVisionStop()
            || this.isManualOverride()
            || this.isRadarStop()
            || this.isObjectTrackingStop()
            || this.isHazardCameraStop();
  }

  areAllCommunicationsOffline(){
    return this.isWifiConnected() === false &&
			     this.isCloudConnected() === false;
  }

  isBrokerConnected(brokerName){
    let isConnected = false;

    if(brokerName === commManagerConstants.WIFI_BROKER){
      isConnected = this.isWifiConnected() === true;
    }
    else if(brokerName === commManagerConstants.CLOUD_BROKER){
      isConnected = this.isCloudConnected() === true;
    }

    return isConnected;
  }

  hasIntermittentError(){
    let allCommunicationsDown = this.areAllCommunicationsOffline();

    let invalidCoordinates = !this.hasValidCoordinates();

    let invalidName = !this.hasValidName();

    return allCommunicationsDown || invalidCoordinates || invalidName;
  }
}

const TractorInfoRecord = Record({
  machine: initMachineInfo(machineTypes.TRACTOR),
  lastReceivedPingInMilliseconds: 0,
  distance_to_sync_point: -1,
  sync_locked_in: false
});

export class TractorInfo extends TractorInfoRecord{
  updateTractorIdReducer(action) { return this.set('machine', this.machine.updateMachineIdReducer(action)); }
  updateTractorBrokerStatusReducer(action) { return this.set('machine', this.machine.updateBrokerStatusReducer(action)); }
  updateTractorNameReducer(action) { return this.set('machine', this.machine.updateMachineNameReducer(action)); }
  updateTractorCanReducer(action) { return this.set('machine', this.machine.updateMachineCanReducer(action)); }
  updateTractorGpsReducer(action) { return this.set('machine', this.machine.updateMachineGpsReducer(action)); }
  updateTractorMachineStatusReducer(action) {
    const {distance_to_sync_point} = action.payload;

    let sync_locked_in = this.sync_locked_in;

    // Start printing the distance in order to debug
    if (distance_to_sync_point >= 0 && distance_to_sync_point < 30) {
      console.log('distance_to_sync_point:', distance_to_sync_point);
    }

    if(distance_to_sync_point >= 0 && distance_to_sync_point < DISTANCE_TO_COMBINE_THRESH_METERS){
      sync_locked_in = true;
      //console.warn("Sync locked: sync_locked_in="+ sync_locked_in);
      console.log('SYNC LOCKED IN');
    }

    if(distance_to_sync_point < 0){
      sync_locked_in = false;
      console.log("Reset sync lock: sync_locked_in="+ sync_locked_in);
    }

    return this
      .set('machine', this.machine.updateMachineStatusReducer(action))
      .set('distance_to_sync_point', distance_to_sync_point)
      .set('sync_locked_in', sync_locked_in);
  }
  updateTractorMachineStatusExtraReducer(action) { return this.set('machine', this.machine.updateMachineStatusExtraReducer(action)); }
  updateTractorSensorStatusReducer(action) { return this.set('machine', this.machine.updateMachineSensorStatusReducer(action)); }
  invalidateTractorDataReducer(action) { return this.set('machine', this.machine.invalidateMachineDataReducer(action)); }
  updateTractorConfigReducer(action) { return this.set('machine', this.machine.updateMachineConfigReducer(action))}
  updateTractorConfigOptionsReducer(action) { return this.set('machine', this.machine.updateMachineConfigOptionsReducer(action))}
  updateTractorLastReceivedPingMillisecondsReducer({ payload }) {
    const { timestamp } = payload;

    return this.set('lastReceivedPingInMilliseconds', timestamp)
  }

  isCommunicationLinkStable(){
    let currentTimestampInMilliseconds = Date.now();

    let diffInMilliseconds = currentTimestampInMilliseconds - this.lastReceivedPingInMilliseconds;
    // if the timestamps are less than 3 seconds apart, then the communication link is stable

    return diffInMilliseconds < HEART_BEAT_TIMEOUT_LIMIT_MS;
  }

  isSyncLocked(){

    return this.sync_locked_in;
  }
}

const CombineInfoRecord = Record({
  machine: initMachineInfo(machineTypes.COMBINE),
  sync_x: 0,
  sync_y: 0
});

export class CombineInfo extends CombineInfoRecord {
  updateCombineIdReducer(action) { return this.set('machine', this.machine.updateMachineIdReducer(action)); }
  updateCombineBrokerStatusReducer(action) { return this.set('machine', this.machine.updateBrokerStatusReducer(action)); }
  updateCombineNameReducer(action) { return this.set('machine', this.machine.updateMachineNameReducer(action)); }
  updateCombineCanReducer(action) { return this.set('machine', this.machine.updateMachineCanReducer(action)); }
  updateCombineGpsReducer(action) { return this.set('machine', this.machine.updateMachineGpsReducer(action)); }
  updateCombineMachineStatusReducer(action) { return this.set('machine', this.machine.updateMachineStatusReducer(action)); }
  updateCombineMachineStatusExtraReducer(action) { return this.set('machine', this.machine.updateMachineStatusExtraReducer(action)); }
  updateCombineSensorStatusReducer(action) { return this.set('machine', this.machine.updateMachineSensorStatusReducer(action)); }
  invalidateCombineDataReducer(action) { return this.set('machine', this.machine.invalidateMachineDataReducer(action)); }
  updateCombineConfigReducer(action) { return this.set('machine', this.machine.updateMachineConfigReducer(action))}
  updateCombineSyncPointReducer({ payload }){
    return this
      .set('sync_x', payload.sync_x)
      .set('sync_y', payload.sync_y);
  }

  hasValidSyncPoints(){
    let validX = this.sync_x != null;

    let validY = this.sync_y != null;

    return validX && validY && combineHelper.isSyncPointValid(this.sync_x, this.sync_y);
  }
}
