import AccountService from '../services/v1/AccountService';
import { SET_NEW_IOT_TOKEN } from '../SmartAgUI-Common/commTopicGenerator';
import { setIotTokenForAccount } from './account-management-dux';
export const mountPoint = 'iotToken';
export const actions = {
  SET_NEW_IOT_TOKEN_SUCCESS: 'iotToken/SET_NEW_IOT_TOKEN_SUCCESS',
  SET_NEW_IOT_TOKEN_FETCHING: 'iotToken/SET_NEW_IOT_TOKEN_FETCHING',
  SET_NEW_IOT_TOKEN_FAIL: 'iotToken/SET_NEW_IOT_TOKEN_FAIL'
};

export const parseIotToken = iotToken => {
  return {
    secretKey: iotToken.SecretAccessKey,
    accessKey: iotToken.AccessKeyId,
    sessionToken: iotToken.SessionToken,
    expiration: iotToken.Expiration,
  };
}

export const getNewIotToken = () => {
  // mqttCloudFactory will keep calling this function if the tokens are still expired
  // so we want to ensure we don't keep calling the api since we can't wait for the response
  // and freeze up the interface.
  return (dispatch, getState) => {
    const { accountManagement, iotToken } = getState();

    if (!iotToken.fetching) {
      dispatch(setNewIotToken(accountManagement.activeAccount.company_id));
    }
  }
}

export const setNewIotTokenSuccess = iotToken => {
  const parsedIotToken = parseIotToken(iotToken);

  return {
    type: actions.SET_NEW_IOT_TOKEN_SUCCESS,
    data: parsedIotToken
  };
}

export const setNewIotTokenFetching = () => {
  return {
    type: actions.SET_NEW_IOT_TOKEN_FETCHING
  }
}

export const setNewIotTokenFail = () => {
  return {
    type: actions.SET_NEW_IOT_TOKEN_FAIL
  }
}

export const setNewIotTokenMc = iotToken => {
  return (dispatch, getState) => {
    const socket = getState().websocket;
    const parsedIotToken = parseIotToken(iotToken);

    if (socket.connected) {
      socket.client.emit(SET_NEW_IOT_TOKEN, JSON.stringify(parsedIotToken));
    }
  }
}

export const setNewIotToken = accountId => {
  return async (dispatch, getState) => {
    try {
      dispatch(setNewIotTokenFetching());
      const result = await AccountService.getNewIotToken(accountId);

      if (result.isSuccess) {
        // dispatch action to set state for aavi
        dispatch(setNewIotTokenSuccess(result.data));
        dispatch(setIotTokenForAccount(result.data));
        // dispatch action to send a socket message so
        // Machine Control can update its iot token
        dispatch(setNewIotTokenMc(result.data));
      } else {
        dispatch(setNewIotTokenFail());
      }
    } catch (error) {
      dispatch(setNewIotTokenFail());
    }
  }
}

export const initialState = {
  secretKey: null,
  accessKey: null,
  sessionToken: null,
  expiration: null,
  fetching: false
};

export function reducer(state = initialState, action) {
  switch (action.type) {
  case actions.SET_NEW_IOT_TOKEN_FETCHING:
    return {
      ...state,
      fetching: true
    }
  case actions.SET_NEW_IOT_TOKEN_FAIL:
    return {
      ...state,
      fetching: false
    }
  case actions.SET_NEW_IOT_TOKEN_SUCCESS:
    return {
      ...state,
      ...action.data,
      fetching: false
    }
  default:
    return state;
  }
}