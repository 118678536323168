import React from 'react';
import { ReactComponent as BackButton } from '../../assets/BackButton.svg';
import { ReactComponent as CombineBordered } from '../../assets/CombineBordered.svg';
import { ReactComponent as TractorBordered } from '../../assets/TractorBordered.svg';
import '../../styles/choose-machine-type-view.scss';
import { MachineType } from '../../constants/machineType';
import { Routes } from '../../constants/routes';

class ChooseMachineTypeView extends React.Component {
  handleBack = () => {
    this.props.history.goBack();
  }

  handleChooseCombine = () => {
    this.props.history.push(Routes.MachineCreate, {
      machineType: MachineType.Combine
    });
  }

  handleChooseTractor = () => {
    this.props.history.push(Routes.MachineCreate, {
      machineType: MachineType.Tractor
    });
  }

  render() {
    return (
      <div className='choose-machine-type-view'>
        <div className='header'>
          <BackButton className='back' onClick={this.handleBack} />
          <h1>{'Choose Machine Type'}</h1>
        </div>

        <div className='body'>
          <div className='clickable' onClick={this.handleChooseCombine}>
            <CombineBordered className='combine' />
            <h3 className='label'>{'Combine'}</h3>
          </div>
          <div className='clickable' onClick={this.handleChooseTractor}>
            <TractorBordered className='tractor' />
            <h3 className='label'>{'Tractor'}</h3>
          </div>
        </div>

      </div>
    );
  }
}

export default ChooseMachineTypeView;