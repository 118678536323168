import { FormError } from '../constants/formErrors';
const MIN_DECIMAL = 0.00;
const MAX_DECIMAL = 999.99;
const MIN_INTEGER = 0;
const MAX_INTEGER = 2147483647;
const MAX_STRING_LENGTH = 100;

export const isEmpty = value => {
  return value.length === 0;
}

export const isInDecimalRange = value => {
  return value >= MIN_DECIMAL && value <= MAX_DECIMAL;
}

export const isInIntRange = value => {
  return value >= MIN_INTEGER && value <= MAX_INTEGER;
}

export const checkName = value => {
  if (isEmpty(value)) return FormError.Invalid;
  if (value.length > MAX_STRING_LENGTH) return FormError.TooLong;
  return null;
}

export const checkOptionalInt = value => {
  const parsedValue = parseInt(value);

  if (isEmpty(value)) return null;
  if (!isInIntRange(parsedValue)) return FormError.OutOfRange;
  return null;
}

export const checkOptionalDecimal = value => {
  const parsedValue = parseFloat(value);

  if (isEmpty(value)) return FormError.NotRecognized;
  if (!isInDecimalRange(parsedValue)) return FormError.OutOfRange;
  return null;
}

export const checkDecimal = value => {
  const parsedValue = parseFloat(value);

  if (isEmpty(value)) return FormError.Invalid;
  if (!isInDecimalRange(parsedValue)) return FormError.OutOfRange;
  return null;
}

export const checkInt = value => {
  const parsedValue = parseInt(value);

  if (isEmpty(value)) return FormError.Invalid;
  if (!isInIntRange(parsedValue)) return FormError.OutOfRange;
  return null;
}