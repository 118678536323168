import React from 'react';
import { ReactComponent as BackButton } from '../../assets/BackButton.svg';
import { ReactComponent as CombineBordered } from '../../assets/CombineBordered.svg';
import { ReactComponent as TractorBordered } from '../../assets/TractorBordered.svg';
import CreateEditCombineForm from './CreateEditCombineForm';
import CreateEditTractorForm from './CreateEditTractorForm';
import { openDialog } from '../../dux/dialog-dux';
import MachineService from '../../services/v1/MachineService';
import '../../styles/machine-settings-view.scss';
import { MachineType, TractorType } from '../../constants/machineType';
import { showDefaultErrorToast } from '../../helpers/toast-helper';
import { ErrorMessages } from '../../constants/errorMessages';

class MachineSettingsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdvanced: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accountManagement.activeAccount.account_id !== this.props.accountManagement.activeAccount.account_id) {
      // switched accounts, need to go back to machines view
      this.props.history.goBack();
    }
  }

  renderIcon() {
    const icon = this.props.machine.selectedMachine.machine_type === MachineType.Combine ?
      <CombineBordered className='combine' /> :
      <TractorBordered className='tractor' />;

    return (
      <div className='icon'>
        {icon}
      </div>
    );
  }

  renderEditMachineForm() {
    if (this.props.machine.selectedMachine.machine_type === MachineType.Combine) {
      return (
        <CreateEditCombineForm
          machine={this.props.machine.selectedMachine}
          showAdvanced={this.state.showAdvanced}
          ref={ref => { this.form = ref }} />
      );
    }
    return (
      <CreateEditTractorForm
        machine={this.props.machine.selectedMachine}
        showAdvanced={this.state.showAdvanced}
        ref={ref => { this.form = ref }} />
    );
  }

  onDelete = () => {
    this.props.dispatch(openDialog({
      content: `Are you sure you want to delete the machine "${this.props.machine.selectedMachine.displayName}"?`,
      yesButton: async () => {
        try {
          await MachineService.deleteMachine(this.props.machine.selectedMachine.dbId);
          this.props.history.goBack();
        } catch (error) {
          showDefaultErrorToast(ErrorMessages.DeleteMachine);
        }
      }
    }));
  }

  onSave = async () => {
    if (!this.form.validate()) {
      return;
    }

    const formData = this.props.machine.selectedMachine.machine_type === MachineType.Combine ? this.getCombineFormData() : this.getTractorFormData();
    const updateMachineModel = {
      machineID: this.props.machine.selectedMachine.dbId,
      formData: formData
    };

    try {
      await MachineService.updateMachine(updateMachineModel);
      this.props.history.goBack();
    } catch (error) {
      showDefaultErrorToast(ErrorMessages.UpdateMachine);
    }
  }

  getCombineFormData = () => {
    const { name, forward, headWidth, syncX, syncY } = this.form.state;

    return {
      name: name.value,
      machineType: MachineType.Combine,
      machineModel: null,
      syncX: syncX.value || null,
      syncY: syncY.value || null,
      forward: forward.value,
      headWidth: headWidth.value,
      minRPM: null,
      maxRPM: null,
      speedDialF1: null,
      speedDialF2: null
    };
  }

  getTractorFormData = () => {
    const { name, type, speedDialF1, speedDialF2, minRPM, maxRPM } = this.form.state;
    const ivtConfigs = {
      speedDialF1: speedDialF1.value,
      speedDialF2: speedDialF2.value,
      minRPM: null,
      maxRPM: null
    };
    const pstConfigs = {
      minRPM: minRPM.value,
      maxRPM: maxRPM.value,
      speedDialF1: null,
      speedDialF2: null
    }
    const configs = type.value === TractorType.IVT ? ivtConfigs : pstConfigs

    return {
      name: name.value,
      machineType: MachineType.Tractor,
      machineModel: type.value,
      syncX: null,
      syncY: null,
      forward: null,
      headWidth: null,
      ...configs
    };
  }

  render() {
    return (
      <div className='machine-settings-view'>
        <div className='header'>
          <BackButton className='back' onClick={() => this.props.history.goBack()} />
          {this.renderIcon()}
        </div>

        <div className='tabs'>
          <div
            className={`tab${this.state.showAdvanced ? '' : ' active'}`}
            onClick={() => this.setState({ showAdvanced: false })}>
            {'Basic'}
          </div>
          <div
            className={`tab${this.state.showAdvanced ? ' active' : ''}`}
            onClick={() => this.setState({ showAdvanced: true })}>
            {'Advanced'}
          </div>
        </div>

        <div className='form-container'>
          {this.renderEditMachineForm()}
        </div>


        <div className='buttons'>
          <button
            className='delete'
            onClick={this.onDelete}>
            {'Delete'}
          </button>
          <button
            className='save'
            onClick={this.onSave}>
            {'Save'}
          </button>
        </div>
      </div>
    );
  }
}

export default MachineSettingsView;