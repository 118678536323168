import dateUtils from '../dateUtils';

export default class trackedObj {
    constructor(model, expires_after_seconds = 2) {
        this.expires_after_seconds = expires_after_seconds
        this.update(model)
    }

    update(model) {
        this.lastUpdated = new Date()
        this.id = model['id'];
        this.lon = model['lon'];
        this.lat = model['lat'];
        this.label = model['label'];
        this.conf = model['conf'];
    }


    isValid() {
        //The combine is valid if it has been "updated" in the last 60 seconds
        var sixtySecondsAgo = dateUtils.dateAdd(new Date(), 'second', -this.expires_after_seconds).getTime() / 1000;
        var lastUpdated = this.lastUpdated.getTime() / 1000;

        var isRecentlyUpdated = lastUpdated >= sixtySecondsAgo;
        return isRecentlyUpdated;
    }
}