import React from 'react';
import { ReactComponent as BackButton } from '../../assets/BackButton.svg';
import { ReactComponent as CombineBordered } from '../../assets/CombineBordered.svg';
import { ReactComponent as TractorBordered } from '../../assets/TractorBordered.svg';
import CreateEditCombineForm from './CreateEditCombineForm';
import CreateEditTractorForm from './CreateEditTractorForm';
import MachineService from '../../services/v1/MachineService';
import { MachineType } from '../../constants/machineType';
import '../../styles/create-machine-view.scss';
import { ErrorMessages } from '../../constants/errorMessages';
import { showDefaultErrorToast } from '../../helpers/toast-helper';
import { Routes } from '../../constants/routes';

class CreateMachineView extends React.Component {
  constructor(props) {
    super(props);
    const webState = this.props.location.state;

    this.state = {
      machineType: webState.machineType
    }
  }

  onSave = async () => {
    if (!this.form.validate()) {
      return;
    }
    const formData = this.state.machineType === MachineType.Combine ? this.getCombineFormData() : this.getTractorFormData();
    const createMachineModel = {
      accountId: this.props.accountManagement.activeAccount.account_id,
      formData: formData
    };

    try {
      await MachineService.createMachine(createMachineModel);
      this.props.history.push(Routes.Machines);
    } catch (error) {
      showDefaultErrorToast(ErrorMessages.CreateMachine);
    }
  }

  getCombineFormData = () => {
    const { name, forward, headWidth } = this.form.state;

    return {
      name: name.value,
      machineType: this.state.machineType,
      machineModel: null,
      forward: forward.value,
      headWidth: headWidth.value,
      minRPM: null,
      maxRPM: null,
      speedDialF1: null,
      speedDialF2: null
    };
  }

  getTractorFormData = () => {
    const { name, type, speedDialF1, speedDialF2, minRPM, maxRPM } = this.form.state;
    const isIVT = type && type.value === 'IVT';
    const isPST = type && type.value === 'PST';

    return {
      name: name.value,
      machineType: this.state.machineType,
      machineModel: type.value,
      forward: null,
      headWidth: null,
      minRPM: isPST ? minRPM.value : null,
      maxRPM: isPST ? maxRPM.value : null,
      speedDialF1: isIVT ? speedDialF1.value : null,
      speedDialF2: isIVT ? speedDialF2.value : null
    };
  }

  renderIcon() {
    const icon = this.state.machineType === MachineType.Combine ?
      <CombineBordered className='combine' /> :
      <TractorBordered className='tractor' />;

    return (
      <div className='icon'>
        {icon}
      </div>
    );
  }

  renderCreateMachineForm() {
    if (this.state.machineType === MachineType.Combine) {
      return (
        <CreateEditCombineForm
          showAdvanced={false}
          ref={ref => { this.form = ref }} />
      );
    }
    return (
      <CreateEditTractorForm
        ref={ref => { this.form = ref }} />
    );
  }

  render() {
    return (
      <div className='create-machine-view'>
        <div className='header'>
          <BackButton className='back' onClick={() => this.props.history.goBack()} />
          {this.renderIcon()}
        </div>

        {this.renderCreateMachineForm()}

        <button
          className='save'
          onClick={this.onSave}>
          {'Save'}
        </button>

      </div>
    );
  }
}

export default CreateMachineView;