// utilities
import actionTypes from '../actions/actionTypes';

var initState = {
  client: null
};

export default function comm(state = initState, action) {
  switch (action.type) {
    case actionTypes.MQTT_INIT:
      // payload has all field details
      return Object.assign({}, state, { client: action.payload.client});
    default:
      return state;
  }
}
