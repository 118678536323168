import React from 'react';
import { closeDialog } from '../dux/dialog-dux';
import '../styles/dialog.scss';

class Dialog extends React.Component {
  render() {
    if (!this.props.dialog.showDialog) return null;

    let no_btn_txt = 'No';

    let yes_btn_txt = 'Yes';

    let header = 'Confirmation';

    if (this.props.dialog.dialogOptions.noButtonTxt)
      no_btn_txt = this.props.dialog.dialogOptions.noButtonTxt;
    if (this.props.dialog.dialogOptions.yesButtonTxt)
      yes_btn_txt = this.props.dialog.dialogOptions.yesButtonTxt;
    if (this.props.dialog.dialogOptions.headerTxt)
      header = this.props.dialog.dialogOptions.headerTxt;


    return (
      <div className='dialog'>
        <div className='dialog-content'>
          <h4>{header}</h4>
          <div className='content'>
            {this.props.dialog.dialogOptions.content}
          </div>
          <div className='buttons'>
            <button
              className='no-button'
              onClick={() => {
                if (this.props.dialog.dialogOptions.noButton) {
                  this.props.dialog.dialogOptions.noButton();
                }
                this.props.dispatch(closeDialog());
              }}
              type="submit"
            >
              {no_btn_txt}
            </button>
            <button
              className='yes-button'
              onClick={() => {
                this.props.dialog.dialogOptions.yesButton();
                this.props.dispatch(closeDialog());
              }}
              type="submit"
            >
              {yes_btn_txt}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Dialog;