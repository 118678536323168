import { guid } from './guid';
import commManagerConstants from './commManagerConstants';

const wifiMqttFactory = (hostUrl) => {
  const id = guid();
    var mqtt = {
      name: commManagerConstants.WIFI_BROKER,
      host: hostUrl,
      priority_broker: true,
      options: {
        queueQoSZero: false,
        qos: 0,
        clientId: id,
        resubscribe: true
      }
    };

    return mqtt;
};

export default wifiMqttFactory;