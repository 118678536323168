export const mountPoint = 'operation';
export const actions = {
  START_OPERATION: 'operation/START_OPERATION',
  END_OPERATION: 'operation/END_OPERATION',
  TOGGLE_MAP_SHOULD_RESIZE: 'operation/TOGGLE_MAP_SHOULD_RESIZE'
};

export const startOperation = operationData => {
  return dispatch => {
    dispatch({
      type: actions.START_OPERATION,
      operationData
    });
  }
}

export const endOperation = () => {
  return dispatch => {
    dispatch({
      type: actions.END_OPERATION
    })
  }
}

export const toggleMapShouldResize = shouldResize => {
  return dispatch => {
    dispatch({
      type: actions.TOGGLE_MAP_SHOULD_RESIZE,
      shouldResize
    })
  }
}


const initialState = {
  isRunning: false,
  connectionID: undefined,
  combine: undefined,
  tractor: undefined,
  field: undefined,
  mapShouldResize: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
  case actions.START_OPERATION:
    return {
      ...state,
      ...action.operationData,
      isRunning: true,
      mapShouldResize: false
    }
  case actions.END_OPERATION:
    return {
      ...state,
      isRunning: false,
      connectionID: undefined,
      mapShouldResize: true
    }
  case actions.TOGGLE_MAP_SHOULD_RESIZE:
    return {
      ...state,
      mapShouldResize: action.shouldResize
    }
  default:
    return state;
  }
}