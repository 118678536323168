import React from 'react';
import '../../styles/draw-tools-sidebar.scss';
import { ReactComponent as SaveIcon } from '../../assets/SaveIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/EditIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/DeleteIcon.svg';
import { ReactComponent as BackButton } from '../../assets/BackButton.svg';
import { connect } from 'react-redux';

export class DrawToolsSidebar extends React.Component {
  constructor(props) {
    super(props);
    const initFieldData = {
      type: 'FeatureCollection',
      features: []
    };

    if (this.props.field) {
      // Modifying field
      this.state = {
        field: this.props.field,
        isCreating: false
      }
    }
    else {
      // Creating field
      this.state = {
        field: {
          data: initFieldData
        },
        isCreating: true
      }
    }
  }

  saveField = () => {
    this.props.onSave();
  }

  render() {
    const isCreating = !this.props.field;

    return (
      <div className='draw-tools-sidebar'>
        <BackButton className='back' onClick={this.props.onBack} />
        <h1>{isCreating ? 'Create' : 'Modify'}</h1>

        <div className='blocks'>
          <div className='clickable-block' onClick={this.saveField}>
            <SaveIcon />
            <p>{'Save & Exit'}</p>
          </div>

          <div className='clickable-block' onClick={this.props.enableDrawMode}>
            <EditIcon />
            <p>{isCreating ? 'Draw Boundary' : 'Add Inner Boundary'}</p>
          </div>

          <div className='clickable-block' onClick={this.props.onDelete}>
            <DeleteIcon />
            <p>{'Delete Points'}</p>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    field: state.field.selectedField
  }
}

export default connect(mapStateToProps)(DrawToolsSidebar);