export const FormError = {
  Invalid: 'Valid input required',
  OutOfRange: 'Number out of range',
  TooLong: 'Must be less than 100 characters',
  InvalidInt: 'Number must be a positive integer',
  NotRecognized: 'Input not recognized.',
  MinRPMGreaterThanMaxRPM: 'Minimum RPM must be less than Maximum RPM',
  MaxRPMLessThanMinRPM: 'Maximum RPM must be greater than Minimum RPM',
  SpeedDialF1GreaterThanSpeedDialF2: 'Speed Dial F1 must be less than Speed Dial F2',
  SpeedDialF2LessThanSpeedDialF1: 'Speed Dial F2 must be greater than Speed Dial F1',
};