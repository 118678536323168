// utilities
import timeUtil from './timeUtil';

export default class commandCreatorsHelper {
    static createCommandStructure(command, args) {
        return {
            command: command,
            args: args,
            timestamp: timeUtil.getTimestampSeconds()
        }
    }

    static updateCommandTimestamp(command) {
        command.timestamp = timeUtil.getTimestampSeconds();
    }
}