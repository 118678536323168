export const mountPoint = 'dialog';
export const actions = {
  OPEN_DIALOG: 'dialog/OPEN_DIALOG',
  CLOSE_DIALOG: 'dialog/CLOSE_DIALOG'
};

export const openDialog = options => {
  return dispatch => {
    dispatch({
      dialogOptions: options,
      type: actions.OPEN_DIALOG
    })
  }
}

export const closeDialog = () => {
  return dispatch => {
    dispatch({
      type: actions.CLOSE_DIALOG
    })
  }
}

const initialState = {
  dialogOptions: undefined,
  showDialog: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
  case actions.OPEN_DIALOG:
    return {
      ...state,
      dialogOptions: action.dialogOptions,
      showDialog: true
    }
  case actions.CLOSE_DIALOG:
    return {
      ...state,
      dialogOptions: undefined,
      showDialog: false
    }
  default:
    return state;
  }
}