import { batch } from 'react-redux'
// utilities
import actionTypes from './actionTypes';
import { getSha1Hash } from '../hashHelper';
import machineConfigConstants from '../machineConfigConstants';

// Dispatches all actions required to update tractor state using MQTT message

export function updateTractorId(dispatch, id) {
  const action = {
    type: actionTypes.UPDATE_TRACTOR_ID,
    payload: { id }
  };
  dispatch(action);
}

export function updateTractorBrokerStatus(dispatch, brokerName, isConnected) {
  const action = {
    type: actionTypes.UPDATE_TRACTOR_BROKER_STATUS,
    payload: {
      brokerName,
      isConnected
    }
  };
  dispatch(action);
}

export function updateTractorName(dispatch, name) {
  const nameAction = {
    type: actionTypes.UPDATE_TRACTOR_NAME,
    payload: { name }
  };
  dispatch(nameAction);
}

export function updateTractorRunningCommand(dispatch, runningCommand) {
  const runningCommandAction = {
    type: actionTypes.UPDATE_TRACTOR_RUNNING_COMMAND,
    payload: { runningCommand }
  };
  dispatch(runningCommandAction);
}

export function updateTractorStateSlimCore(dispatch, mqttPayload) {
  try {
    const { yaw_rate, veh_speed, timestamp } = mqttPayload;
    const lat = parseFloat(mqttPayload.lat);
    const lon = parseFloat(mqttPayload.lon);

    var speed_mph = formatSpeedInMPH(veh_speed);
    // convert degrees to radians
    const heading_deg = parseFloat(mqttPayload.heading);
    const heading = heading_deg * (Math.PI / 180);

    const direction = headingToDirection(heading_deg);

    const gpsAction = {
      type: actionTypes.UPDATE_TRACTOR_GPS,
      payload: {
        lat, lon, speed_mph, direction, heading, yaw_rate, timestamp
      }
    };

    dispatch(gpsAction);
  } catch (error) {
    invalidateTractorDataAction(dispatch);
  }
}

export function updateTractorStateSlimExtraWrapper(veh_speed, dispatch, mqttPayload) {
  try {
    const { cur_gear, eng_rpm, fuel_level, bypass } = mqttPayload;

    // figure out the gear based on what type of tractor we are running
    var cur_gear_str = 'Bypass';
    const tractorType = getTractorType(cur_gear);
    const shouldParseGearValue = shouldParseGear(tractorType, bypass);
    if (shouldParseGearValue) {
      const tractorGearKey = machineConfigConstants.TRACTOR_GEAR_KEYS[tractorType];
      const rawGear = mqttPayload[tractorGearKey];
      cur_gear_str = formatGear(rawGear, veh_speed);
    }

    const fuel_level_str = formatFuelLevel(fuel_level);
    const gps_fail = parseInt(mqttPayload.gps_fail);
    const can0_fail = parseInt(mqttPayload.can0_fail);
    const can1_fail = parseInt(mqttPayload.can1_fail);
    const gps_acquiring = parseInt(mqttPayload.gps_acquiring);
    const ignition_key = mqttPayload.ignition_key;
    const shutdown_timer = mqttPayload.shutdown_timer;

    const canAction = {
      type: actionTypes.UPDATE_TRACTOR_CAN,
      payload: {
        eng_rpm, fuel_level: fuel_level_str, gear: cur_gear_str
      }
    };
    const sensorStatusAction = {
      type: actionTypes.UPDATE_TRACTOR_SENSOR_STATUS,
      payload: { gps_fail, can0_fail, can1_fail, gps_acquiring, ignition_key, shutdown_timer }
    };

    batch(() => {
      dispatch(canAction);
      dispatch(sensorStatusAction);
    });
  } catch (error) {

    console.log("error:", error);
    invalidateTractorDataAction(dispatch);
  }
}

export function updateTractorStateSlimExtra(dispatch, mqttPayload) {

  const fn = function (dispatch, getState) {
    const veh_speed = getState().tractorInfo.machine.gps.veh_speed;
    updateTractorStateSlimExtraWrapper(veh_speed, dispatch, mqttPayload);
  };

  dispatch(fn);
}

export function updateTractorStatusSlimCore(dispatch, mqttPayload) {
  try {
    const statusAction = {
      type: actionTypes.UPDATE_TRACTOR_MACHINE_STATUS,
      payload: mqttPayload
    };
    dispatch(statusAction);
  } catch (error) {
    invalidateTractorDataAction(dispatch);
  }
}

export function updateTractorStatusSlimExtra(dispatch, mqttPayload) {
  try {
    const statusAction = {
      type: actionTypes.UPDATE_TRACTOR_MACHINE_STATUS_EXTRA,
      payload: mqttPayload
    };
    dispatch(statusAction);
  } catch (error) {
    invalidateTractorDataAction(dispatch);
  }
}


export function headingToDirection(heading) {
  var headingDirection = '';
  if (heading > 0 && heading < 90) {
    headingDirection = 'NE';
  } else if (heading > 90 && heading < 180) {
    headingDirection = 'SE'
  } else if (heading > 180 && heading < 270) {
    headingDirection = 'SW';
  } else if (heading > 270 && heading < 360) {
    headingDirection = 'NW';
  } else if (heading === 0 || heading === 360) {
    headingDirection = 'N';
  } else if (heading === 90) {
    headingDirection = 'E';
  } else if (heading === 180) {
    headingDirection = 'S';
  } else if (heading === 270) {
    headingDirection = 'W';
  }

  return headingDirection;
}

export function formatSpeedInMPH(speed) {
  var formattedMPH = parseFloat(speed) * 2.23694;
  formattedMPH = formattedMPH.toFixed(2);
  return formattedMPH;
}

export function formatGear(cur_gear, speed) {
  // check  the gear
  var cur_gear = parseInt(cur_gear);
  var formattedCurGear;

  if (cur_gear > 0) { // Forward gears or Park
    if (cur_gear === 126) {
      formattedCurGear = 'Park';
    } else {
      formattedCurGear = 'F' + cur_gear;
    }
  } else if (cur_gear < 0) { // Reverse gears
    formattedCurGear = 'R' + (cur_gear * (-1));
  } else { // Park or Neutral - 0
    // gear is 0 - which could be park or neutral - check speed to verify
    if (parseFloat(speed) === 0) {
      formattedCurGear = 'Park';
    } else {
      formattedCurGear = 'Neutral';
    }
  }
  return formattedCurGear;
}
export function formatFuelLevel(fuel_level) {
  return parseFloat(fuel_level).toFixed(2);
}

export function invalidateTractorDataAction(dispatch) {
  console.log("[Error]: recieved bad state info for a machine");
  dispatch({
    type: actionTypes.INVALIDATE_TRACTOR_DATA
  })
}

export function updateTractorConfig(dispatch, data) {
  var version = getSha1Hash(data);
  const updateConfigAction = {
    type: actionTypes.UPDATE_TRACTOR_CONFIG,
    payload: {
      data,
      version
    }
  };
  dispatch(updateConfigAction);
}

export function updateTractorConfigOptions(dispatch, turning_radius_default, turning_radius_min) {
  const updateConfigAction = {
    type: actionTypes.UPDATE_TRACTOR_CONFIG_OPTIONS,
    payload: {
      turning_radius_default,
      turning_radius_min
    }
  };
  dispatch(updateConfigAction);
}

export function updateTractorLastReceivedPingMilliseconds(dispatch, timestamp) {
  const updateConfigAction = {
    type: actionTypes.UPDATE_TRACTOR_LAST_RECEIVED_PING_MILLISECONDS,
    payload: {
      timestamp
    }
  };
  dispatch(updateConfigAction);
}

export function shouldParseGear(tractorType, bypass) {
  return tractorType != machineConfigConstants.TRACTOR_TYPE_IVT || !bypass;
}

export function getTractorType(cur_gear) {
  // 99 is default cur_gear and means we cannot parse gear data, which means it is IVT
  if (parseInt(cur_gear) === 99) {
    return machineConfigConstants.TRACTOR_TYPE_IVT;
  }

  // if we can parse cur_gear then it is a PST
  return machineConfigConstants.TRACTOR_TYPE_PST;

}